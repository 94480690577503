import { Snackbar, useSession, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import React from "react";
import { useParams } from "react-router-dom";

import { findCustomExtractionLabel } from "../../_common/custom-extraction-labels/findCustomExtractionLabel";
import { isCustomExtractionReportTemplateCode } from "../../_common/custom-extraction-labels/isCustomExtractionReportTemplateCode";
import {
  DatasourceCode,
  ModuleCode,
  useCustomExtractionLabelsQuery,
  useReportPageDataQuery,
} from "../../graphql";
import { getReportCodes } from "../helpers/getReportCodes";
import { getReportInfo } from "./getReportInfo";
import { ReportPageData } from "./types";
import { useReportBreadcrumbs } from "./useReportBreadcrumbs";
import { createAdminFilter } from "../../_common/api/createAdminFilter";
import { getReportChange } from "../../_common/report-helpers/getReportChange";
import { useSearchParam } from "../../_common/routing/useSearchParam";

export function useReportPageData(): ReportPageData {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("report");

  const { isDeepCrawlAdminEnabled, hasAddon } = useSession();

  const { accountId, projectId, crawlId, reportTemplateCodeWithTypeCode } =
    useParams<{
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTemplateCodeWithTypeCode: string;
    }>();
  const segmentId = useSearchParam("segmentId");

  const { reportTemplateCode } = getReportCodes(reportTemplateCodeWithTypeCode);
  const isCustomExtractionReport =
    isCustomExtractionReportTemplateCode(reportTemplateCode);

  const {
    loading: customExtractionLabelsLoading,
    data: customExtractionLabelsData,
  } = useCustomExtractionLabelsQuery({
    variables: { crawlId },
    fetchPolicy: "cache-first",
    skip: !isCustomExtractionReport,
    onError: (error) =>
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />),
  });

  const customExtractionLabel = findCustomExtractionLabel(
    customExtractionLabelsData,
    reportTemplateCode,
  );

  const { data, loading } = useReportPageDataQuery({
    variables: {
      crawlId,
      projectId,
      reportTemplateCode,
      segmentId: segmentId ?? null,
      templateFilter: {
        code: { eq: reportTemplateCode },
        ...createAdminFilter(isDeepCrawlAdminEnabled),
      },
      shouldQuerySegmentFields: hasAddon("segmentation"),
    },
    fetchPolicy: "cache-first",
    onError: (error) =>
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />),
  });

  const report = data?.getReportStat;
  const reportTemplate = data?.reportTemplates.edges[0]?.node;
  const datasourceCode = reportTemplate?.datasourceCodeEnum;
  const reportTemplateQueryVersion = reportTemplate?.queryVersion ?? 1;
  const lastFinishedCrawlId = data?.getProject?.lastFinishedCrawl?.id;

  const title = customExtractionLabel || reportTemplate?.name || "Reports";

  const info = React.useMemo(() => {
    return getReportInfo(report, reportTemplate, customExtractionLabel);
  }, [report, reportTemplate, customExtractionLabel]);

  const { loading: breadrumbsLoading, breadcrumbs } = useReportBreadcrumbs({
    reportTemplate,
    accountId,
    projectId,
    crawlId,
    segmentId,
    title,
  });

  function getError(): Error | undefined {
    if (loading) return;

    if (!reportTemplate) return new Error(t("reportTemplateNotFound"));

    const supportedDatasources = [
      DatasourceCode.CrawlUrls,
      DatasourceCode.CrawlAccessibilityIssues,
      DatasourceCode.CrawlAccessibilityIssueInstances,
    ];

    if (!report) {
      return segmentId &&
        !supportedDatasources.includes(reportTemplate.datasourceCodeEnum)
        ? new Error(t("noSegmentedReport"))
        : new Error(t("reportNotFound"));
    }
  }

  const isMissingDiffs = !report || getReportChange(report) === null;

  return {
    loading: loading || customExtractionLabelsLoading || breadrumbsLoading,
    error: getError(),
    title,
    breadcrumbs,
    info,
    datasourceCode,
    reportTemplateCode,
    reportTemplateQueryVersion,
    lastFinishedCrawlId,
    reportAdviceCollapsed: Boolean(data?.me.reportAdviceCollapsed),
    tabsMode: isMissingDiffs ? "onlyTotalRowsVisible" : "visible",
    crawlSegmentsTotalCount: data?.getCrawl?.crawlSegments?.totalCount ?? 0,
    crawlProjectModuleCode: data?.getProject?.moduleCode ?? ModuleCode.Seo,
  };
}
