import { Box, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { CrawlOverviewTypeTabs } from "./CrawlOverviewTabs";
import { OverviewType } from "./types";
import { Dashboard } from "./dashboard/Dashboard";
import { useTranslation, Alert } from "@lumar/shared";
import { Routes } from "../_common/routing/routes";
import { SegmentSelect } from "../segment-selector/SegmentSelect";
import { CrawlSelector } from "../crawl-selector/CrawlSelector";
import { CrawlSettingsComparison } from "../report/report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { ReportSearch } from "./ReportSearch";
import {
  CrawlOverviewContextProvider,
  useCrawlOverviewContext,
} from "./CrawlOverviewContext";
import { ReportsTable } from "./reports-table/ReportsTable";
import { ReportEntity } from "../report/Report.types";
import { useSearchParam } from "../_common/routing/useSearchParam";

export function CrawlOverviewPage(): JSX.Element {
  return (
    <CrawlOverviewContextProvider reportEntity={ReportEntity.LegacyReport}>
      <CrawlOverview />
    </CrawlOverviewContextProvider>
  );
}

function CrawlOverview(): JSX.Element {
  const { data, loading, helpers, crawlContext, categoryNotFound } =
    useCrawlOverviewContext();

  const { t } = useTranslation("crawlOverview");
  const segmentId = useSearchParam("segmentId");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (categoryNotFound) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("reportCategoryNotFound")}</Alert>
        </div>
      </>
    );
  }

  if (crawlContext.crawlNotFound) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("crawlNotFound")}</Alert>
        </div>
      </>
    );
  }

  if (loading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  if (!data || !crawlContext.data || !helpers || !crawlContext.helpers) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("error.noData")}</Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <TopNavigation
        title={t("pageTitle", {
          category: data.selectedCategory.name,
          type: data.selectedOverviewType,
        })}
        breadcrumbs={data.breadcrumbs}
        actions={[
          <ReportSearch key="report-search" />,
          <SegmentSelect key="segment-select" />,
          <CrawlSelector
            key="crawl-select"
            getPath={(crawlId) =>
              Routes.CrawlOverview.getUrl({
                accountId,
                projectId,
                crawlId,
                category: data.selectedCategory?.code,
                segmentId,
                type: data.selectedOverviewType,
              })
            }
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        capitalizeTitle
        availableForShare
      />
      <Box marginTop={3}>
        <CrawlOverviewTypeTabs
          selectedType={data.selectedOverviewType}
          onTypeSelect={(type: OverviewType) =>
            helpers.selectOverviewType(type)
          }
        />
        <Box marginTop="10px">
          {data.selectedOverviewType === OverviewType.Dashboard ? (
            <Dashboard />
          ) : (
            <ReportsTable
              reportEntity={ReportEntity.LegacyReport}
              key={data.selectedOverviewType}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
