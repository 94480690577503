import { getRawCrawlId, useTranslation } from "@lumar/shared";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { ColumnState } from "../../../../_common/data-grid/column-persistance/columnsState";
import {
  MetricType,
  ReportStatColumnsMetadataQuery,
} from "../../../../graphql";
import { jsTypes } from "../_common/js-types";
import { CardCell } from "./card-cell/CardCell";
import { ReportGridColumn } from "./ReportGridColumns.types";
import { IndexInterpreter } from "./interpreters/IndexInterpreter";
import {
  DefaultColumnState,
  ReportGridColumnsResult,
  UseReportGridColumnsArgs,
} from "./ReportGridColumns.types";
import { TFunction } from "i18next";
import { getReportAllTypesMetrics } from "./getReportAllTypesMetrics";
import { sortableMetrics } from "./sortableMetrics";
import { useReportGridColumnState } from "./useReportGridColumnState";
import { useReportColumnsMetadataQuery } from "./useReportColumnsMetadataQuery";

export function useReportGridColumns({
  reportInput,
  columnPersistanceKey,
  overwrites,
}: UseReportGridColumnsArgs): ReportGridColumnsResult {
  const { t } = useTranslation("report");

  const { loading, data, error } = useReportColumnsMetadataQuery(reportInput);

  const {
    isGridView,
    toggleGridView,
    loadInitialColumnsState,
    saveColumnsState,
  } = useReportGridColumnState({ reportInput, columnPersistanceKey });

  const memoed = useMemo(() => {
    const {
      allMetrics,
      cardMetrics,
      foundInSourcesMetrics: foundInSources,
      defaultMetrics,
      filterMetrics,
    } = getReportAllTypesMetrics({
      data,
      isGridView,
      metricsGroupingsOverwrite: overwrites?.metricsGroupings,
    });

    const reportTemplateCode =
      (data as ReportStatColumnsMetadataQuery)?.report?.reportTemplateCode ??
      undefined;
    const datasourceCode =
      data?.report?.reportTemplate?.datasource?.datasourceCode;

    const defaultStates = [
      ...(!isGridView
        ? [{ code: "card", hide: false, width: 594, minWidth: 594 }]
        : []),
      ...[
        ...defaultMetrics,
        ...allMetrics.filter(
          (m) => !defaultMetrics.find((d) => d.code === m.code),
        ),
      ].map((col) => ({
        code: col.code,
        hide: !defaultMetrics.find((x) => x.code === col.code),
        ...getDefaultProps(col, datasourceCode || "", col.type),
      })),
    ];

    const storedStates = loadInitialColumnsState(defaultStates, cardMetrics);

    const possibleMetrics = storedStates
      ? allMetrics.toSorted(
          ({ code: a }, { code: b }) =>
            storedStates.findIndex((x) => x.code === a) -
            storedStates.findIndex((x) => x.code === b),
        )
      : allMetrics;

    const definitions: GridColDef[] = [
      ...(!isGridView
        ? [
            getCardColumnDefinition({
              cardMetrics,
              foundInSources,
              isGridView,
              defaultStates,
              storedStates,
              reportTemplateCode,
              t,
            }),
          ]
        : []),
      ...possibleMetrics.map((column) =>
        getColumnDefinition({
          column,
          isGridView,
          defaultStates,
          storedStates,
          reportTemplateCode,
        }),
      ),
    ];

    return {
      definitions,
      defaultMetrics,
      cardMetrics,
      foundInSources,
      storedStates,
      defaultStates,
      metricsData: allMetrics,
      filterMetrics,
      datasourceCode,
      compareToCrawlId: data?.getCrawl?.comparedToCrawlId
        ? getRawCrawlId(data.getCrawl.comparedToCrawlId)
        : undefined,
      isCrawlIncomplete: data?.getCrawl?.incomplete,
      crawlTypesMetadata: data?.getCrawlTypesMetadata ?? [],
    };
  }, [
    data,
    isGridView,
    overwrites?.metricsGroupings,
    loadInitialColumnsState,
    t,
  ]);

  return {
    loading,
    error,
    ...memoed,
    isGridView,
    toggleGridView,
    saveColumnsState: (state) => saveColumnsState(state, memoed.defaultStates),
  };
}

function getCardColumnDefinition(args: {
  cardMetrics: ReportGridColumn[];
  foundInSources: ReportGridColumn[];
  isGridView: boolean;
  defaultStates: DefaultColumnState[];
  storedStates: ColumnState[];
  reportTemplateCode?: string;
  t: TFunction<"report">;
}): GridColDef {
  const storedState = args.storedStates.find((x) => x.code === "card");
  const defaultState = args.defaultStates.find((x) => x.code === "card");

  return {
    field: "card",
    headerName: args.t("urlDetails"),
    sortable: false,
    disableReorder: true,
    disableColumnMenu: true,
    width: storedState?.width ?? defaultState?.width,
    minWidth: defaultState?.minWidth,
    renderCell: (params: GridRenderCellParams) => (
      <CardCell
        {...params}
        cardMetrics={args.cardMetrics}
        foundInSources={args.foundInSources}
        isGridView={args.isGridView}
        reportTemplateCode={args.reportTemplateCode}
        /**
         * We need to subtract 152px from the column width to calculate the
         * inner container width of the card cell.
         */
        containerWidth={params.colDef.computedWidth - 152}
      />
    ),
  };
}

function getColumnDefinition(args: {
  column: {
    code: string;
    name: string;
    description: string;
    type: MetricType;
  };
  isGridView: boolean;
  defaultStates: DefaultColumnState[];
  storedStates: ColumnState[];
  reportTemplateCode?: string;
}): GridColDef {
  const { column, isGridView, defaultStates, storedStates } = args;
  const defaultState = defaultStates.find((x) => x.code === column.code);
  const storedState = storedStates.find((x) => x.code === column.code);

  return {
    type: jsTypes.get(column.type),
    description: column.description,
    field: column.code,
    headerName: column.name,
    resizable: true,
    hide: storedState?.hide ?? defaultState?.hide ?? true,
    width: storedState?.width ?? defaultState?.width ?? 0,
    minWidth: defaultState?.minWidth,
    sortable: defaultState?.sortable ?? true,
    align: defaultState?.align,
    headerAlign: "left",
    renderCell: (props) => (
      <IndexInterpreter
        {...props}
        code={column.code}
        isGridView={isGridView}
        reportTemplateCode={args.reportTemplateCode}
        /**
         * We need to subtract 34px from the column width to account
         * for the 17px padding on each side of the column.
         * @author Alex Sánchez
         */
        containerWidth={props.colDef.computedWidth - 34}
      />
    ),
  };
}

function getDefaultProps(
  metric: ReportGridColumn,
  datasourceCode: string,
  type: MetricType,
): Omit<DefaultColumnState, "code" | "hide"> {
  const props = defaultColumnProps[metric.code];

  return {
    width: props?.width ?? metric.name.length * 7 + 80,
    minWidth: props?.minWidth ?? (type === MetricType.String ? 200 : 100),
    sortable:
      metric.isCustomMetricSortable ||
      sortableMetrics[datasourceCode]?.get(metric.code) ||
      false,
    align: metric.code.includes("httpStatusCode")
      ? "center"
      : type === MetricType.Integer || type === MetricType.Decimal
        ? "right"
        : "left",
    ...props,
  };
}

const defaultColumnProps: Record<
  string,
  Partial<Omit<DefaultColumnState, "code" | "hide">>
> = {
  rawHeader: { width: 300, minWidth: 300 },
  ...Object.fromEntries(
    Array.from(
      [...Array(30).keys()].map((x) => [
        `customExtraction${x}`,
        { width: 300, minWidth: 300 },
      ]),
    ),
  ),
  containerExecutionFailures: { minWidth: 200 },
};
