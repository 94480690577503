/* eslint-disable react/display-name */
import { Trans } from "@lumar/shared";
import { Typography } from "@material-ui/core";
import {
  GetSimpleMultiSeriesChartDataDocument,
  ModuleCode,
} from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ChartConfigItemInputSourceType,
  RequiredSources,
} from "../types/ChartConfigItemBase";

interface Options {
  inputSource?: ChartConfigItemInputSourceType;
  visualisationType: VisualisationTypes.Pie | VisualisationTypes.Bar;
  icon?: ChartConfigItem["icon"];
  title: string;
  descriptionTitle?: string;
  description: string;
  reportTemplateCodes: string[];
  testAttributePrefix?: string;
  pendoAttributePrefix?: string;
  requiredSources?: RequiredSources;
  supportedModules?: ModuleCode[];
}

export function generateSimpleMultiSeriesChartConfig({
  visualisationType,
  icon,
  title,
  description,
  reportTemplateCodes,
  testAttributePrefix,
  pendoAttributePrefix,
  requiredSources,
  supportedModules,
  inputSource,
  descriptionTitle,
}: Options) {
  return function (): ChartConfigItem {
    if (!inputSource || inputSource === "CrawlContext") {
      return {
        icon,
        inputSource: "CrawlContext",
        visualisationFamily: "MultiSeries",
        visualisationType: visualisationType,
        supportedModules,
        testAttributePrefix,
        pendoAttributePrefix,
        requiredSources,
        showPercentageInSeriesTooltip:
          visualisationType === VisualisationTypes.Pie ? true : false,
        additionalVisualisationTypes:
          visualisationType === VisualisationTypes.Pie
            ? [VisualisationTypes.Bar]
            : undefined,
        title: () => title,
        description: () => (
          <Trans
            t={(value: unknown) => value}
            defaults={description}
            components={[
              <Typography key="0" paragraph variant="inherit" />,
              <Typography key="1" variant="inherit" />,
            ]}
          />
        ),
        descriptionTitle: descriptionTitle ? () => descriptionTitle : undefined,
        reportStatsLimit: reportTemplateCodes.length,
        reportStatFilter: (report) => {
          return reportTemplateCodes.includes(report.reportTemplateCode);
        },
        reportTemplateCodesOrder: reportTemplateCodes,
        serieName: (report) => {
          return report.reportTemplateName;
        },
        count: (report) => {
          return report.basic ?? 0;
        },
      };
    } else {
      return {
        icon,
        inputSource: "GqlQuery",
        visualisationFamily: "MultiSeries",
        visualisationType: visualisationType,
        supportedModules,
        testAttributePrefix,
        pendoAttributePrefix,
        requiredSources,
        showPercentageInSeriesTooltip:
          visualisationType === VisualisationTypes.Pie ? true : false,
        additionalVisualisationTypes:
          visualisationType === VisualisationTypes.Pie
            ? [VisualisationTypes.Bar]
            : undefined,
        title: () => title,
        description: () => (
          <Trans
            t={(value: unknown) => value}
            defaults={description}
            components={[
              <Typography key="0" paragraph variant="inherit" />,
              <Typography key="1" variant="inherit" />,
            ]}
          />
        ),
        descriptionTitle: descriptionTitle ? () => descriptionTitle : undefined,
        serieName: (report) => {
          return report.reportTemplateName;
        },
        count: (report) => {
          return report.basic ?? 0;
        },
        reportStatsLimit: reportTemplateCodes.length,
        reportTemplateCodesOrder: reportTemplateCodes,
        gqlDocument: GetSimpleMultiSeriesChartDataDocument,
        gqlVariables: ({ crawlContext, segmentId }) => {
          return {
            inputs: reportTemplateCodes.map((reportTemplateCode) => {
              return {
                crawlId: crawlContext.crawl.rawID,
                segmentId,
                reportTemplateCode: reportTemplateCode,
              };
            }),
          };
        },
      };
    }
  };
}
