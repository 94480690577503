import { useSession, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  GetCrawlSettingsForSettingsQuery,
  useGetCrawlSettingsForSettingsQuery,
  useSetCrawlSettingsForSettingsMutation,
} from "../../../../graphql";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";
import {
  getValidationErrors,
  useSettingsDialog,
} from "../../components/SettingsDialog";
import { getFormValues } from "./getFormValues";
import { getMutationData } from "./getMutationData";
import { ContextValues, FormValues } from "./types";
import { useContextValues } from "./useContextValues";

export interface SettingsFormValuesResult {
  formValues: FormValues;
  contextValues: ContextValues;
  loading: boolean;
  error?: string;
  updateProject: (values: FormValues) => Promise<boolean>;
}

export function useSettingsFormValues(): SettingsFormValuesResult {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const { t } = useTranslation("crawlSettings");

  const { enqueueSnackbar } = useSnackbar();
  const { showMessage } = useSettingsDialog();
  const { hasFeatureFlagEnabled } = useSession();

  const { data, error, loading } = useGetCrawlSettingsForSettingsQuery({
    variables: {
      accountId,
      projectId,
    },
  });

  const [updateSettings] = useSetCrawlSettingsForSettingsMutation({
    refetchQueries: ["RunningCrawl", "GetCrawlSettingsForSettings"],
    awaitRefetchQueries: true,
    onError: (error) => {
      const errorMessage = getValidationErrors(error);
      if (errorMessage.length) {
        showMessage({
          errorMessage,
          apiError: error,
          title: t("message.apiErrorTitleSave"),
        });
      } else {
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("message.apiErrorTitleSave")}
            error={error}
          />,
        );
      }
    },
  });

  const accessibilityContainer = getAccesibilityContainer(data);
  const screenshotContainerId =
    data?.getGlobalCustomMetricContainers?.nodes?.find(
      (e) => e.name === "ScreenshotStoring",
    )?.id;
  const htmlContainerId = data?.getGlobalCustomMetricContainers?.nodes?.find(
    (e) => e.name === "HtmlStoring",
  )?.id;

  const formValues = useMemo(
    () =>
      getFormValues(
        data?.getProject,
        accessibilityContainer?.id,
        htmlContainerId,
        screenshotContainerId,
      ),
    [
      data?.getProject,
      accessibilityContainer?.id,
      htmlContainerId,
      screenshotContainerId,
    ],
  );
  const contextValues = useContextValues(
    data,
    accountId,
    projectId,
    accessibilityContainer,
    data?.getGlobalCustomMetricContainers?.nodes,
  );

  return {
    formValues,
    contextValues,
    loading: loading,
    error: error?.message,
    updateProject: async (values) => {
      const result = await updateSettings({
        variables: getMutationData({
          data,
          projectId,
          values,
          initialValues: formValues,
          accessibilityContainerId: accessibilityContainer?.id,
          htmlContainerId,
          screenshotContainerId,
          hasFeatureFlagEnabled,
        }),
      });

      return Boolean(result?.data);
    },
  };
}

function getAccesibilityContainer(
  data: GetCrawlSettingsForSettingsQuery | undefined,
): { id: string; name: string } | undefined {
  const accessibilityContainer =
    data?.accessibilityModule?.customMetricContainers?.find(
      (x) => x.name === "AccessibilityIssues",
    );

  const accessibilityEnabled = Boolean(
    data?.getProject?.customMetricContainerProjects.nodes.find(
      (x) => x.customMetricContainer.id === accessibilityContainer?.id,
    )?.enabled,
  );

  return accessibilityEnabled ? accessibilityContainer : undefined;
}
