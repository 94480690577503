/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { GetAggregateChartDataDocument } from "../../../graphql";

import { getRawCrawlId } from "@lumar/shared";

export function getConversionsVsVisitsChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("conversionsVsVisitsChart.title"),
    description: () => t("conversionsVsVisitsChart.description"),
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          "all_crawled_organic_landing_pages:ga_visits_sum",
          "all_crawled_organic_landing_pages:primary_conversions_sum",
        ].map((reportTemplateCode) => {
          return {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode,
          };
        }),
      };
    },
    reportTemplateCodesOrder: [
      "all_crawled_organic_landing_pages:ga_visits_sum",
      "all_crawled_organic_landing_pages:primary_conversions_sum",
    ],
    reportStatFilter: (report) => {
      return [
        "all_crawled_organic_landing_pages:ga_visits_sum",
        "all_crawled_organic_landing_pages:primary_conversions_sum",
      ].includes(report.reportTemplateCode);
    },
    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      const trend = report.trend ?? [];
      const numericCrawlId = Number(getRawCrawlId(report.crawlId));
      const currentTrend = trend.find((t) => t.crawlId === numericCrawlId);
      return currentTrend?.basic ?? 0;
    },
  };
}
