/* eslint-disable fp/no-mutation */
import {
  AddonAvailability,
  getRawAccountId,
  getRawUserId,
  useAuth,
  useBrand,
  useSession,
  useTranslation,
} from "@lumar/shared";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { useSearchParam } from "../routing/useSearchParam";
import { WindowWithPendo } from "./types";

const beaconId = "beacon-hidden-style";
declare global {
  interface Window {
    dataLayer: unknown[];
    Beacon?: (action: string, event?: string, callback?: () => void) => void;
  }
}

//NOTE: This component controls the Helpscout button visibility.
export function TagsController(): JSX.Element {
  useSimilarwebTags();

  const auth = useAuth();
  const session = useSession();
  const location = useLocation();
  const hiddenBeaconStyle = document.getElementById(beaconId);
  const pendo = (window as WindowWithPendo).pendo;
  const category = useSearchParam("category") || "";

  React.useEffect(() => {
    if (!session?.id) {
      if (!hiddenBeaconStyle) {
        const style = document.createElement("style");
        style.id = beaconId;
        style.innerHTML = "#beacon-container { display:none; }";
        document.head.appendChild(style);
      }
    } else {
      if (hiddenBeaconStyle) {
        hiddenBeaconStyle.parentNode?.removeChild(hiddenBeaconStyle);
      }
    }
  }, [hiddenBeaconStyle, session?.id]);

  const context = useMemo(
    () => ({
      id: session?.id,
      email: session?.email,
      firstName: session?.firstName,
      lastName: session?.lastName,
      permissions: (session?.permissions || []).map((c) => c.code),
      account: session?.account,
      role: session?.role,
      jobTitle: session?.jobTitle,
      ssoClientId: session?.ssoClientId,
      getAddonAvailability: session?.getAddonAvailability,
    }),
    [
      session?.id,
      session?.email,
      session?.firstName,
      session?.lastName,
      session?.permissions,
      session?.account,
      session?.role,
      session?.jobTitle,
      session?.ssoClientId,
      session?.getAddonAvailability,
    ],
  );

  const userId = getRawUserId(context.id ?? "");

  const { i18n } = useTranslation();
  const language = i18n.language;

  const canInitializePendo =
    auth.isAuthenticated && !session?.isUsingSharedLink;

  //NOTE: Sends data to Google Analytics.
  useEffect(() => {
    const account = context.account;

    const data = {
      app: "analyze",
      category: category,
      visitor: {
        id: userId,
        email: context.email ?? "",
        firstName: context.firstName ?? "",
        lastName: context.lastName ?? "",
        permissions: context.permissions,
        jobFunction: context.jobTitle ?? "",
        ssoClientId: context.ssoClientId ?? "",
        userLanguage: language,
      },
      account: {
        id: getRawAccountId(context.account?.id ?? ""),
        accountName: context.account?.name ?? "",
        role: context.role?.name ?? "",
        planLevel: context.account?.subscription.plan.name ?? "",
        planStatus: context.account?.subscription.plan.status ?? "",
        minCommitmentPeriod:
          context.account?.subscription.plan.minCommitmentPeriod ?? 0,
        period: context.account?.subscription.plan.period ?? 0,
        paidAddons: account
          ? account.subscription.addons.flatMap((addon) =>
              addon.settings
                .filter(
                  (setting) =>
                    context.getAddonAvailability(setting.code) ===
                    AddonAvailability.Paid,
                )
                .map((setting) => setting.name),
            )
          : [],
        freemiumAddons: account
          ? account.subscription.addons.flatMap((addon) =>
              addon.settings
                .filter(
                  (setting) =>
                    context.getAddonAvailability(setting.code) ===
                    AddonAvailability.Free,
                )
                .map((setting) => setting.name),
            )
          : [],
        subscriptionStatus: context.account?.subscription.status ?? "",
        accountManagers: context.account?.accountManagers ?? [],
        availableCredits: context.account?.availableCredits ?? 0,
      },
    };

    if (canInitializePendo) {
      pendo?.initialize(data);
    }

    if (window.dataLayer) {
      // eslint-disable-next-line fp/no-mutating-methods
      window.dataLayer.push({
        event: "routeChange",
        data: {
          app: "analyze",
          category: category,
          visitor: {
            id: userId,
            email: context.email ?? "",
            firstName: context.firstName ?? "",
            lastName: context.lastName ?? "",
            jobFunction: context.jobTitle ?? "",
            ssoClientId: context.ssoClientId ?? "",
            userLanguage: language,
            permissions: Object.fromEntries(
              (context.permissions || []).map((code) => [code, true]),
            ),
          },
          account: {
            id: getRawAccountId(context.account?.id ?? ""),
            accountName: context.account?.name ?? "",
            role: context.role?.name ?? "",
            planLevel: context.account?.subscription.plan.name ?? "",
            planStatus: context.account?.subscription.plan.status ?? "",
            minCommitmentPeriod:
              context.account?.subscription.plan.minCommitmentPeriod ?? 0,
            period: context.account?.subscription.plan.period ?? 0,
            paidAddons: account
              ? account.subscription.addons.flatMap((addon) =>
                  addon.settings
                    .filter(
                      (setting) =>
                        context.getAddonAvailability(setting.code) ===
                        AddonAvailability.Paid,
                    )
                    .map((setting) => setting.name),
                )
              : [],
            freemiumAddons: account
              ? account.subscription.addons.flatMap((addon) =>
                  addon.settings
                    .filter(
                      (setting) =>
                        context.getAddonAvailability(setting.code) ===
                        AddonAvailability.Free,
                    )
                    .map((setting) => setting.name),
                )
              : [],
            subscriptionStatus: context.account?.subscription.status ?? "",
            accountManagers: context.account?.accountManagers ?? [],
            availableCredits: context.account?.availableCredits ?? 0,
          },
        },
        route: `${location.pathname}${location.search}`,
      });
    }
  }, [
    canInitializePendo,
    pendo,
    context,
    userId,
    language,
    location,
    category,
  ]);

  return <></>;
}

const useSimilarwebTags = (): void => {
  const { auth0User } = useAuth();
  const location = useLocation();

  const session = useSession();
  const { brandId } = useBrand();

  useEffect(() => {
    if (auth0User?.sub) {
      const [, ssoProvider, userId] = auth0User.sub.split("|");
      if (window.dataLayer && ssoProvider === "similarweb") {
        // eslint-disable-next-line fp/no-mutating-methods
        window.dataLayer.push({
          event: "routeChange",
          user_id: userId,
        });
      }
    }
  }, [auth0User?.sub, location]);

  const userId = auth0User?.sub?.split("|")[2];
  const accountId = getRawAccountId(session.account.id);

  React.useEffect(() => {
    if (!window.dataLayer || brandId !== "similarweb") return;

    // eslint-disable-next-line fp/no-mutating-methods
    window.dataLayer.push({
      event: "updateUserData",
      user_id: userId,
      account_id: accountId,
    });
  }, [brandId, userId, accountId]);
};
