/* eslint-disable react/display-name */
import { Theme, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import {
  CrawlType,
  GetCrawlSourceOverlapChartDataDocument,
} from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getCrawlSourceOverlapSitemapsChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "Venn",
    visualisationType: VisualisationTypes.Venn,
    defaultSelectedCrawlType: CrawlType.Sitemap,
    visualisationColors: [
      theme.palette.grey[500],
      theme.palette.yellow[300],
      theme.palette.green[500],
      theme.palette.red[500],
    ],
    title: () => t("crawlSourceOverlap.title"),
    description: () => (
      <Trans ns="charts" i18nKey="crawlSourceOverlap.description">
        <Typography paragraph variant="inherit">
          This chart shows the overlapping and non-overlapping URLs between a
          single &apos;primary&apos; source of URLs and all other sources
          included in the crawl.
        </Typography>
        <Typography paragraph variant="inherit">
          It can be used to identify groups of URLs which are only in the
          primary source, or only in the compared sources.
        </Typography>
        <Typography variant="inherit">
          The source with the largest number of URLs will be the default source
          against which the others are compared. This can be changed in the
          graph configuration.
        </Typography>
      </Trans>
    ),
    gqlDocument: GetCrawlSourceOverlapChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        crawlId: crawlContext.crawl.rawID,
        segmentId,
      };
    },
    series: (reports) => {
      const report = reports[0];
      const crawl = report?.crawl;

      const data = (crawl?.crawlTypeCounts ?? []).map(
        ({ crawlTypes, count }) => {
          return {
            sets: crawlTypes,
            value: count,
            totalUrls: report.basic ?? 0,
          };
        },
      );

      const supportedCrawlTypesData = data.filter((datum) =>
        datum.sets.every((set) => crawl?.crawlTypes?.includes(set)),
      );

      return {
        name: "URLs",
        data: supportedCrawlTypesData,
      };
    },

    testAttributePrefix: "crawl-source-overlap-chart",
  };
}
