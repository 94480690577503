import { useQuery } from "@lumar/shared";
import {
  ReportStatColumnsMetadataQueryResult,
  CustomReportColumnsMetadataQueryResult,
} from "../../../../graphql";
import { getReportGridColumnsMetadataQuery } from "./ReportGridColumns.helpers";
import { ReportInput } from "../../../Report.types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useReportColumnsMetadataQuery(reportInput: ReportInput) {
  const { document, variables } = getReportGridColumnsMetadataQuery({
    reportInput,
  });
  return useQuery<
    | ReportStatColumnsMetadataQueryResult["data"]
    | CustomReportColumnsMetadataQueryResult["data"]
  >(document, {
    variables,
    fetchPolicy: "cache-first",
  });
}
