import {
  fieldToTextField,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Label } from "./Label";
import { Field, FieldProps } from "formik";
import { makeStyles } from "@material-ui/core";
import isUrl from "validator/lib/isURL";

export function DescriptionField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  return (
    <Field name="description">
      {(props: FieldProps<string>) =>
        !isEditing ? (
          <>
            <Label label={t("editDialog.descriptionLabel")} />
            <Typography className={classes.description}>
              {(props.field.value || "-").split(/(\s+)/).map((x) => (
                <>
                  {isUrl(x, { require_protocol: true }) ? (
                    <a href={x} rel="noreferrer" target="_blank">
                      {x}
                    </a>
                  ) : (
                    x
                  )}
                </>
              ))}
            </Typography>
          </>
        ) : (
          <TextField
            {...fieldToTextField(props)}
            id="edit-task-description"
            name="description"
            className={classes.input}
            label={t("editDialog.descriptionLabel")}
            placeholder={t("editDialog.desctiptionPlaceholder")}
            multiline
            maxRows={8}
            minRows={8}
            disabled={props.form.isSubmitting}
            data-testid="edit-task-description"
            InputLabelProps={{ className: classes.label }}
          />
        )
      }
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  description: {
    display: "block",
    whiteSpace: "pre-line",
    lineHeight: theme.typography.pxToRem(20),
    minHeight: theme.typography.pxToRem(80),
    overflowWrap: "break-word",
    margin: theme.spacing(1, 0, 2, 0),
  },
  input: {
    width: "100%",
    margin: theme.spacing(0, 0, 2, 0),
  },
  label: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },
}));
