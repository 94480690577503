import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { CreateTaskFormValuesResult } from "../data/useEditTaskFormValues";
import { Label } from "./Label";
import {
  Alert,
  Trans,
  Typography,
  useBrand,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import { DescriptionField } from "./DescriptionField";
import { HowToFixField } from "./HowToFixField";
import { AssigneeField } from "./AssigneeField";
import { PriorityField } from "./PriorityField";
import { StatusField } from "./StatusField";
import { DeadlineField } from "./DeadlineField";

export function EditTaskForm({
  task,
  isEditing,
  actions,
  summary,
}: {
  task: CreateTaskFormValuesResult["task"];
  isEditing: boolean;
  actions?: React.ReactNode;
  summary?: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["taskManager", "units"]);
  const formatDate = useDateFormatter();
  const brand = useBrand();

  const fixedAt = task?.fixedAt ? new Date(task.fixedAt) : undefined;

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} md={8} className={classes.contentLeft}>
        {!isEditing && summary}
        <div {...(!isEditing && { className: classes.contentLeftScrollable })}>
          <DescriptionField isEditing={isEditing} />
          <HowToFixField isEditing={isEditing} />
        </div>
        {Boolean(fixedAt) && (
          <Alert
            severity="success"
            size="small"
            className={classes.archivedAlert}
          >
            <Trans
              ns="taskManager"
              i18nKey="editDialog.archivedMessage"
              values={{ fixedAt }}
            />
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} md={4} className={classes.contentRight}>
        {brand.featureAvailability.taskManagerEmailAssignment && (
          <AssigneeField isEditing={isEditing} />
        )}
        <PriorityField isEditing={isEditing} />

        <Label label={t("taskManager:editDialog.createdAt")} />
        <Typography variant="subtitle3Medium" className={classes.createdAt}>
          {task?.createdAt
            ? formatDate(new Date(task.createdAt), {
                dateStyle: "medium",
              })
            : "-"}
        </Typography>

        <DeadlineField isEditing={isEditing} />
        <StatusField isEditing={isEditing} />

        {Boolean(task?.reportTemplate) && (
          <>
            <Label label={t("taskManager:editDialog.baseReport")} />
            <Typography variant="subtitle3Medium" className={classes.fixedAt}>
              {task?.reportTemplate?.name}
            </Typography>
          </>
        )}

        {!isEditing && Boolean(actions) && (
          <>
            <Label label={t("taskManager:editDialog.actions")} />
            <div className={classes.actions}>{actions}</div>
          </>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  contentLeft: {
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(2.625),
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  contentLeftScrollable: {
    display: "block",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: theme.typography.pxToRem(400),
    paddingRight: theme.spacing(2.625),
  },
  contentRight: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(2.625),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      borderLeft: "none",
    },
  },
  urlContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(3.625),
    "& > *:nth-child(2n)": {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  urlItem: {
    flexBasis: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.625),
    padding: theme.spacing(1.375, 0, 1, 0),
    color: theme.palette.grey[800],
    "& > *:first-child": {
      lineHeight: theme.typography.pxToRem(19),
    },
    "& > *:last-child": {
      lineHeight: theme.typography.pxToRem(15),
    },
  },
  urlButton: {
    flexBasis: 0,
    flexGrow: 1,
    borderRadius: 0,
    padding: 0,
  },
  arrowIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
  createdAt: {
    display: "block",
    margin: theme.spacing(0.75, 0, 1, 0),
    color: theme.palette.grey[700],
  },
  fixedAt: {
    display: "block",
    margin: theme.spacing(0.875, 0, 1.5, 0),
    color: theme.palette.grey[700],
  },
  actions: {
    margin: theme.spacing(1, 0, 1.5, 0),
    display: "flex",
    flexDirection: "column",
    maxWidth: 260,
    gap: theme.spacing(1),
  },
  archivedAlert: {
    marginTop: "auto",
    marginBottom: theme.spacing(2),
  },
}));
