/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlType,
  GetDepthChartDataDocument,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";

export function getWebNotInSourceByLevelChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.StackedColumn,
    additionalVisualisationTypes: [
      VisualisationTypes.Line,
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedColumn,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("crawlSourcebyInNotInChart.title"),
    description: () => t("crawlSourcebyInNotInChart.description"),
    gqlDocument: GetDepthChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          "pages_not_in_web_crawl",
          "missing_in_backlinks_crawl",
          "missing_in_sitemaps",
          "missing_in_organic_landing_pages",
          "pages_not_in_log_summary",
          "pages_not_in_list",
        ].map((reportTemplateCode) => {
          return {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode,
          };
        }),
      };
    },
    reportTemplateCodesOrder: [
      "pages_not_in_web_crawl",
      "missing_in_backlinks_crawl",
      "missing_in_sitemaps",
      "missing_in_organic_landing_pages",
      "pages_not_in_log_summary",
      "pages_not_in_list",
    ],
    requiredSources: {
      sources: [CrawlType.Web],
      incompleteMessage: t("webCrawlDepth.requiredSourcesMessage"),
    },
    xAxisLabel: "Level",
    series: (reports, linkData) => {
      return reports.map((report) => ({
        name: report.reportTemplateName,
        reportTemplateCode: report.reportTemplateCode,
        data: (report.crawlUrlsAggregates?.nodes ?? []).map(
          (crawlUrlsAggregate) => {
            const depthLevel = crawlUrlsAggregate?.level ?? 0;
            const urlCount = crawlUrlsAggregate?.count?.url ?? 0;

            return {
              x: depthLevel,
              y: urlCount,
              url: Routes.Report.getUrl({
                ...linkData,
                reportTemplateCode: report.reportTemplateCode,
                reportTypeCode: "basic",
                filter: createConnectionFilter({
                  or: [
                    {
                      and: [
                        {
                          metricCode: "level",
                          predicateKey: ConnectionPredicate.Eq,
                          predicateValue: depthLevel,
                        },
                      ],
                    },
                  ],
                }),
              }),
              additionalTooltipLines: [
                t("webCrawlDepth.depthLevel", {
                  depthLevel,
                }),
              ],
            };
          },
        ),
      }));
    },

    testAttributePrefix: "web-crawl-depth-chart",
  };
}
