import { InformationCircleOutlined } from "@lumar/shared";
import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 22,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
    marginLeft: 17,
  },
  contentRoot: {
    padding: "12px 16px",
    position: "relative",
    minHeight: 71,
    "&:last-child": {
      paddingBottom: 12,
    },
  },
  header: {
    borderBottom: "solid 1px #E5E7EB",
    color: theme.palette.grey[800],
    fontWeight: 500,
    padding: 16,
    display: "flex",
    alignItems: "center",
  },
  headerTitle: { fontSize: "19px" },
  headerTooltip: {
    marginLeft: 8,
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(22),
    margin: theme.spacing(0.375),
  },
}));

export function ProgressDashboardContainer(props: {
  children?: ReactNode;
  headerTitle?: string;
  headerTooltip?: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Paper className={classes.container} elevation={0}>
      {props.headerTitle ? (
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>
            {props.headerTitle}
          </Typography>
          {props.headerTooltip ? (
            <Tooltip title={props.headerTooltip} placement="right">
              <InformationCircleOutlined className={classes.headerTooltip} />
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      <div className={classes.contentRoot}>{props.children}</div>
    </Paper>
  );
}
