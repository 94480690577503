/* eslint-disable fp/no-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Highcharts, {
  ColorString,
  Options,
  SeriesAreaOptions,
  SeriesLineOptions,
} from "highcharts";
import { TFunction } from "i18next";
import { baseChartOptions } from "../_common/charts/base-chart-options";
import { baseTooltipOptions } from "../_common/charts/tooltip-config";

const gradientStart = Highcharts.color("#147DF8" as ColorString)
  .setOpacity(0)
  .get("rgba");

function speedChartOptions(t: TFunction<"crawlProgress">): Options {
  return {
    ...baseChartOptions,
    accessibility: {
      series: {
        pointDescriptionEnabledThreshold: false,
      },
    },
    chart: {
      height: 172,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: { second: "%H:%M:%S", millisecond: "%H:%M:%S" },
    },
    yAxis: { type: "linear", title: { style: { display: "none" } } },
    legend: { enabled: false },
    tooltip: {
      ...baseTooltipOptions,
      formatter: function format() {
        const isExpected = this.series.name.toLowerCase().includes("maximum");
        return isExpected
          ? t("charts.maxSpeedTooltip", { count: this.y || 0 })
          : t("charts.speedTooltip", { count: this.y || 0 });
      },
    },
    series: [
      {
        marker: { enabled: false },
        lineWidth: 1,
        color: "#3B82F6",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "#fff"],
            [1, gradientStart.toString()],
          ],
        },
        type: "area",
        name: t("charts.speed"),
        data: [],
      },
      {
        marker: { enabled: false },
        lineWidth: 2,
        color: "#DC2626",
        dashStyle: "ShortDash",
        type: "line",
        name: t("charts.maxSpeed"),
        data: [],
      },
    ],
  };
}

export interface SpeedPoint {
  speed: number;
  expected: number;
  time: number;
}

export interface SpeedChartData {
  speed: [number, number][];
  limit: [number, number][];
}

export function getSpeedChartOptions(
  t: TFunction<"crawlProgress">,
  data: SpeedChartData,
  offset: number,
): Options {
  const options = speedChartOptions(t);
  const currentAreaSeries = options.series?.[0] as SeriesAreaOptions;
  const currentLineSeries = options.series?.[1] as SeriesLineOptions;

  const speed =
    data.speed.length === 1
      ? [data.speed[0], [data.speed[0][0] + 1, data.speed[0][1]]]
      : data.speed;
  const limit =
    data.limit.length === 1
      ? [data.limit[0], [data.limit[0][0] + 1, data.limit[0][1]]]
      : data.limit;

  return {
    ...options,
    series: [
      {
        ...currentAreaSeries,
        data: speed.map(([x, y]) => [x + offset, y]),
      },
      {
        ...currentLineSeries,
        data: limit.map(([x, y]) => [x + offset, y]),
      },
    ],
  };
}
