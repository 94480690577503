/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ModuleCode,
  ReportStatTrendItem,
  GetAggregateChartDataDocument,
} from "../../../graphql";
import { Routes } from "../../routing/routes";

import { DateFormatter, getRawCrawlId } from "@lumar/shared";

export function getSiteSpeedAvgTBTChart(
  t: TFunction<"charts">,
  theme: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Column,
    icon: "lighthouse",
    title: () => t("siteSpeedTBTMetricTrend.title"),
    description: () => t("siteSpeedTBTMetricTrend.description"),
    legendEnabled: false,
    showXAxisLabel: false,
    showYAxisLabel: false,
    showPercentageInSeriesTooltip: false,
    presentLastValue: true,
    showBorderAtColumnTop: true,
    pointPadding: 0,
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode: "site_speed_pages:total_blocking_time_avg",
          },
        ],
      };
    },
    categoryCsvColumnHeaderName: "DateTime",
    seriesCategories: (series) => {
      return (
        series[0]?.data.map((data) => String(data.metadata?.createdAt)) || []
      );
    },
    series: (reports, { accountId, projectId, segmentId }) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: (report.trend ?? [])
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .sort((a, b) => a.crawlId - b.crawlId)
            .map((trend, index) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: index,
                y: trend.basic ?? 0,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode:
                    report.reportTemplate?.code ?? report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
                metadata: {
                  createdAt: trend.createdAt,
                },
              };
            }),
        };
      }),
  };
}
