/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlType,
  GetSearchClicksByDeviceDocument,
  GetSearchClicksByDeviceQuery,
} from "../../../graphql";

import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";

export function getSearchClicksByDeviceChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Table,
    visualisationColors: [
      theme.palette.green[500],
      theme.palette.grey[500],
      theme.palette.purple[600],
    ],
    secondaryVisualisationColors: [
      theme.palette.green[100],
      theme.palette.grey[100],
      theme.palette.purple[100],
    ],
    title: () => t("searchClicksByDevice.title"),
    description: () => t("searchClicksByDevice.description"),
    gqlDocument: GetSearchClicksByDeviceDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          "indexable_pages",
          "non_indexable_pages",
          "mobile_alternates",
        ].map((reportTemplateCode) => {
          return {
            crawlId: crawlContext.crawl.id,
            segmentId,
            reportTemplateCode,
          };
        }),
      };
    },
    reportTemplateCodesOrder: [
      "indexable_pages",
      "non_indexable_pages",
      "mobile_alternates",
    ],
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("searchClicksByDevice.requiredSourcesMessage"),
    },
    series: (
      reports: NonNullable<GetSearchClicksByDeviceQuery["getReportStats"]>,
      linkData,
    ) => {
      return reports.map((report) => {
        const desktopClicks =
          report.searchConsoleDesktopClicks?.totalCount ?? 0;
        const mobileClicks = report.searchConsoleMobileClicks?.totalCount ?? 0;
        const tabletClicks = report.searchConsoleTabletClicks?.totalCount ?? 0;
        const total = report.basic ?? 0;

        const data = [
          {
            x: t("searchClicksByDevice.desktop"),
            y: desktopClicks,
            percentage: total ? desktopClicks / total : 0,
            url: generateUrl(
              report.reportTemplateCode,
              "searchConsoleDesktopClicks",
              linkData,
            ),
          },
          {
            x: t("searchClicksByDevice.mobile"),
            y: mobileClicks,
            percentage: total ? mobileClicks / total : 0,
            url: generateUrl(
              report.reportTemplateCode,
              "searchConsoleMobileClicks",
              linkData,
            ),
          },
          {
            x: t("searchClicksByDevice.tablet"),
            y: tabletClicks,
            percentage: total ? tabletClicks / total : 0,
            url: generateUrl(
              report.reportTemplateCode,
              "searchConsoleTabletClicks",
              linkData,
            ),
          },
        ];

        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data,
        };
      });
    },
    pendoAttributePrefix: "pages-with-clicks-by-device-chart",
    testAttributePrefix: "pages-with-clicks-by-device-chart",
  };
}

function generateUrl(
  reportTemplateCode: string,
  metricCode: string,
  linkData: LinkData,
): string {
  return Routes.Report.getUrl({
    ...linkData,
    reportTemplateCode: reportTemplateCode,
    reportTypeCode: "basic",
    filter: createConnectionFilter({
      or: [
        {
          and: [
            {
              metricCode,
              predicateKey: ConnectionPredicate.Gt,
              predicateValue: 0,
            },
          ],
        },
      ],
    }),
  });
}
