/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType } from "../../../graphql";

export function getConversionPagesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.GoogleAnalytics],
      incompleteMessage: t("requiredSourcesMessage.analyticsSource"),
    },
    title: () => t("conversionPagesChart.title"),
    description: () => t("conversionPagesChart.description"),
    reportTemplateCodesOrder: [
      "primary_pages_with_visits_and_conversions",
      "primary_pages_with_visits_without_conversions",
    ],
    reportStatFilter: (report) => {
      return [
        "primary_pages_with_visits_and_conversions",
        "primary_pages_with_visits_without_conversions",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
