import {
  ApolloError,
  BlueDataGrid,
  BlueGridRowSelector,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridApiRef, useGridApiRef } from "@mui/x-data-grid-pro";
import { createContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { useDebounedFunction } from "../../../_common/useDebounedFunction";
import {
  CrawlStatus,
  CrawlTypeMetadata,
  DatasourceCode,
  RoleCode,
} from "../../../graphql";
import { ColumnSelector } from "./columns/column-selector/ColumnSelector";

import { ReportRowsFilter } from "../../../_common/connection-filtering/types";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { CreateCustomReportDialog } from "../../../custom-report/create-custom-report/CreateCustomReportDialog";
import { ReportEntity, ReportInput } from "../../Report.types";
import { getRowHeight } from "./_common/row-height-utils";
import { ReportGridColumn } from "./columns/ReportGridColumns.types";
import { useReportGridColumns } from "./columns/useReportGridColumns";
import { DataGridStickyScroll } from "./DataGridStickyScroll";
import { ReportGridTabs, ReportGridTabsMode } from "./grid-tabs/GridTabs";
import { MetricData, MetricGrouping } from "./ReportGrid.types";
import { ReportGridAlerts } from "./rows/alerts/ReportGridAlerts";
import { ColumnSelectorButton } from "./rows/columns-selector-button/ColumnSelectorButton";
import { ExportRows } from "./rows/export-rows/ExportRows";
import { RowsFilters } from "./rows/rows-filters/RowsFilters";
import { TasksMenu } from "./rows/tasks/TasksMenu";
import { useReportGridRows } from "./rows/useReportGridRows";
import { TableGridToggle } from "./TableGridToggle";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    color: theme.palette.grey[800],
    "& .MuiDataGrid-toolbarContainer": {
      maxHeight: 64,
    },
  },
  rightTabContent: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface GridContext {
  datasourceCode?: DatasourceCode;
  crawlTypesMetadata: Array<Pick<CrawlTypeMetadata, "id" | "name" | "code">>;
  apiRef: GridApiRef;
  filter?: ReportRowsFilter;
  compareToCrawlId: string | undefined;
  totalCount: number;
  loading: boolean;
  isGridView: boolean;
  toggleGridView: (checked: boolean) => void;
  reportInput: ReportInput;
  filterMetrics: MetricData[];
  cardMetrics: ReportGridColumn[];
  foundInSources: ReportGridColumn[];
}

export interface ReportGridProps {
  reportInput: ReportInput;
  baseFilter?: Record<string, unknown>;
  tabsMode?: ReportGridTabsMode;
  onTabChange?: (value: string) => void;
  hideTaskMenu?: boolean;
  columnPersistanceKey?: string;
  overwrites?: {
    metricsGroupings?: Array<MetricGrouping>;
    filter?: ReportRowsFilter;
  };
  reportTemplateQueryVersion?: number;
  lastFinishedCrawlId?: string;
  hideCreateCustomReport?: boolean;
  error?: ApolloError | undefined;
}

export const GridContext = createContext({} as GridContext);

const DEFAULT_PAGE_SIZE = 20;

export function ReportGrid(props: ReportGridProps): JSX.Element {
  const {
    reportInput,
    baseFilter,
    tabsMode = "visible",
    hideTaskMenu,
    columnPersistanceKey,
    overwrites,
    reportTemplateQueryVersion = 1,
    lastFinishedCrawlId = "",
    hideCreateCustomReport,
    error: errorParam,
  } = props;

  const { t } = useTranslation("report");
  const classes = useStyles();
  const { account, hasSufficientRole, hasFeatureFlagEnabled } = useSession();
  const { crawl, projectAllCustomReportTemplates } = useCrawlContextData();

  const { crawlId, projectId, accountId } = useParams<{
    crawlId: string;
    projectId: string;
    accountId: string;
  }>();

  const showTasks = !hideTaskMenu && hasSufficientRole(RoleCode.Editor);
  const showCreateCustomReport =
    !hideCreateCustomReport &&
    hasFeatureFlagEnabled("custom-reports") &&
    hasSufficientRole(RoleCode.Editor);

  const {
    loading: columnsLoading,
    error: columnError,
    definitions,
    cardMetrics,
    defaultMetrics,
    foundInSources,
    storedStates,
    isCrawlIncomplete,
    compareToCrawlId,
    metricsData,
    filterMetrics,
    datasourceCode,
    crawlTypesMetadata,
    saveColumnsState,
    isGridView,
    toggleGridView,
  } = useReportGridColumns({
    reportInput,
    columnPersistanceKey,
    overwrites,
  });

  const {
    rows,
    reportQueryVersion,
    totalCount,
    totalRows,
    pagination,
    loading: dataLoading,
    error: dataError,
    diffs,
    diffTotalRows,
    customFilter,
  } = useReportGridRows({
    pageSize: DEFAULT_PAGE_SIZE,
    metricsData,
    datasourceCode,
    baseFilter,
    reportInput,
    fetchFilteredMissing: crawl.comparedTo?.statusEnum === CrawlStatus.Finished,
  });

  const apiRef = useGridApiRef();
  const loading = dataLoading || columnsLoading;
  const error = dataError || columnError || errorParam;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    storedStates?.filter((x) => !x.hide).map((x) => x.code) ?? [],
  );

  useEffect(() => {
    setVisibleColumns(
      storedStates?.filter((x) => !x.hide).map((x) => x.code) ?? [],
    );
  }, [storedStates]);

  const { debounce } = useDebounedFunction(300);

  if (isCrawlIncomplete) {
    return (
      <Redirect
        exact
        to={Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "progress",
        })}
      />
    );
  }

  const rowHeight = (() => {
    if (isGridView) return 42;
    return getRowHeight({
      rows,
      visibleColumns,
      definitions,
      cardMetrics,
      hasFounInMetric: Boolean(foundInSources.length),
      metricsData,
      isGridView,
    });
  })();

  const errorMessage =
    error?.graphQLErrors[0]?.message ??
    error?.networkError?.message ??
    error?.clientErrors[0]?.message ??
    error?.protocolErrors[0]?.message;

  return (
    <GridContext.Provider
      value={{
        datasourceCode,
        crawlTypesMetadata,
        apiRef,
        compareToCrawlId,
        totalCount,
        loading: loading,
        isGridView,
        toggleGridView,
        reportInput,
        filter: customFilter,
        filterMetrics,
        cardMetrics,
        foundInSources,
      }}
    >
      <div>
        <ReportGridTabs
          mode={tabsMode}
          diffs={diffs}
          totalRows={diffTotalRows}
          loading={loading}
          reportInput={reportInput}
          onTabChange={props.onTabChange}
        >
          <div className={classes.rightTabContent}>
            {showCreateCustomReport ? (
              <CreateCustomReportDialog
                accountId={accountId}
                crawlId={crawlId}
                lastFinishedCrawlId={lastFinishedCrawlId}
                projectId={projectId}
                filter={customFilter}
                reportTemplateCode={reportInput.reportTemplateCode}
                gridApiRef={apiRef}
                reportTemplateQueryVersion={reportTemplateQueryVersion}
                reportQueryVersion={reportQueryVersion ?? 1}
                cardMetrics={cardMetrics}
                foundInSources={foundInSources}
                maxCustomReportsReached={
                  projectAllCustomReportTemplates.length >=
                  account.maxCustomReportsPerProject
                }
              />
            ) : null}
            {showTasks && (
              <TasksMenu
                totalCount={totalCount}
                reportInput={{
                  reportEntity: ReportEntity.LegacyReport,
                  crawlId: reportInput.crawlId,
                  reportTypeCode: reportInput.reportTypeCode,
                  reportTemplateCode: reportInput.reportTemplateCode,
                  segmentId: reportInput.segmentId,
                }}
              />
            )}
          </div>
        </ReportGridTabs>
        <ReportGridAlerts error={error}>
          <DataGridStickyScroll data-testid="urls-table">
            <BlueDataGrid
              sticky
              rowHeight={rowHeight}
              disableColumnSelector={false}
              defaultPaginationState={{ pageSize: DEFAULT_PAGE_SIZE }}
              onColumnResize={(p, e, d) =>
                debounce(() => saveColumnsState(d.api.state))
              }
              onColumnOrderChange={(p, e, d) => {
                debounce(() => saveColumnsState(d.api.state));
              }}
              onColumnVisibilityChange={(p, e, d) => {
                debounce(() => saveColumnsState(d.api.state));
                if (p.isVisible) {
                  setVisibleColumns((visibleColumns) => [
                    p.field,
                    ...visibleColumns,
                  ]);
                } else if (!p.isVisible) {
                  setVisibleColumns((visibleColumns) =>
                    visibleColumns.filter((x) => x !== p?.field),
                  );
                }
              }}
              apiRef={apiRef}
              disableColumnsButton
              showCellRightBorder
              showColumnRightBorder
              rows={rows ? rows : []}
              loading={loading}
              visibleColumnsCount={visibleColumns.length}
              rowCount={totalCount}
              totalRowCount={totalRows}
              error={error}
              columns={definitions}
              classes={{ root: classes.root }}
              pagination
              components={{
                ToolbarLeft: [
                  RowsFilters,
                  ColumnSelectorButton,
                  TableGridToggle,
                ],
                ToolbarRight: [BlueGridRowSelector, ExportRows],
                ColumnsPanel: ColumnSelector,
              }}
              componentsProps={{
                columnMenu: { style: { paddingLeft: 8, paddingRight: 8 } },
                toolbar: {
                  disabled: Boolean(error),
                },
                columnsPanel: {
                  defaultColumns: [
                    "card",
                    ...defaultMetrics.map((x) => x.code),
                  ],
                  insertIndex: isGridView ? 0 : 1,
                },
                pagination: {
                  hideLastPages: true,
                },
                noRowsOverlay: {
                  title: t("noRowsTitle"),
                  detail: t("noRowsDetail"),
                },
                noResultsOverlay: {
                  title: t("noResultsTitle"),
                  detail: t("noResultsDetail"),
                },
                noColumnsOverlay: {
                  title: t("noColumnsTitle"),
                  detail: t("noColumnsDetail"),
                },
                errorOverlay: {
                  hideReloadButton: !Boolean(customFilter),
                  detail: errorMessage,
                  onReload: () => {
                    // One of the possible cause of the error state is a malformed/invalid filter or
                    // pagination data. In this case a simple reload would not solve the issue, and
                    // because the overlay hides the grid's controls the user is not able to change or
                    // clear these values. - Csaba

                    /**
                     * Added a full page reload by replacing the current URL with the same URL but without
                     * the filter and pagination query parameters, as there are cases where the error might
                     * be caused by another component like GraphQLClient and calling history.push() would
                     * not reset those components causing the issue to persist and forcing the user to manually
                     * reload the full page.
                     * @author Alex Sánchez
                     */
                    const currentUrl = new URL(window.location.href);
                    currentUrl.searchParams.delete("filter");
                    currentUrl.searchParams.delete("pagination");

                    window.location.replace(currentUrl.href);
                  },
                },
              }}
              paginationMode="server"
              {...pagination}
              rowsPerPageOptions={[10, 20, 50, 100]}
              sortingOrder={["desc", "asc", null]}
            />
          </DataGridStickyScroll>
        </ReportGridAlerts>
      </div>
    </GridContext.Provider>
  );
}
