import { TFunction } from "i18next";
import { useParams } from "react-router";
import { useSearchParam } from "../../../../../_common/routing/useSearchParam";
import { useGetSiteSpeedChartsDataQuery } from "../../../../../graphql";
import { getReportsWithAlignedTrends } from "./getReportsWithAlignedTrends";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";
import { ChartPanelErrorMessage } from "../../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";
import { useCrawlContext } from "../../../../CrawlContext";
import { SiteSpeedSmallChartPanelTitle } from "./SiteSpeedSmallChartPanelTitle";
import { SiteSpeedSmallColumnChart } from "./SiteSpeedSmallColumnChart";
import { getIconForChart } from "../getIconForChart";
import { ChartIconNames } from "../../../../../_common/charts/types/ChartConfigItemBase";

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    marginTop: "16px",
    width: "100%",
    justifyContent: "center",
  },
  tile: {
    display: "block",
    background: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    flexGrow: 1,
    flexBasis: 0,
    color: "inherit",
    padding: 8,
    height: 101,
    minWidth: 210,
    [theme.breakpoints.down("md")]: {
      minWidth: 292,
      maxWidth: "100%",
    },
  },
}));

export function SiteSpeedSmallCharts(): JSX.Element {
  const segmentId = useSearchParam("segmentId");
  const { crawlId } = useParams<{ crawlId: string }>();
  const classes = useStyles();
  const { t } = useTranslation("charts");
  const chartConfigs = getChartConfigurations(t);
  const reportTemplateCodes = Object.keys(chartConfigs);

  const crawlContext = useCrawlContext();
  const chartDataQuery = useGetSiteSpeedChartsDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      inputs: reportTemplateCodes.map((reportTemplateCode) => ({
        crawlId,
        segmentId,
        reportTemplateCode,
      })),
    },
  });

  const stubRows = [
    reportTemplateCodes.slice(0, reportTemplateCodes.length / 2),
    reportTemplateCodes.slice(
      reportTemplateCodes.length / 2,
      reportTemplateCodes.length,
    ),
  ];

  if (chartDataQuery.loading || crawlContext.loading) {
    return (
      <>
        {stubRows.map((reportTemplateCodes, index) => {
          return (
            <div className={classes.tileContainer} key={index}>
              {reportTemplateCodes.map((reportTemplateCode) => {
                return (
                  <div
                    key={reportTemplateCode}
                    className={classes.tile}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "auto" }}>
                      <CircularProgress />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

  if (chartDataQuery.error) {
    return (
      <>
        {stubRows.map((reportTemplateCodes, index) => {
          return (
            <div className={classes.tileContainer} key={index}>
              {reportTemplateCodes.map((reportTemplateCode) => {
                return (
                  <div
                    key={reportTemplateCode}
                    className={classes.tile}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ margin: "auto" }}>
                      <ChartPanelErrorMessage>
                        {t("errors.queryError")}
                      </ChartPanelErrorMessage>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

  const reportsWithAlignedTrends = getReportsWithAlignedTrends(
    chartDataQuery.data?.getReportStats ?? [],
  );

  const chartDataMap = new Map(
    reportTemplateCodes.map((reportTemplateCode) => {
      return [
        reportTemplateCode,
        reportsWithAlignedTrends.find(
          (report) => report.reportTemplateCode === reportTemplateCode,
        ),
      ];
    }),
  );

  const entries = Array.from(chartDataMap.entries());
  const rows = [
    entries.slice(0, entries.length / 2),
    entries.slice(entries.length / 2, entries.length),
  ];

  return (
    <>
      {rows.map((entries, index) => {
        return (
          <div className={classes.tileContainer} key={index}>
            {entries.map(([reportTemplateCode, report]) => {
              const config = chartConfigs[reportTemplateCode];
              return (
                <div className={classes.tile} key={reportTemplateCode}>
                  <SiteSpeedSmallChartPanelTitle
                    {...config}
                    icon={getIconForChart(config.icon)}
                    module={crawlContext.data?.module}
                    crawlSetting={crawlContext.data?.crawlSetting}
                    report={report}
                  />
                  <SiteSpeedSmallColumnChart
                    {...config}
                    module={crawlContext.data?.module}
                    report={report}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

function getChartConfigurations(t: TFunction<"charts">): Record<
  string,
  {
    unitDescription: string;
    icon: ChartIconNames;
    title: string;
    description: string;
    descriptionTitle?: string;
    noDataMessage?: string;
    testAttributePrefix?: string;
  }
> {
  return {
    "site_speed_pages:first_contentful_paint_avg": {
      unitDescription: t("unitDescription.speed"),
      icon: "lighthouse",
      title: t("siteSpeedFCPMetricTrend.tile_title"),
      description: t("siteSpeedFCPMetricTrend.description"),
      testAttributePrefix: "fcp-lighthouse",
    },
    "site_speed_pages:largest_contentful_paint_avg": {
      icon: "lighthouse",
      unitDescription: t("unitDescription.speed"),
      title: t("siteSpeedLCPMetricTrend.tile_title"),
      description: t("siteSpeedLCPMetricTrend.description"),
      testAttributePrefix: "lcp-lighthouse",
    },
    "site_speed_pages:total_blocking_time_avg": {
      icon: "lighthouse",
      unitDescription: t("unitDescription.speed"),
      title: t("siteSpeedTBTMetricTrend.tile_title"),
      description: t("siteSpeedTBTMetricTrend.description"),
      testAttributePrefix: "tbt-lighthouse",
    },
    "site_speed_pages:cumulative_layout_shift_avg": {
      title: t("siteSpeedCLSMetricTrend.tile_title"),
      description: t("siteSpeedCLSMetricTrend.description"),
      unitDescription: t("unitDescription.layout"),
      icon: "lighthouse",
      testAttributePrefix: "cls-lighthouse",
    },
    "crux_domain_summary:crux_fcp_weighted_avg": {
      unitDescription: t("unitDescription.speed"),
      icon: "crux",
      title: t("siteSpeedCruxFCPMetricTrend.tile_title"),
      description: t("siteSpeedCruxFCPMetricTrend.description"),
      descriptionTitle: t("siteSpeedCruxFCPMetricTrend.tile_descriptionTitle"),
      noDataMessage: t("noCruxData"),
    },
    "crux_domain_summary:crux_lcp_weighted_avg": {
      icon: "crux",
      unitDescription: t("unitDescription.speed"),
      title: t("siteSpeedCruxLCPMetricTrend.tile_title"),
      description: t("siteSpeedCruxLCPMetricTrend.description"),
      descriptionTitle: t("siteSpeedCruxLCPMetricTrend.tile_descriptionTitle"),
      noDataMessage: t("noCruxData"),
    },
    "crux_domain_summary:crux_itnp_weighted_avg": {
      icon: "crux",
      unitDescription: t("unitDescription.speed"),
      title: t("siteSpeedCruxITNPMetricTrend.tile_title"),
      description: t("siteSpeedCruxITNPMetricTrend.description"),
      descriptionTitle: t("siteSpeedCruxITNPMetricTrend.tile_descriptionTitle"),
      noDataMessage: t("noCruxData"),
    },
    "crux_domain_summary:crux_cls_weighted_avg": {
      unitDescription: t("unitDescription.layout"),
      icon: "crux",
      title: t("siteSpeedCruxCLSMetricTrend.tile_title"),
      description: t("siteSpeedCruxCLSMetricTrend.description"),
      descriptionTitle: t("siteSpeedCruxCLSMetricTrend.tile_descriptionTitle"),
      noDataMessage: t("noCruxData"),
    },
  };
}
