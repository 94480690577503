import React from "react";
import {
  useTranslation,
  Snackbar,
  useAllHealthScoresExport,
  getRawProjectId,
  getRawCrawlId,
  ChartIconButton,
} from "@lumar/shared";
import { Menu, MenuItem } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSnackbar } from "notistack";
import { useCrawlContext } from "../../../../CrawlContext";
import { useSelectedSegment } from "../../../../../segment-selector/useSelectedSegment";

export function HealthScoreExport(): JSX.Element {
  const { t } = useTranslation("charts");
  const { enqueueSnackbar } = useSnackbar();
  const crawlContext = useCrawlContext();

  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const exportAllCSV = useAllHealthScoresExport();
  const [segment] = useSelectedSegment();

  async function handleExportAllCSV(): Promise<void> {
    if (!crawlContext.data) return;

    setLoading(true);
    try {
      await exportAllCSV({
        projectId: getRawProjectId(crawlContext.data.crawlProject.id),
        crawlId: getRawCrawlId(crawlContext.data.crawl.id),
        segment,
        categories: crawlContext.data.crawlReportCategoriesList,
        title: t("healthScore.exportTitle"),
      });
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("chartDownloadMessage", { exportType: "CSV" })}
        />,
      );
    } catch {
      enqueueSnackbar(
        <Snackbar variant="error" title={t("errors.queryError")} />,
      );
    }
    setLoading(false);
  }

  return (
    <>
      <ChartIconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        data-testid="health-score-export-button"
        onClick={(event) => !loading && setAnchorEl(event.currentTarget)}
        disabled={loading}
        tooltip={"Export options"}
        icon={GetAppIcon}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleExportAllCSV();
          }}
        >
          {t("healthScore.exportAllCsv")}
        </MenuItem>
      </Menu>
    </>
  );
}
