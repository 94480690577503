import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import React from "react";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Typography, useTranslation } from "@lumar/shared";
import { isCrawling, isQeued } from "../../_common/running-crawl-query-utils";
import { makeStyles } from "@material-ui/core";
import { RunningCrawl } from "../../../useProjectPoller";

const useStyles = makeStyles(() => ({
  "@global": {
    "@keyframes dash": {
      to: { strokeDashoffset: -100 },
    },
  },
  container: (props: { isCrawling: boolean }) => ({
    "& path": {
      animation: props.isCrawling ? `dash 5s linear infinite` : "none",
    },
  }),
  fallback: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 172,
  },
}));

export function CrawlFailureRateChart({
  failureRateChartOptions,
  runningCrawl,
}: {
  failureRateChartOptions: Options;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const classes = useStyles({
    isCrawling: isCrawling(runningCrawl) || isQeued(runningCrawl),
  });
  const lookbackWindow = runningCrawl?.failureRateLookbackWindow ?? 300;
  const lookbackWindowInMs = lookbackWindow * 1000;
  const internalCrawlRates = runningCrawl?.internalCrawlRates ?? [];

  const hasElapsedLookbackWindow =
    internalCrawlRates.length > 1
      ? (() => {
          const firstRateTime = new Date(
            internalCrawlRates[0].crawlingAt,
          ).getTime();
          const lastRateTime = new Date(
            internalCrawlRates[internalCrawlRates.length - 1].crawlingAt,
          ).getTime();
          return lastRateTime - firstRateTime > lookbackWindowInMs;
        })()
      : false;

  // Store the result in a variable
  const elapsedLookbackWindow = hasElapsedLookbackWindow;

  return (
    <ProgressDashboardContainer
      headerTitle={t("progressDashboard.crawlFailureRateChartTitle")}
      headerTooltip={t("progressDashboard.crawlFailureRateChartTooltip", {
        lookbackWindow:
          lookbackWindow < 60
            ? t("lookbackWindowSeconds", { value: lookbackWindow })
            : t("lookbackWindowMinutes", { value: lookbackWindow / 60 }),
      })}
    >
      {elapsedLookbackWindow ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={failureRateChartOptions}
          containerProps={{
            className: classes.container,
            "data-testid": "crawl-failure-rate-chart",
          }}
        />
      ) : (
        <div className={classes.fallback}>
          <Typography>{t("waitingForData")}</Typography>
        </div>
      )}
    </ProgressDashboardContainer>
  );
}
