import { TFunction } from "i18next";
import { Theme } from "@material-ui/core";
import { DateFormatter } from "@lumar/shared";

import { SharedCrawlPrefixRouteParams } from "../../routing/routes";
import { ChartConfigItemDynamic } from "../dynamic-trend-chart/ChartConfigItemDynamic";
import { ChartConfigItemMultiDimensionalSeries } from "../multi-dimensional-series-chart/ChartConfigItemMultiDimensionalSeries";
import { ChartConfigItemMultiSeries } from "../multi-series-chart/ChartConfigItemMultiSeries";
import { ChartConfigItemSparkline } from "../sparkline-list/ChartConfigItemSparkline";
import { ChartConfigItemVennDiagram } from "../venn-diagram-chart/ChartConfigItemVennDiagram";

export enum VisualisationTypes {
  SparklineList = "SparklineList",
  Pie = "Pie",
  Bar = "Bar",
  Line = "Line",
  Area = "Area",
  Column = "Column",
  StackedColumn = "Stacked Column",
  StackedArea = "Stacked Area",
  FullStackedArea = "Full Stacked Area",
  FullStackedColumn = "Full Stacked Column",
  Table = "Table",
  Venn = "Venn Diagram",
  DynamicTrend = "Dynamic Trend",
}

export type ChartConfigItem =
  | ChartConfigItemSparkline
  | ChartConfigItemMultiSeries
  | ChartConfigItemMultiDimensionalSeries
  | ChartConfigItemVennDiagram
  | ChartConfigItemDynamic;

export type ChartConfig = ChartConfigItem & {
  hideChartWithoutData?: boolean;
};

export type GenerateChartConfigItemFunction = (
  t: TFunction<"charts">,
  theme: Theme,
  formatter: DateFormatter,
) => ChartConfigItem;

export interface LinkData extends SharedCrawlPrefixRouteParams {
  segmentId?: string;
  category?: string;
}
