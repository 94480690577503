import {
  ConnectionPredicate,
  ConnectionPredicateMetadata,
  Metric,
  MetricType,
} from "../../graphql";

type ReportMetric = Pick<Metric, "type" | "code"> & {
  connectionPredicates: Array<
    Pick<ConnectionPredicateMetadata, "code" | "isArray">
  >;
};

export function getFilterReportMetrics<T extends ReportMetric>(
  metrics: T[],
  customExtractions: string[],
): T[] {
  return metrics
    .map((metric) => ({
      ...metric,
      connectionPredicates: [
        ...metric.connectionPredicates.filter((predicate) => {
          if (predicate.isArray) {
            return false;
          }

          if (
            metric.code !== "customExtractionMatch" &&
            RegExp(/customExtraction/i).test(metric.code) &&
            !RegExp(
              customExtractions.map((str) => `^${str}$`).join("|"),
              "i",
            ).test(metric.code)
          ) {
            return false;
          }

          // Note: We are excluding the equals and not equals predicates for decimal metrics
          // since we represent decimal values with smaller precision than they are stored
          // on data side. As a result, these predicates won't function as intended.
          if (
            metric.type === MetricType.Decimal &&
            (predicate.code === ConnectionPredicate.Eq ||
              predicate.code === ConnectionPredicate.Ne)
          ) {
            return false;
          }

          return true;
        }),
      ],
    }))
    .filter((metric) => metric.connectionPredicates.length);
}
