import { useSession, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";

import { useRollbar } from "@rollbar/react";
import React from "react";
import {
  ModuleCode,
  useGetCrawlSettingsForDomainQuery,
} from "../../../../graphql";
import { getFormValues } from "./getFormValues";
import { ContextValues, FormValues } from "./types";
import { useContextValues } from "./useContextValues";
import { useDomainMutation } from "./useDomainMutation";

export interface FormValuesResult {
  formValues: FormValues;
  contextValues: ContextValues;
  loading: boolean;
  error?: string;
  createOrUpdate: (values: FormValues) => Promise<string | undefined>;
}

export function useFormValues(moduleCode?: ModuleCode): FormValuesResult {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();

  const rollbar = useRollbar();
  const { hasAddon } = useSession();
  const { t } = useTranslation("crawlSettings");

  const isInEditMode = projectId !== undefined;

  const { data, error, loading } = useGetCrawlSettingsForDomainQuery({
    variables: {
      accountId,
      projectId: projectId ?? "0",
      isNew: !isInEditMode,
      industryEnabled: hasAddon("industry-benchmarks"),
    },
  });

  const accessibilityContainer =
    data?.accessibilityModule?.customMetricContainers?.find(
      (x) => x.name === "AccessibilityIssues",
    );

  const moduleCodeResolved = data?.getProject?.moduleCode ?? moduleCode;
  const noModuleFound = !loading && !error && !moduleCodeResolved;

  React.useEffect(() => {
    if (noModuleFound) {
      if (rollbar.options.enabled) {
        rollbar.error("No module found!");
      } else {
        console.error("No module found!");
      }
    }
  }, [noModuleFound, rollbar]);

  const contextValues = useContextValues(data, moduleCodeResolved);

  const formValues = getFormValues(
    data,
    contextValues.module.code,
    accessibilityContainer?.id,
    contextValues.hasJsRendering,
  );

  const createOrUpdate = useDomainMutation({
    moduleCode: contextValues.module.code,
    initialValues: formValues,
    accessibilityContainerId: accessibilityContainer?.id,
  });

  return {
    loading,
    error: noModuleFound ? t("moduleSelector.noModuleError") : error?.message,
    formValues,
    contextValues,
    createOrUpdate,
  };
}
