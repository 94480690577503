import React from "react";
import { Alert, useTranslation } from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";

import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useReportPageData } from "./data/useReportPageData";
import { ReportChartCarousel } from "./report-chart-carousel/ReportChartCarousel";
import { ReportGrid } from "./report-rows/report-grid/ReportGrid";

import { useCallback } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import { HelpAccordion } from "../_common/components/HelpAccordion";
import { getStoredColumnsParam } from "../_common/data-grid/column-persistance/columnsState";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { useCrawlContext } from "../crawl-overview/CrawlContext";
import { mapStringToReportTypeCode } from "../custom-report/_common/CustomReportHelpers";
import { SegmentSelect } from "../segment-selector/SegmentSelect";
import { getInfoEntries } from "./data/getInfoEntries";
import { getReportCodes } from "./helpers/getReportCodes";
import { CrawlSettingsComparison } from "./report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { ReportCrawlSelector } from "./report-title/ReportCrawlSelector";
import { ReportEntity, ReportInput } from "./Report.types";
import { useSearchParam } from "../_common/routing/useSearchParam";
import { useReportChartConfig } from "./report-chart-carousel/config/useReportChartConfig";

export function Report(): JSX.Element {
  const { reportTemplateCodeWithTypeCode } = useParams<{
    reportTemplateCodeWithTypeCode: string;
  }>();
  const { reportTemplateCode, reportTypeCode } = getReportCodes(
    reportTemplateCodeWithTypeCode,
  );

  React.useEffect(() => window.scrollTo(0, 0), [reportTemplateCode]);

  const { t } = useTranslation("common");
  const { t: tReport } = useTranslation("report");
  const resolvedPath = useResolvePath();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const segmentId = useSearchParam("segmentId");

  const {
    loading,
    error,
    title,
    breadcrumbs,
    info,
    tabsMode,
    datasourceCode,
    reportTemplateQueryVersion,
    lastFinishedCrawlId,
    reportAdviceCollapsed,
    crawlSegmentsTotalCount,
    crawlProjectModuleCode,
  } = useReportPageData();

  const {
    data: crawlContextData,
    errors: crawlContextErrors,
    loading: crawlContextLoading,
    crawlNotFound,
  } = useCrawlContext();

  const chartConfigs = useReportChartConfig(
    reportTemplateCode,
    crawlSegmentsTotalCount,
    crawlProjectModuleCode,
    datasourceCode,
  );

  const onTabChange = useCallback(
    (value: string) => {
      const reportName = `${reportTemplateCode}_${value}`;
      const currentReport = pathname.substring(
        pathname.indexOf("/reports/") + 9,
      );
      const newPath = pathname.replace(currentReport, reportName);
      history.push({ pathname: newPath, search });
    },
    [history, pathname, reportTemplateCode, search],
  );

  if (resolvedPath) {
    return <Redirect to={resolvedPath} />;
  }

  if (loading || crawlContextLoading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  if (crawlContextErrors) {
    return (
      <>
        <TopNavigation title={title} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("genericError")}</Alert>
        </div>
      </>
    );
  }

  if (crawlNotFound) {
    return (
      <>
        <TopNavigation title={title} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("crawlNotFound")}</Alert>
        </div>
      </>
    );
  }

  const reportInput: ReportInput = {
    reportEntity: ReportEntity.ReportStat,
    crawlId: crawlContextData?.crawl.id,
    reportTemplateCode,
    segmentId,
    reportTypeCode: mapStringToReportTypeCode(reportTypeCode),
  };

  return (
    <>
      <TopNavigation
        title={title}
        actions={[
          <SegmentSelect key="segment-selector" />,
          <ReportCrawlSelector
            key="crawl-selector"
            reportInput={reportInput}
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        breadcrumbs={breadcrumbs}
        loading={loading}
        availableForShare
        getShareLinkAdditionalParams={() => {
          const isGridView = getIsGridView(reportTemplateCode);
          const columns =
            reportTemplateCode &&
            getStoredColumnsParam(reportTemplateCode, isGridView);
          return columns
            ? [
                `columns=${columns}`,
                `viewType=${isGridView ? "grid" : "table"}`,
              ]
            : [];
        }}
      />
      {info && (
        <HelpAccordion
          defaultCollapsed={reportAdviceCollapsed}
          priority={info.priority}
          impact={info.impact}
          entries={getInfoEntries(info, tReport)}
        />
      )}
      <div style={{ marginTop: 24 }}>
        {error ? (
          <Alert severity="warning">{error.message}</Alert>
        ) : (
          <>
            <ReportChartCarousel
              chartConfigs={chartConfigs}
              loading={loading}
            />
            <ReportGrid
              tabsMode={tabsMode}
              reportInput={reportInput}
              onTabChange={onTabChange}
              reportTemplateQueryVersion={reportTemplateQueryVersion}
              lastFinishedCrawlId={lastFinishedCrawlId}
            />
          </>
        )}
      </div>
    </>
  );
}
function getIsGridView(reportTemplateCode?: string): boolean {
  const value = localStorage.getItem(`${reportTemplateCode}_view_type`);
  if (!value) return false;

  return value === JSON.stringify("grid");
}

function useResolvePath(): string | undefined {
  const params = useURLSearchParams();
  const { data } = useCrawlContext();
  const { accountId, projectId, crawlId, reportTemplateCodeWithTypeCode } =
    useParams<{
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTemplateCodeWithTypeCode: string;
    }>();

  if (!data || !params.get("resolvePath")) return;

  const { reportTypeCode, reportTemplateCode } = getReportCodes(
    reportTemplateCodeWithTypeCode,
  );
  const report = data.crawlReports.find(
    (x) => x.reportTemplate.code === reportTemplateCode,
  );

  if (!report) {
    return Routes.CrawlOverview.getUrl({
      accountId,
      projectId,
      crawlId,
    });
  }

  const reportTypeMissing =
    reportTypeCode !== "basic" &&
    report[reportTypeCode as "added" | "removed" | "missing"] === null;

  if (reportTypeMissing) {
    return Routes.Report.getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCode,
      reportTypeCode: "basic",
    });
  }

  params.delete("resolvePath");
  params.apply();
}
