import { DatasourceCode } from "../graphql";

export const REPORT_STAT_TREND_ITEM_LIMIT = 30;

export const SUPPORTED_SEGMENT_CHART_CAROUSEL_DATASOURCES = [
  DatasourceCode.CrawlUrls,
  DatasourceCode.CrawlAccessibilityIssues,
  DatasourceCode.CrawlAccessibilityIssueInstances,
  DatasourceCode.CrawlSiteSpeedAuditItems,
  DatasourceCode.CrawlSiteSpeedAuditOpportunities,
  DatasourceCode.CrawlSiteSpeedAudits,
];
