import {
  TooltipInfoIcon,
  Typography,
  URLFunnelChart,
  getRawCrawlId,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles, Paper, Switch, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { RoleCode } from "../../../../../graphql";
import { Routes } from "../../../../../_common/routing/routes";
import { urlFunnelDataMapper } from "./helpers";
import { useCrawlOverviewContext } from "../../../../CrawlOverviewContext";
import { useSearchParam } from "../../../../../_common/routing/useSearchParam";
import { useSelectedSegment } from "../../../../../segment-selector/useSelectedSegment";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    borderRadius: theme.spacing(1),
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(19),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.786em",
    textTransform: "uppercase",
  },
  pendoPlaceholder: {
    marginLeft: theme.spacing(1.5),
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
    width: "22px",
    height: "22px",
  },
  tooltipContent: {
    maxWidth: 500,
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}));

function hasCrawlRequiredCrawlTypes(crawlTypes: string[]): boolean {
  const requiredTypes = ["Web", "GoogleSearchConsole"];
  return requiredTypes.every((type) => crawlTypes.includes(type));
}

export const URLFunnel = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("charts");
  const [showProportionalBars, setShowProportionalBars] = useState(true);
  const { hasSufficientRole } = useSession();
  const [segment] = useSelectedSegment();

  const { crawlContext } = useCrawlOverviewContext();
  const segmentId = useSearchParam("segmentId");

  const hasEditorPermission = hasSufficientRole(RoleCode.Editor);
  const session = useSession();

  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const isJsRenderingEnabled =
    crawlContext.data?.crawlSetting?.useRenderer ?? false;
  const hasRequiredCrawlTypes = hasCrawlRequiredCrawlTypes(
    crawlContext.data?.crawl.crawlTypes ?? [],
  );

  const dynamicDataset = urlFunnelDataMapper(t, crawlContext);

  if (!session.hasAddon("traffic-funnel")) {
    return <></>;
  }

  return (
    <Paper
      elevation={0}
      className={classes.paper}
      data-pendo="traffic-funnel-container"
    >
      <Box className={classes.titleContainer}>
        <Box display="flex" alignItems="center">
          <Typography
            className={classes.title}
            data-pendo="an-url-funnel-heading"
          >
            {t("urlFunnel.title")}
            {segmentId ? (
              <Typography className={classes.subtitle}>
                {" "}
                ({segment?.name})
              </Typography>
            ) : null}
          </Typography>
          <Tooltip
            title={
              <>
                <Typography
                  component="h6"
                  variant="subtitle3Medium"
                  className={classes.tooltipTitle}
                >
                  {t("urlFunnel.title")}
                </Typography>
                <Typography
                  variant="caption"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {t("urlFunnel.tooltip")}
                </Typography>
              </>
            }
            placement="right"
            classes={{ tooltip: classes.tooltipContent }}
            className={classes.tooltip}
          >
            <TooltipInfoIcon fontSize="small" />
          </Tooltip>
        </Box>

        <Box display="flex" alignItems="center" marginLeft={2}>
          <Box marginX={1}>
            <Switch
              checked={showProportionalBars}
              onChange={() => setShowProportionalBars(!showProportionalBars)}
            />
          </Box>
          <Box marginRight={1}>
            <Typography variant="subtitle3Medium">
              {t("urlFunnel.proportional")}
            </Typography>
          </Box>
          <Tooltip
            title={
              <>
                <Typography
                  component="h6"
                  variant="subtitle3Medium"
                  className={classes.tooltipTitle}
                >
                  {t("urlFunnel.proportional")}
                </Typography>
                <Typography variant="caption">
                  {t("urlFunnel.proportionalTooltip")}
                </Typography>
              </>
            }
            placement="right"
          >
            <TooltipInfoIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>

      <URLFunnelChart
        showProportionalBars={showProportionalBars}
        data={dynamicDataset}
        loading={false}
        text={{
          missingSources: {
            description: t("urlFunnel.missingSources.description"),
            action: t("urlFunnel.missingSources.action"),
          },
          emptyState: {
            title: t("urlFunnel.error.title"),
            description: t("urlFunnel.error.description"),
          },
        }}
        getReportUrl={(reportTemplateCode) =>
          Routes.Report.getUrl({
            accountId,
            projectId,
            reportTemplateCode,
            crawlId: getRawCrawlId(crawlId),
            reportTypeCode: "basic",
          })
        }
        editCrawlUrl={Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "edit",
        })}
        isMissingSources={!isJsRenderingEnabled || !hasRequiredCrawlTypes}
        hasEditorPermission={hasEditorPermission}
      />
    </Paper>
  );
};
