import { Chip } from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { useUnresolvedProjectTasksCountForChipQuery } from "../../graphql";
import { useParams } from "react-router";

export function ProjectTasksCountChip(): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  const { data, loading, error } = useUnresolvedProjectTasksCountForChipQuery({
    variables: { projectId },
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return <CircularProgress size="1.3rem" style={{ display: "block" }} />;
  }

  if (error || !data || !data.getProject) {
    return <></>;
  }

  return (
    <Chip label={data.getProject.unresolvedTasks.totalCount} color="primary" />
  );
}
