import { useTranslation } from "@lumar/shared";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import { RunningCrawl } from "../../../useProjectPoller";

export function CrawlReponseCodesChart({
  responseCodesChartOptions,
  runningCrawl,
}: {
  responseCodesChartOptions: Options;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const lookbackWindow = runningCrawl?.failureRateLookbackWindow ?? 300;

  return (
    <ProgressDashboardContainer
      headerTitle={t("progressDashboard.crawlResponseCodesChartTitle", {
        lookbackWindow:
          lookbackWindow < 60
            ? t("lookbackWindowSeconds", { value: lookbackWindow })
            : t("lookbackWindowMinutes", { value: lookbackWindow / 60 }),
      })}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={responseCodesChartOptions}
        containerProps={{
          "data-testid": "crawl-response-codes-chart",
        }}
      />
    </ProgressDashboardContainer>
  );
}
