import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ChartConfig } from "../../../_common/charts/types/ChartConfig";
import { generateSingleReportTrendChart } from "../../../_common/charts/utils/generateSingleReportTrendChartConfig";
import { generateTopSegmentsChart } from "../../../_common/charts/config/getTopSegmentsChart";
import { generateSegmentComparisonChart } from "../../../_common/charts/config/getSegmentComparisonChart";
import { reportsChartsMap } from "./reportsChartsMap";
import { useDateFormatter } from "@lumar/shared";
import { DatasourceCode, ModuleCode } from "../../../graphql";
import { SUPPORTED_SEGMENT_CHART_CAROUSEL_DATASOURCES } from "../../../_common/constants";
import { useSearchParam } from "../../../_common/routing/useSearchParam";

export function useReportChartConfig(
  reportTemplateCode: string,
  crawlSegmentsTotalCount: number,
  crawlProjectModuleCode: ModuleCode = ModuleCode.Seo,
  datasourceCodeEnum?: DatasourceCode,
): ChartConfig[] | null {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();
  const segmentId = useSearchParam("segmentId");

  const trendChartConfig = generateSingleReportTrendChart(reportTemplateCode)(
    t,
    theme,
    dateFormatter,
  );

  const reportChartConfigs = (reportsChartsMap.get(reportTemplateCode) ?? [])
    .map((configItem) => configItem(t, theme, dateFormatter))
    .filter((chart) =>
      (chart.supportedModules || [ModuleCode.Seo]).includes(
        crawlProjectModuleCode,
      ),
    );

  // datasourceCode might not exist because the query might be in loading state
  const isSegmentationSupported =
    datasourceCodeEnum &&
    SUPPORTED_SEGMENT_CHART_CAROUSEL_DATASOURCES.includes(datasourceCodeEnum);
  const hasSegments = crawlSegmentsTotalCount > 0;
  const isSegmentSelected = !!segmentId;

  if (isSegmentationSupported && hasSegments) {
    const segmentChartConfig = isSegmentSelected
      ? generateSegmentComparisonChart(reportTemplateCode)(t)
      : generateTopSegmentsChart(reportTemplateCode)(t);

    return [segmentChartConfig, trendChartConfig, ...reportChartConfigs];
  }

  return [trendChartConfig, ...reportChartConfigs];
}
