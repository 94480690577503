import { GenerateChartConfigItemFunction } from "../../../_common/charts/types/ChartConfig";
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";
import { ReportsChartColumnCustomDefinition } from "../data-visualization/charts/useTopErrorsSparklineTableChartData";
import { ReportsDefaultColumnTypes } from "../../reports-table/reportsTableDefaultColumns";
import { ReportsTableColumnCustomDefinition } from "../../reports-table/useReportsTableColumns";

export type ReportCategoryChartConfigItem = GenerateChartConfigItemFunction;

export type ReportCategoryChartConfig = {
  /*
   * @defaultValue false
   */
  includeTopChanges?: boolean;
  errorsChart?: GenerateErrorsChartConfigFunction;
  allReportsTable?: GenerateReportsTableConfigFunction;
  errorsTable?: GenerateReportsTableConfigFunction;
  mainCharts: ReportCategoryChartConfigItem[];
};

export type ErrorsChartConfigItem = {
  columns: (
    | "name"
    | "trend"
    | "total"
    | "change"
    | ReportsChartColumnCustomDefinition
  )[];
  orderBy?: {
    field: string;
    direction: "asc" | "desc";
  };
};

export type GenerateErrorsChartConfigProps = {
  theme: Theme;
  t: TFunction<"charts">;
};

export type GenerateErrorsChartConfigFunction = (
  props: GenerateErrorsChartConfigProps,
) => ErrorsChartConfigItem;

export type ReportsTablePredefinedColumnDefinition = {
  predefinedColumn: ReportsDefaultColumnTypes;
  hide?: boolean;
};

export type ReportsTableColumnDefinition =
  | ReportsTablePredefinedColumnDefinition
  | ReportsTableColumnCustomDefinition;

export type ReportsTableConfigItem = {
  columns: ReportsTableColumnDefinition[];
  orderBy?: {
    field: string;
    direction: "asc" | "desc";
  };
};

export type GenerateReportsTableConfigProps = {
  theme: Theme;
  t: TFunction<"charts">;
};

export type GenerateReportsTableConfigFunction = (
  props: GenerateReportsTableConfigProps,
) => ReportsTableConfigItem;

export function isReportsTableColumnCustomDefinition(
  value: ReportsTableColumnDefinition,
): value is ReportsTableColumnCustomDefinition {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (value as any).field === "string";
}
