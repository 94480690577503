import { camelCase } from "lodash";
import { ReportTemplateUnit } from "../../../../graphql";
import { ChartDataMetric } from "./ChartDataContext";
import {
  CrawlContextCrawlReportStat,
  CrawlContextProjectModule,
} from "../../../../crawl-overview/CrawlContext";
import { ChartConfigReportStat } from "../../types/ChartConfigItemBase";

export function getReportUnit(
  report: CrawlContextCrawlReportStat | undefined,
  module: CrawlContextProjectModule | undefined,
): ReportTemplateUnit | string | undefined {
  const isAggregateReport = report?.reportTemplateCode?.includes(":");
  return isAggregateReport
    ? (getMetric(report, module)?.metadata?.unit ?? undefined)
    : (report?.reportTemplate?.metadata?.unit ?? undefined);
}

export function getAggregatedMetric(
  report: ChartConfigReportStat | undefined,
  module: CrawlContextProjectModule | undefined,
): ChartDataMetric | undefined {
  const isAggregateReport = Boolean(report?.reportTemplateCode?.includes(":"));
  if (!isAggregateReport) return;

  return getMetric(report, module);
}

function getMetric(
  report: ChartConfigReportStat | undefined,
  module: CrawlContextProjectModule | undefined,
): ChartDataMetric | undefined {
  const aggregateName = report?.reportTemplateCode?.slice(
    report.reportTemplateCode.indexOf(":") + 1,
  );
  const aggregate =
    aggregateName &&
    report?.reportTemplate?.aggregatesByCode?.[aggregateName]?.aggregate;

  const metricCode = aggregate && Object.values(aggregate)[0];
  const formattedMetricCode =
    typeof metricCode === "string" ? camelCase(metricCode) : undefined;

  const datasource = module?.datasources?.find(
    (x) => x.datasourceCode === report?.reportTemplate?.datasourceCodeEnum,
  );

  const metric = formattedMetricCode
    ? datasource?.metrics?.find((x) => x.code === formattedMetricCode)
    : undefined;

  return metric
    ? { ...metric, aggregate: Object.keys(aggregate)[0] }
    : undefined;
}
