/* eslint-disable react/display-name */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  GetAggregateChartDataDocument,
  ModuleCode,
  ReportStatTrendItem,
} from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter } from "@lumar/shared";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getSiteSpeedCruxFCPTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.FullStackedArea,
    supportedModules: [ModuleCode.SiteSpeed],
    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.Line,
    ],
    icon: "crux",
    title: () => t("siteSpeedCruxFCPTrend.title"),
    description: () => t("siteSpeedCruxFCPTrend.description"),
    descriptionTitle: () => t("siteSpeedCruxFCPTrend.descriptionTitle"),
    inputSource: "GqlQuery",
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          "crux_domain_summary:crux_fcp_poor_weighted_avg",
          "crux_domain_summary:crux_fcp_moderate_weighted_avg",
          "crux_domain_summary:crux_fcp_good_weighted_avg",
        ].map((reportTemplateCode) => {
          return {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode,
          };
        }),
      };
    },
    reportTemplateCodesOrder: [
      "crux_domain_summary:crux_fcp_poor_weighted_avg",
      "crux_domain_summary:crux_fcp_moderate_weighted_avg",
      "crux_domain_summary:crux_fcp_good_weighted_avg",
    ],
    series: (reports, { accountId, projectId, segmentId }: LinkData) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: (report.trend ?? [])
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .map((trend) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: createdAt,
                y: (trend.basic ?? 0) * 100,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode:
                    report.reportTemplate?.code ?? report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
              };
            }),
        };
      }),
    yAxisMax: 100,
    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
  };
}
