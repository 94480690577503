/* eslint-disable fp/no-this */
import Highcharts, {
  ColorString,
  Options,
  SeriesAreaOptions,
} from "highcharts";
import { TFunction } from "i18next";
import { CrawlProgressFragment } from "../../../../graphql";
import { baseChartOptions } from "../_common/charts/base-chart-options";
import { baseTooltipOptions } from "../_common/charts/tooltip-config";

const gradientStart = Highcharts.color("#147DF8" as ColorString)
  .setOpacity(0)
  .get("rgba");

function speedChartOptions(t: TFunction<"crawlProgress">): Options {
  return {
    ...baseChartOptions,
    accessibility: {
      series: {
        pointDescriptionEnabledThreshold: false,
      },
    },
    chart: {
      height: 172,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: { second: "%H:%M:%S", millisecond: "%H:%M:%S" },
    },
    yAxis: { type: "linear", title: { style: { display: "none" } } },
    legend: { enabled: false },
    tooltip: {
      ...baseTooltipOptions,
      formatter: function format() {
        return t("charts.requestRateTooltip", { count: this.y || 0 });
      },
    },
    series: [
      {
        marker: { enabled: false },
        lineWidth: 1,
        color: "#3B82F6",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "#fff"],
            [1, gradientStart.toString()],
          ],
        },
        type: "area",
        name: t("charts.requestRate"),
        data: [],
      },
    ],
  };
}

export function getRequestRateChartOptions(
  t: TFunction<"crawlProgress">,
  crawl: Pick<CrawlProgressFragment, "internalCrawlRates"> | undefined,
  offset: number,
): Options {
  const options = speedChartOptions(t);
  const currentSeries = options.series?.[0] as SeriesAreaOptions;

  return {
    ...options,
    series: [
      {
        ...currentSeries,
        data: (crawl?.internalCrawlRates || []).map((x) => [
          new Date(x.crawlingAt).getTime() + offset,
          x.rate,
        ]),
      },
    ],
  };
}
