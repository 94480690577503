import {
  Button,
  Chip,
  EmptyState,
  MagnifyingGlass,
  RefreshIconOutlined,
  SwitchWithIcon,
  useTranslation,
} from "@lumar/shared";
import {
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCompareSettingsResults } from "../../../../../_common/crawl-settings-comparison/compareSettingsResults";
import {
  CSVDownloadButton,
  CSVGenerationInput,
} from "../../../../../_common/csv-download-button/CSVDownloadButton";
import { CustomSkeleton } from "../../../../../_common/CustomSkeleton";
import { useSearchParam } from "../../../../../_common/routing/useSearchParam";
import { useURLSearchParams } from "../../../../../_common/routing/useURLSearchParams";
import { useCrawlSettingsComparisonQuery } from "../../../../../graphql";
import { SearchBar } from "../../../../../sidebar/search-bar/SearchBar";
import { IndexPresenter } from "./presenters/IndexPresenter";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "white",
    color: theme.palette.grey[700],
    height: 36,
    boxShadow: "0px 1px 2px rgb(0 0 0 / 5%) !important",
    border: "solid 1px #D1D5DB !important",
    borderRadius: 7,
  },
  listHeader: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grey[700],
    paddingLeft: 10,
    lineHeight: "30px",
  },
  menu: {
    border: 0,
    width: 224,
    padding: 0,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
    minWidth: 650,
    maxHeight: 350,
    overflowX: "hidden",
    overflowY: "auto",
    marginTop: theme.spacing(1),
  },
  alert: {
    color: theme.palette.yellow[900],
    backgroundColor: theme.palette.yellow[100],
    alignItems: "center",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  alertMessage: { width: "100%" },
  alertIcon: { color: theme.palette.yellow[500] },
  alertLink: { color: theme.palette.blue[600] },
  tableHeader: { padding: "10px 15px", top: 55 },
  tableCell: { padding: "10px 15px", fontSize: 13 },
  tableRow: { height: "auto" },
  // Some crawl settings render a lot of chips.
  // We're using single generated class to prevent the browser from freezing.
  chip: {
    display: "inline-block",
    height: "auto",
    background: "#D1D5DB",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: 8,
    padding: 4,
    fontWeight: 500,
    marginBottom: 3,
    fontSize: theme.typography.pxToRem(12),
    width: "auto",
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    marginRight: 3,
  },
  changesChip: {
    marginLeft: 5,
    height: 18,
    cursor: "pointer",
    "& > span": {
      padding: theme.spacing(0, 1.25),
    },
  },
  toolBar: {
    display: "flex",
    position: "sticky",
    top: 0,
    padding: theme.spacing(1.125, 1, 1.125, 1),
    alignItems: "center",
    gap: theme.spacing(1),
    backgroundColor: "#FAFAFA",
    borderBottom: "1px solid #E5E7EB",
  },
  switch: {
    marginRight: theme.spacing(0.5),
  },
}));

export function CrawlSettingsComparison(): JSX.Element | null {
  const { crawlId, projectId } = useParams<{
    crawlId: string;
    projectId: string;
  }>();
  const { data, loading } = useCrawlSettingsComparisonQuery({
    variables: { crawlId, projectId },
    fetchPolicy: "cache-first",
  });

  const accessibilityContainerId =
    data?.accessibilityModule?.customMetricContainers?.find(
      (x) => x.name === "AccessibilityIssues",
    )?.id;

  const compareSettingsResults = useCompareSettingsResults();

  const {
    diffs: baseDiffs,
    noDiffs: baseNoDiffs,
    isCompared,
  } = compareSettingsResults(
    data?.getCrawl,
    accessibilityContainerId,
    data?.getProject?.moduleCode,
  );

  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const diffs = baseDiffs.filter((e) =>
    e.name.toUpperCase().includes(searchTerm.toUpperCase()),
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showChanges, setShowChanges] = useState(baseDiffs.length > 0);
  const { t } = useTranslation("crawlSettingsComparison");
  const shouldOpen = Boolean(useSearchParam("changes"));
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const searchParams = useURLSearchParams();
  const history = useHistory();
  const theme = useTheme();

  const noDiffs = baseNoDiffs.filter(
    (e) =>
      e[0]?.toUpperCase().includes(searchTerm.toUpperCase()) && !showChanges,
  );

  const csvData: CSVGenerationInput = {
    header: [
      t("setting"),
      isCompared ? t("now") : t("value"),
      ...(isCompared ? [t("previously")] : []),
    ],
    rows: [
      ...diffs.map((e) => [
        e.name,
        JSON.stringify(e.currentValue),
        ...(isCompared ? [JSON.stringify(e.comparedToValue)] : []),
      ]),
      ...noDiffs.map(([name, value]) => [
        name,
        JSON.stringify(value),
        ...(isCompared ? [JSON.stringify(value)] : []),
      ]),
    ],
  };
  const csvFileName = `${projectId}_${crawlId}_project_settings.csv`;

  useEffect(() => {
    setShowChanges(baseDiffs.length > 0);
  }, [baseDiffs.length]);

  const button = btnRef.current;
  useEffect(() => {
    if (button && shouldOpen) {
      searchParams.delete("changes");
      history.replace({ search: searchParams.toString() });

      setAnchorEl(button);
    }
  }, [shouldOpen, button, searchParams, history]);

  const empty = !(Boolean(noDiffs.length) || Boolean(diffs.length));

  if (loading) {
    return (
      <CustomSkeleton
        width="91px"
        height="36px"
        variant="rect"
        animation="wave"
      />
    );
  }

  return (
    <>
      <Tooltip
        title={isCompared ? t("crawlSettingsComparison") : t("crawlSettings")}
        arrow
        placement="top"
      >
        <div>
          <Button
            loading={false}
            onClick={async (event) => setAnchorEl(event.currentTarget)}
            size="medium"
            variant="outlined"
            className={classes.button}
            data-testid="crawl-settings-comparison"
            ref={btnRef}
            aria-label={
              isCompared ? t("crawlSettingsComparison") : t("crawlSettings")
            }
          >
            <RefreshIconOutlined style={{ height: 22 }} />
            <Chip
              label={baseDiffs.length}
              color={isCompared ? "yellow" : "primary"}
              rounded
              className={classes.changesChip}
            />
          </Button>
        </div>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted={false}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        classes={{ paper: classes.menu }}
        data-testid="crawl-settings-comparison-dialog"
      >
        <div>
          <div className={classes.toolBar}>
            <div style={{ maxWidth: 290, width: 290 }}>
              <SearchBar
                placeholder={t("filterPlaceholder")}
                searchTerm={searchTerm}
                onSearch={setSearchTerm}
                maxLenth={100}
              />
            </div>
            {baseDiffs.length ? (
              <div>
                <SwitchWithIcon
                  checked={showChanges}
                  onChange={() => setShowChanges(!showChanges)}
                  className={classes.switch}
                  aria-label={t("showChanges")}
                />
                <Typography variant="button">{t("showChanges")}</Typography>
              </div>
            ) : undefined}
            <div style={{ flex: 1 }}></div>
            <CSVDownloadButton
              data={csvData}
              fileName={csvFileName}
              dataReady
              tooltip={t("exportTooltip")}
              disabled={empty}
            />
          </div>
          {empty ? (
            <EmptyState
              title={t("emptyStateMessage", { searchText: searchTerm })}
              icon={
                <MagnifyingGlass
                  fontSize="large"
                  htmlColor={theme.palette.yellow[400]}
                />
              }
              actions={[
                {
                  type: "button",
                  title: t("clearFilters"),
                  onClick: () => {
                    setShowChanges(false);
                    setSearchTerm("");
                  },
                },
              ]}
            />
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: "20%" }}
                  >
                    {t("setting")}
                  </TableCell>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: "40%" }}
                  >
                    {isCompared ? t("now") : t("value")}
                  </TableCell>
                  {isCompared && (
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: "40%" }}
                    >
                      {t("previously")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {diffs.map((diff, index) => (
                  <TableRow
                    key={`setting-${index}`}
                    className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell}>
                      {diff.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IndexPresenter
                        chipClassName={classes.chip}
                        value={diff.currentValue}
                      ></IndexPresenter>
                    </TableCell>
                    {isCompared && (
                      <TableCell className={classes.tableCell}>
                        <IndexPresenter
                          chipClassName={classes.chip}
                          value={diff.comparedToValue}
                        ></IndexPresenter>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {!showChanges ? (
                <TableBody>
                  {noDiffs.map(([name, value], index) => (
                    <TableRow
                      key={`setting-${index}`}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.tableCell}>
                        {name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <IndexPresenter
                          chipClassName={classes.chip}
                          value={value}
                        ></IndexPresenter>
                      </TableCell>
                      {isCompared && (
                        <TableCell className={classes.tableCell}>
                          <IndexPresenter
                            chipClassName={classes.chip}
                            value={value}
                          ></IndexPresenter>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          )}
        </div>
      </Popover>
    </>
  );
}
