import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCLSTrend";
import { getSiteSpeedFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedFCPTrend";
import { getSiteSpeedLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedLCPTrend";
import { getSiteSpeedSITrend } from "../../../../../_common/charts/config/getSiteSpeedSITrend";
import { getSiteSpeedTBTTrend } from "../../../../../_common/charts/config/getSiteSpeedTBTTrend";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedCruxFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxFCPTrend";
import { getSiteSpeedCruxLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxLCPTrend";
import { getSiteSpeedCruxITNPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxITNPTrend";
import { getSiteSpeedCruxCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxCLSTrend";

export const generateSiteSpeedOverviewCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    mainCharts: [
      getSiteSpeedFCPTrend,
      getSiteSpeedCruxFCPTrend,
      getSiteSpeedLCPTrend,
      getSiteSpeedCruxLCPTrend,
      getSiteSpeedTBTTrend,
      getSiteSpeedCruxITNPTrend,
      getSiteSpeedCLSTrend,
      getSiteSpeedCruxCLSTrend,
      getSiteSpeedSITrend,
    ],
  };
