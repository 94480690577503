import { useParams } from "react-router-dom";
import { desanitizeDigestFromPageId } from "../../../../_common/routing/desanitizeDigestFromPageId";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ReportEntity } from "../../../../report/Report.types";

export function UrlsInSitemap(): JSX.Element {
  const { resourceId, crawlId } = useParams<{
    crawlId: string;
    resourceId: string;
  }>();

  return (
    <ReportGrid
      reportInput={{
        crawlId,
        reportEntity: ReportEntity.ReportStat,
        reportTemplateCode: "all_sitemaps_links",
        reportTypeCode: ReportTypeCode.Basic,
      }}
      baseFilter={{
        urlFromDigest: {
          eq: desanitizeDigestFromPageId(resourceId),
        },
      }}
      tabsMode="onlyTotalRowsVisible"
      hideTaskMenu
      hideCreateCustomReport
    />
  );
}
