import {
  AppStylesProvider,
  AppThemeProvider,
  ErrorDisplay,
  HelpScoutStyleOverwrite,
  PendoStyleOverwrite,
  BrandProvider,
  I18nProvider,
  GTMSetup,
} from "@lumar/shared";
import { CssBaseline } from "@material-ui/core";
import {
  ErrorBoundary as RollbarErrorBoundary,
  LEVEL_ERROR,
  Provider as RollbarProvider,
} from "@rollbar/react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { rollbarConfig } from "./rollbarConfig";
import { resources } from "./_common/locale/resources";

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
      <BrandProvider>
        <GTMSetup />
        <I18nProvider resources={resources}>
          <AppStylesProvider>
            <AppThemeProvider>
              <CssBaseline />
              <HelpScoutStyleOverwrite />
              <PendoStyleOverwrite />
              <App />
            </AppThemeProvider>
          </AppStylesProvider>
        </I18nProvider>
      </BrandProvider>
    </RollbarErrorBoundary>
  </RollbarProvider>,
  document.querySelector("#root"),
);
