import { useParams } from "react-router";
import { useSearchParam } from "../_common/routing/useSearchParam";
import {
  CrawlSegmentDataFragment,
  CrawlSegmentDataFragmentDoc,
} from "../graphql";
import { getRawSegmentId, useFragment, UseFragmentResult } from "@lumar/shared";

type ReturnedSegment =
  UseFragmentResult<CrawlSegmentDataFragment>["data"]["segment"];

/**
 * Helper hook allowing to quickly fetch selected segment with an intention to hit cache.
 * If you want segment id, then use `useSearchParam("segmentId")` instead.
 */
export function useSelectedSegment(): [
  ReturnedSegment,
  UseFragmentResult<CrawlSegmentDataFragment>,
] {
  const { crawlId } = useParams<{ crawlId: string }>();
  const segmentId = useSearchParam("segmentId");
  const fragment = useFragment<CrawlSegmentDataFragment>({
    fragment: CrawlSegmentDataFragmentDoc,
    from: {
      __typename: "CrawlSegment",
      crawlId: parseInt(crawlId),
      segmentId: parseInt(getRawSegmentId(segmentId ?? "")),
    },
  });
  return [fragment.data?.segment, fragment];
}
