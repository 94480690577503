import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ChartConfig } from "../../../_common/charts/types/ChartConfig";
import { useDateFormatter } from "@lumar/shared";
import { newCategoriesChartsMap } from "./new/newCategoriesChartsMap";
import { useCrawlOverviewContextData } from "../../CrawlOverviewContext";
import { ModuleCode } from "../../../graphql";

export function useReportCategoryChartConfig(): {
  includeTopChanges?: boolean;
  mainCharts: ChartConfig[];
} {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();

  const { selectedCategory, moduleCode } = useCrawlOverviewContextData();

  const predefinedCharts = newCategoriesChartsMap.get(selectedCategory.code);

  if (!predefinedCharts) return { mainCharts: [] };

  function moduleFilter(chart: { supportedModules?: ModuleCode[] }): boolean {
    return (chart.supportedModules || [ModuleCode.Seo]).includes(moduleCode);
  }

  return {
    includeTopChanges: predefinedCharts.includeTopChanges,
    mainCharts: predefinedCharts.mainCharts
      .map((configItem) => configItem(t, theme, dateFormatter))
      .filter(moduleFilter),
  };
}
