import { GoogleAnalyticsSolid, TabSpinner } from "@lumar/shared";
import { makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AALogo } from "../../../../_common/assets/tab-logos/adobe-analytics-blue.svg";
import { insertIf } from "../../../../_common/insertIf";
import { CrawlType } from "../../../../graphql";
import { AddonAccess } from "../../components/AddonAccess";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import { FormValues } from "../data/types";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";
import { ManualUploadsContext } from "../manual-uploads/ManualUploadsWrapper";
import { AdobeAnalytics } from "./AdobeAnalytics";
import { AnalyticsManualUploads } from "./AnalyticsManualUploads";
import { GoogleAnalytics4 } from "./GoogleAnalytics4";
import { GoogleAnalyticsLogo } from "./GoogleAnalyticsLogo";
import { useAnalyticsValues } from "./useAnalyticsValues";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  iconLabelWrapper: {
    padding: theme.spacing(1),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: "20px",
    width: "29px",
  },
  labelIcon: {
    minHeight: "unset",
  },
}));

export const Analytics = React.memo(function Analytics({
  initialState,
  dirty,
}: {
  initialState: boolean;
  dirty: boolean;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(0);

  const { adobeAnalyticsReportSuites } = useAnalyticsValues();

  const getBadges = (
    values: FormValues,
    hasFile: boolean,
  ): CustomAccordionBadge[] => {
    const shouldShowWarnings =
      values.crawlType.analytics &&
      !hasFile &&
      !values.analytics.googleAnalytics4.propertyId;
    const isDataOnly = values.dataOnlyCrawlType.analytics;
    return [
      ...insertIf(isDataOnly, {
        label: t("sources.dataOnly"),
        info: "",
        color: "primary" as const,
      }),
      ...insertIf(shouldShowWarnings, {
        label: t("sources.analytics.warningLabel"),
        info: t("sources.analytics.warningInfo"),
        color: "yellow" as const,
      }),
      ...insertIf(shouldShowWarnings, {
        label: t("sources.analytics.warningLabelNoView"),
        info: "",
        color: "yellow" as const,
      }),
    ];
  };

  const tabs: {
    label: string | React.ReactElement;
    icon?: React.ReactElement;
    content: React.ReactElement;
  }[] = [
    {
      label: t("sources.analytics.googleAnalytics4.title"),
      icon: <GoogleAnalyticsLogo className={classes.icon} />,
      content: <GoogleAnalytics4 dirty={dirty} />,
    },
    {
      label: t("sources.analytics.adobeAnalytics.title"),
      icon: <AALogo className={classes.icon} />,
      content: (
        <AddonAccess
          addonCode="adobe-analytics"
          addonName={t("addonAccess.adobeName")}
          message={t("addonAccess.adobeDescription")}
        >
          <AdobeAnalytics reportSuites={adobeAnalyticsReportSuites} />
        </AddonAccess>
      ),
    },
    {
      label: (
        <ManualUploadsContext.Consumer>
          {({ getFileCount, loading }) => (
            <>
              {t("sources.analytics.manualUploads.title")}&nbsp;
              {loading ? (
                <TabSpinner />
              ) : (
                <span style={{ fontSize: "12px" }}>
                  ({getFileCount(CrawlType.GoogleAnalytics)})
                </span>
              )}
            </>
          )}
        </ManualUploadsContext.Consumer>
      ),
      content: <AnalyticsManualUploads />,
    },
  ];

  return (
    <CustomAccordion
      title={t("sources.analytics.title")}
      icon={
        <GoogleAnalyticsSolid fontSize="small" style={{ display: "block" }} />
      }
      tooltip={t("sources.analytics.tooltip")}
      initialState={initialState}
      fieldName="crawlType.analytics"
      getBadges={getBadges}
      bottomContent={
        <DataOnlySourceSwitch name="dataOnlyCrawlType.analytics" />
      }
      data-pendo="google-analytics-header"
      data-testid="google-analytics-section"
    >
      <div style={{ width: "100%" }}>
        <Tabs
          value={selectedOption}
          onChange={(_, value) => setSelectedOption(value)}
        >
          {tabs.map((tab, idx) => (
            <Tab
              key={idx}
              value={idx}
              label={tab.label}
              icon={tab.icon}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelIcon: classes.labelIcon,
              }}
            />
          ))}
        </Tabs>
        {tabs[selectedOption]?.content}
      </div>
    </CustomAccordion>
  );
});
