/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-loops */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-mutation */

import { makeStyles } from "@material-ui/core";
import React from "react";
import { CustomSkeleton } from "../../../../_common/CustomSkeleton";
import { ResourceDetailData } from "../../../data/types";
import { useSiteSpeedReport } from "../data/useSiteSpeedReport";
import {
  SmileySad,
  EmptyState,
  Typography,
  useTranslation,
  Trans,
} from "@lumar/shared";

interface RequestNode {
  request: {
    url: string;
    startTime: number;
    endTime: number;
    responseReceivedTime: number;
    transferSize: number;
  };
  children?: Record<string, RequestNode>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: theme.typography.pxToRem(14),
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(1.25, 1.25, 3, 2.25),
    marginBottom: theme.spacing(3),
  },
  title: {
    padding: "10px 2px 10px 2px",
    fontWeight: 600,
    borderBottom: "1px solid #E5E7EB",
    marginBottom: 16,
  },
  chainContainer: {
    marginBottom: "10px",
  },
  initialNav: {
    color: theme.palette.grey[600],
    fontStyle: "italic",
    marginBottom: 5,
  },
  node: {
    display: "flex",
    alignItems: "center",
    height: 26,
  },
  treeMarkerContainer: {
    whiteSpace: "nowrap",
  },
  treeValue: {
    marginLeft: 10,
    whiteSpace: "nowrap",
  },
  treeMarker: {
    display: "inline-block",
    width: 12,
  },
  url: {
    display: "inline",
    direction: "rtl",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 10,
    color: theme.palette.grey[800],
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  urlHost: {
    marginLeft: 4,
    color: theme.palette.grey[500],
    whiteSpace: "nowrap",
  },
  duration: {
    fontWeight: 700,
    marginLeft: 4,
    whiteSpace: "nowrap",
    color: theme.palette.grey[800],
  },
  error: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
}));

const upRightMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  >
    <path d="M7 0h2v14H7zm2 12h7v2H9z" fill="#D8D8D8" fillRule="evenodd" />
  </svg>
);

const rightMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  >
    <path d="M16 12v2H0v-2z" fill="#D8D8D8" fillRule="evenodd" />
  </svg>
);

const horizontalDownMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  >
    <g fill="#D8D8D8" fillRule="evenodd">
      <path d="M16 12v2H-2v-2z" />
      <path d="M9 12v14H7V12z" />
    </g>
  </svg>
);

const verticalRightMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  >
    <path d="M7 0h2v27H7zm2 12h7v2H9z" fill="#D8D8D8" fillRule="evenodd" />
  </svg>
);

const verticalMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  >
    <path d="M7 0h2v26H7z" fill="#D8D8D8" fillRule="evenodd" />
  </svg>
);

const emptyMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    viewBox="0 0 12 26"
  ></svg>
);

const renderTreeMarkers = (
  depth: number,
  hasChildren: boolean,
  isLastNode: boolean,
): JSX.Element[] => {
  const markers: JSX.Element[] = [];

  if (depth === 0) {
    markers.push(upRightMarker);
  } else {
    for (let i = 0; i < depth; i++) {
      markers.push(emptyMarker);
      if (depth === 0) {
        markers.push(emptyMarker);
      }

      if (i % 2 === 0) {
        markers.push(emptyMarker);
      }

      if (depth - i > 1) {
        markers.push(verticalMarker);
      }
    }
    if (isLastNode) {
      markers.push(upRightMarker);
    } else {
      markers.push(verticalRightMarker);
    }
  }

  if (hasChildren) {
    markers.push(rightMarker);
    markers.push(horizontalDownMarker);
  } else {
    markers.push(rightMarker);
    markers.push(rightMarker);
  }

  return markers;
};

const CrcNode: React.FC<{
  node: RequestNode;
  depth: number;
  isLastNode: boolean;
}> = ({ node, depth, isLastNode }) => {
  const classes = useStyles({ depth });
  const { t } = useTranslation("resourceDetail");
  const duration = ((node.request.endTime - node.request.startTime) * 1000)
    .toFixed(3)
    .replace(/\.?0+$/, "");

  const transferSize = (node.request.transferSize / 1024).toFixed(2); // Convert bytes to KiB

  const children =
    node.children && Object.keys(node.children).length > 0
      ? Object.values(node.children)
      : [];

  const treeMarkers = renderTreeMarkers(depth, children.length > 0, isLastNode);

  return (
    <>
      <div className={classes.node}>
        <span className={classes.treeMarkerContainer}>{treeMarkers}</span>{" "}
        <a
          href={node.request.url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.url}
          title={node.request.url}
        >
          {new URL(node.request.url).pathname}
        </a>
        <span className={classes.urlHost}>
          ({new URL(node.request.url).host})
        </span>
        {children.length === 0 && (
          <span className={classes.duration}>
            {"  "} -{" "}
            {t("siteSpeed.crcDuration", {
              msValue: duration,
              kibValue: transferSize,
            })}
          </span>
        )}
      </div>
      {children.map((child, index) => (
        <CrcNode
          key={`${node.request.url}-${index}`}
          node={child}
          depth={depth + 1}
          isLastNode={index === children.length - 1}
        />
      ))}
    </>
  );
};

export const CriticalRequestChains = ({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element => {
  const classes = useStyles({ depth: 0 });
  const { t } = useTranslation("resourceDetail");
  const { report, loading, error } = useSiteSpeedReport(data.metrics);

  if (loading || !report) {
    return (
      <CustomSkeleton
        height={300}
        variant="rect"
        background="white"
        animation="wave"
      />
    );
  }

  const crcData = Object.values(report.audits).find(
    (audit) => audit.id === "critical-request-chains",
  );

  if (crcData?.details?.type === "criticalrequestchain" && !error) {
    const nodes = Object.values(crcData.details.chains);

    const rootNode = nodes[0];

    return (
      <div className={classes.container}>
        <Typography variant="body1" className={classes.title}>
          {t("siteSpeed.criticalRequestChains")}
        </Typography>
        <div className={classes.chainContainer}>
          <Trans
            ns="resourceDetail"
            i18nKey="siteSpeed.crcMaximumCriticalPathLatency"
            values={{
              value: crcData.details.longestChain.duration.toFixed(3),
            }}
            components={{ bold: <b /> }}
          />
        </div>
        <div className={classes.initialNav}>
          {t("siteSpeed.crcInitialNavigation")}
        </div>
        <CrcNode node={rootNode} depth={0} isLastNode={nodes.length === 1} />
      </div>
    );
  }

  return (
    <EmptyState
      icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
      title={t("siteSpeed.crcErrorTitle")}
      description={error}
      className={classes.error}
    />
  );
};
