import { getAmpValidationChart } from "../../../_common/charts/config/getAmpValidationChart";
import { getAnalyticsBreakdownChart } from "../../../_common/charts/config/getAnalyticsBreakdownChart";
import { getBacklinkIssuesChart } from "../../../_common/charts/config/getBacklinkIssuesChart";
import { getBacklinksBreakdownChart } from "../../../_common/charts/config/getBacklinksBreakdownChart";
import { getBotHitsBreakdownChart } from "../../../_common/charts/config/getBotHitsBreakdownChart";
import { getBotHitsByDeviceChart } from "../../../_common/charts/config/getBotHitsByDeviceChart";
import { getBrokenLinksChart } from "../../../_common/charts/config/getBrokenLinksChart";
import { getCanonicalBreakdownChart } from "../../../_common/charts/config/getCanonicalBreakdownChart";
import { getCanonicalIssuesChart } from "../../../_common/charts/config/getCanonicalIssuesChart";
import { getContentDuplicationChart } from "../../../_common/charts/config/getContentDuplicationChart";
import { getContentIssuesChart } from "../../../_common/charts/config/getContentIssuesChart";
import { getContentTypeBreakdownChart } from "../../../_common/charts/config/getContentTypeBreakdownChart";
import { getCrawlSourceOverlapChart } from "../../../_common/charts/config/getCrawlSourceOverlapChart";
import { getCumulativeLayoutShiftChart } from "../../../_common/charts/config/getCumulativeLayoutShiftChart";
import { getCustomExtractionChart } from "../../../_common/charts/config/getCustomExtractionChart";
import { getDomContentLoadChart } from "../../../_common/charts/config/getDomContentLoadChart";
import { getDuplicateSetsChart } from "../../../_common/charts/config/getDuplicateSetsChart";
import { getExternalLinkBreakdownChart } from "../../../_common/charts/config/getExternalLinkBreakdownChart";
import { getExtractReportsChart } from "../../../_common/charts/config/getExtractReportsChart";
import { getFirstContentfulPaintChart } from "../../../_common/charts/config/getFirstContentfulPaintChart";
import { getGoogleSearchConsoleBreakdownChart } from "../../../_common/charts/config/getGoogleSearchConsoleBreakdownChart";
import { getHreflangBreakdownChart } from "../../../_common/charts/config/getHreflangBreakdownChart";
import { getHreflangIssuesChart } from "../../../_common/charts/config/getHreflangIssuesChart";
import { getHttpAndHttpsChart } from "../../../_common/charts/config/getHttpAndHttpsChart";
import { getHttpsIssuesChart } from "../../../_common/charts/config/getHttpsIssuesChart";
import { getHttpStatusBreakdownChart } from "../../../_common/charts/config/getHttpStatusBreakdownChart";
import { getIndexabilityChart } from "../../../_common/charts/config/getIndexabilityChart";
import { getIndexablePagesInSearchChart } from "../../../_common/charts/config/getIndexablePagesInSearchChart";
import { getIndexablePageUniquenessChart } from "../../../_common/charts/config/getIndexablePageUniquenessChart";
import { getLargestContentfulPaintChart } from "../../../_common/charts/config/getLargestContentfulPaintChart";
import { getLinksBreakdownChart } from "../../../_common/charts/config/getLinksBreakdownChart";
import { getListBreakdownChart } from "../../../_common/charts/config/getListBreakdownChart";
import { getLogSummaryIssuesChart } from "../../../_common/charts/config/getLogSummaryIssuesChart";
import { getMobileConfigurationChart } from "../../../_common/charts/config/getMobileConfigurationChart";
import { getMobileIssuesChart } from "../../../_common/charts/config/getMobileIssuesChart";
import { getNewsArticleStructuredDataValidationBreakdownChart } from "../../../_common/charts/config/getNewsArticleStructuredDataValidationBreakdownChart";
import { getNonIndexablePagesBySourceChart } from "../../../_common/charts/config/getNonIndexablePagesBySourceChart";
import { getNonIndexablePagesChartWithFixedReportOrder } from "../../../_common/charts/config/getNonIndexablePagesChart";
import { getOrphanedPagesReportChart } from "../../../_common/charts/config/getOrphanedPagesReportChart";
import { getPageIssuesChart } from "../../../_common/charts/config/getPageIssuesChart";
import { getPagesBreakdownChart } from "../../../_common/charts/config/getPagesBreakdownSingle";
import { getPagesBreakdownTrendChart } from "../../../_common/charts/config/getPagesBreakdownTrendChart";
import { getPagesWithSchemaMarkupChart } from "../../../_common/charts/config/getPagesWithSchemaMarkupChart";
import { getPaginationChart } from "../../../_common/charts/config/getPaginationChart";
import { getPerformanceBreakdownChart } from "../../../_common/charts/config/getPerformanceBreakdownChart";
import { getProductStructuredDataValidationBreakdownChart } from "../../../_common/charts/config/getProductStructuredDataValidationBreakdownChart";
import { getRedirectBreakdownChart } from "../../../_common/charts/config/getRedirectBreakdownChart";
import { getRenderingIssuesChart } from "../../../_common/charts/config/getRenderingIssuesChart";
import { getResourceIssuesChart } from "../../../_common/charts/config/getResourceIssuesChart";
import { getSearchImpressionDistributionChart } from "../../../_common/charts/config/getSearchImpressionDistributionChart";
import { getServerResponseTimeChart } from "../../../_common/charts/config/getServerResponseTimeChart";
import { getSitemapsChart } from "../../../_common/charts/config/getSitemapsChart";
import { getSitemapLinksIssuesChart } from "../../../_common/charts/config/getSitemapLinksIssuesChart";
import { getSitemapsBreakdownChart } from "../../../_common/charts/config/getSitemapsBreakdownChart";
import { getStructuredDataTypesChart } from "../../../_common/charts/config/getStructuredDataTypesChart";
import { getTimeToInteractiveChart } from "../../../_common/charts/config/getTimeToInteractiveChart";
import { getTitleAndDescriptionIssuesChart } from "../../../_common/charts/config/getTitleAndDescriptionIssuesChart";
import { getUrlFetchTimeChart } from "../../../_common/charts/config/getUrlFetchTimeChart";
import { getWebCrawlBreakdownChart } from "../../../_common/charts/config/getWebCrawlBreakdownChart";
import { getSocialTagsBreakdownChart } from "../../../_common/charts/config/getSocialTagsBreakdownChart";
import { ChartConfigs, ReportMap } from "./types";
import { getUncrawledUrlsBreakdownChart } from "../../../_common/charts/config/getUncrawledUrlsBreakdownChart";
import { getLogSummaryBreakdownChart } from "../../../_common/charts/config/getLogSummaryBreakdownChart";
import { getGscTrafficFunnel } from "../../../_common/charts/config/getGscTrafficFunnel";
import { getGscTrafficLevel } from "../../../_common/charts/config/getGscTrafficLevel";
import { getBotHitsCrawlBudget } from "../../../_common/charts/config/getBotHitsCrawlBudget";
import { getAnalyticsTrafficFunnel } from "../../../_common/charts/config/getAnalyticsTrafficFunnel";
import { getEngagementBreakdownChart } from "../../../_common/charts/config/getEngagementBreakdownChart";
import { getPageViewsPerSessionChart } from "../../../_common/charts/config/getPageViewsPerSessionChart";
import { getBounceRateBreakdownChart } from "../../../_common/charts/config/getBounceRateBreakdownChart";
import { getAvgTimeOnPageChart } from "../../../_common/charts/config/getAvgTimeOnPageChart";
import { getServerResponseTimeTrend } from "../../../_common/charts/config/getServerResponseTimeTrend";
import { getPagesinSitemapsBreakdownChart } from "../../../_common/charts/config/getPagesinSitemapsBreakdownChart";
import { getPrimaryPagesNotInSerps } from "../../../_common/charts/config/getPrimaryPagesNotInSerps";
import { getExperienceBrokenChart } from "../../../_common/charts/config/getExperienceBrokenChart";
import { getAccessibilityColorChart } from "../../../_common/charts/config/getAccessibilityColorChart";
import { getAccessibilityIssuesAndInstancesTrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesTrend";
import { getAccessibilitySemanticsChart } from "../../../_common/charts/config/getAccessibilitySemanticsChart";
import { getAccessibilityFormsChart } from "../../../_common/charts/config/getAccessibilityFormsChart";
import { getAccessibilityLanguageChart } from "../../../_common/charts/config/getAccessibilityLanguageChart";
import { getAccessibilityStructureChart } from "../../../_common/charts/config/getAccessibilityStructureChart";
import { getAccessibilityLevelsChart } from "../../../_common/charts/config/getAccessibilityLevelsChart";
import { getAccessibilityIssuesandInstancesAAATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesAAATrend";
import { getAccessibilityIssuesandInstancesAATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesAATrend";
import { getAccessibilityIssuesandInstancesATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesATrend";
import { getAccessibilityIssuesandInstancesBPTrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesBPTrend";
import { getAccessibilitySeverityChart } from "../../../_common/charts/config/getAccessibilitySeverityChart";
import { getAccessibilityAriaAChart } from "../../../_common/charts/config/getAccessibilityAriaAChart";
import { getAccessibilityAriaBPChart } from "../../../_common/charts/config/getAccessibilityAriaBPChart";
import { getAccessibilityColorAChart } from "../../../_common/charts/config/getAccessibilityColorAChart";
import { getAccessibilityFormsAChart } from "../../../_common/charts/config/getAccessibilityFormsAChart";
import { getAccessibilityFormsBPChart } from "../../../_common/charts/config/getAccessibilityFormsBPChart";
import { getAccessibilityKeyboardAChart } from "../../../_common/charts/config/getAccessibilityKeyboardAChart";
import { getAccessibilityKeyboardBPChart } from "../../../_common/charts/config/getAccessibilityKeyboardBPChart";
import { getAccessibilityLanguageAChart } from "../../../_common/charts/config/getAccessibilityLanguageAChart";
import { getAccessibilityNameRoleValueAChart } from "../../../_common/charts/config/getAccessibilityNameRoleValueAChart";
import { getAccessibilityNameRoleValueBPChart } from "../../../_common/charts/config/getAccessibilityNameRoleValueBPChart";
import { getAccessibilityParsingAChart } from "../../../_common/charts/config/getAccessibilityParsingAChart";
import { getAccessibilitySemanticsAChart } from "../../../_common/charts/config/getAccessibilitySemanticsAChart";
import { getAccessibilitySemanticsBPChart } from "../../../_common/charts/config/getAccessibilitySemanticsBPChart";
import { getAccessibilitySensoryAndVisualBPChart } from "../../../_common/charts/config/getAccessibilitySensoryAndVisualBPChart";
import { getAccessibilityStructureAChart } from "../../../_common/charts/config/getAccessibilityStructureAChart";
import { getAccessibilityStructureBPChart } from "../../../_common/charts/config/getAccessibilityStructureBPChart";
import { getAccessibilityTablesAChart } from "../../../_common/charts/config/getAccessibilityTablesAChart";
import { getAccessibilityTablesBPChart } from "../../../_common/charts/config/getAccessibilityTablesBPChart";
import { getAccessibilityTextAlternativesAChart } from "../../../_common/charts/config/getAccessibilityTextAlternativesAChart";
import { getAccessibilityTextAlternativesBPChart } from "../../../_common/charts/config/getAccessibilityTextAlternativesBPChart";
import { getAccessibilityTimeAndMediaAChart } from "../../../_common/charts/config/getAccessibilityTimeAndMediaAChart";
import { getAccessibilityColorAAChart } from "../../../_common/charts/config/getAccessibilityColorAAChart";
import { getAccessibilityLanguageAAChart } from "../../../_common/charts/config/getAccessibilityLanguageAAChart";
import { getAccessibilityLevelAAChart } from "../../../_common/charts/config/getAccessibilityLevelAAChart";
import { getAccessibilityLevelAAAChart } from "../../../_common/charts/config/getAccessibilityLevelAAAChart";
import { getAccessibilityLevelAChart } from "../../../_common/charts/config/getAccessibilityLevelAChart";
import { getAccessibilitySensoryAndVisualAAChart } from "../../../_common/charts/config/getAccessibilitySensoryAndVisualAAChart";
import { getSiteSpeedIssuesCLS } from "../../../_common/charts/config/getSiteSpeedIssuesCLS";
import { getSiteSpeedCLSLayoutShiftElements } from "../../../_common/charts/config/getSiteSpeedCLSLayoutShiftElements";
import { getSiteSpeedIssuesTBT } from "../../../_common/charts/config/getSiteSpeedIssuesTBT";
import { getSiteSpeedIssuesFCP } from "../../../_common/charts/config/getSiteSpeedIssuesFCP";
import { getSiteSpeedIssuesLCP } from "../../../_common/charts/config/getSiteSpeedIssuesLCP";
import { getSiteSpeedMainthreadChart } from "../../../_common/charts/config/getSiteSpeedMainthreadChart";
import { getSiteSpeedMainthreadTrend } from "../../../_common/charts/config/getSiteSpeedMainthreadTrend";
import { getSiteSpeedAvgCruxCLSChart } from "../../../_common/charts/config/getSiteSpeedAvgCruxCLSChart";
import { getSiteSpeedAvgCruxFCPChart } from "../../../_common/charts/config/getSiteSpeedAvgCruxFCPChart";
import { getSiteSpeedAvgCruxITNPChart } from "../../../_common/charts/config/getSiteSpeedAvgCruxITNPChart";
import { getSiteSpeedAvgCruxLCPChart } from "../../../_common/charts/config/getSiteSpeedAvgCruxLCPChart";
import { getConversionPagesChart } from "../../../_common/charts/config/getConversionPagesChart";

const CUSTOM_EXTRACTION_REPORT_MAPPINGS: ReportMap = Array.from(
  { length: 30 },
  (_, i) => `custom_extraction_${i + 1}`,
).map((customExtractionReportTemplateCode: string) => [
  customExtractionReportTemplateCode,
  [getCustomExtractionChart, getExtractReportsChart],
]);

export const reportsChartsMap = new Map<string, ChartConfigs>([
  // INDEXATION
  [
    "all_pages",
    [
      getPagesBreakdownChart,
      getHttpStatusBreakdownChart,
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  [
    "unique_pages",
    [
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  ["indexable_pages", [getIndexablePageUniquenessChart, getIndexabilityChart]],
  [
    "non_indexable_pages",
    [
      getNonIndexablePagesChartWithFixedReportOrder,
      getIndexabilityChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "mobile_alternates",
    [
      getIndexabilityChart,
      getCanonicalBreakdownChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  ["200_pages", [getHttpStatusBreakdownChart]],
  [
    "non-200_pages",
    [
      getHttpStatusBreakdownChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  ["uncrawled_urls", [getUncrawledUrlsBreakdownChart]],
  ["non_html_pages", []],
  ["external_urls_crawled", []],
  ["external_urls", [getUncrawledUrlsBreakdownChart]],
  ["primary_duplicates", [getIndexablePageUniquenessChart]],
  ["duplicate_pages_including_primary", [getContentDuplicationChart]],
  [
    "primary_pages",
    [
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "duplicate_pages",
    [
      getContentDuplicationChart,
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  ["true_uniques", [getIndexablePageUniquenessChart]],
  ["duplicate_pages_2", [getDuplicateSetsChart]],
  [
    "paginated_pages",
    [getPaginationChart, getPagesBreakdownChart, getPagesBreakdownTrendChart],
  ],
  ["self_canonicalized_pages", [getCanonicalBreakdownChart]],
  ["duplicate_pages_inc_non_indexable", []],
  ["noindex_pages", [getNonIndexablePagesChartWithFixedReportOrder]],
  [
    "canonicalized_pages",
    [getCanonicalBreakdownChart, getNonIndexablePagesChartWithFixedReportOrder],
  ],
  ["canonicalized_noindexed_pages", []],
  ["301_redirects", [getHttpStatusBreakdownChart, getRedirectBreakdownChart]],
  [
    "non_301_redirects",
    [getHttpStatusBreakdownChart, getRedirectBreakdownChart],
  ],
  ["5xx_errors", [getHttpStatusBreakdownChart]],
  ["4xx_errors", [getHttpStatusBreakdownChart]],
  ["unauthorised_pages", [getHttpStatusBreakdownChart]],
  ["uncategorised_http_response_codes", [getHttpStatusBreakdownChart]],
  [
    "failed_urls",
    [
      getPagesBreakdownChart,
      getHttpStatusBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  ["disallowed_pages", [getNonIndexablePagesChartWithFixedReportOrder]],
  ["disallowed_urls", [getUncrawledUrlsBreakdownChart]],
  ["robots_noindexed_urls", [getUncrawledUrlsBreakdownChart]],
  ["malformed_urls", [getUncrawledUrlsBreakdownChart]],
  ["excluded_urls", [getUncrawledUrlsBreakdownChart]],
  ["not_included_urls", [getUncrawledUrlsBreakdownChart]],
  ["page_group_restricted_urls", [getUncrawledUrlsBreakdownChart]],
  ["other_uncrawled_(invalid)", [getUncrawledUrlsBreakdownChart]],
  // ADMIN
  ["crawl_limit_restricted_urls", [getUncrawledUrlsBreakdownChart]],
  ["aaa_crawl_hreflangs", []],
  ["aaa_crawl_links", []],
  ["aaa_crawl_unique_links", []],
  ["aaa_crawl_linked_domains", []],
  ["aaa_crawl_duplicate_urls", []],
  ["aaa_crawl_sitemaps", []],
  ["aaa_crawl_uncrawled_urls", []],
  ["aaa_crawl_urls_ordered", []],
  // CONTENT
  ["missing_titles", [getTitleAndDescriptionIssuesChart]],
  ["short_titles", [getTitleAndDescriptionIssuesChart]],
  ["max_title_length", [getTitleAndDescriptionIssuesChart]],
  ["pages_with_duplicate_titles", [getContentDuplicationChart]],
  ["duplicate_titles_2", [getDuplicateSetsChart]],
  ["missing_descriptions", [getTitleAndDescriptionIssuesChart]],
  ["short_descriptions", [getTitleAndDescriptionIssuesChart]],
  ["max_description_length", [getTitleAndDescriptionIssuesChart]],
  ["max_mobile_description_length", []],
  ["pages_with_duplicate_descriptions", [getContentDuplicationChart]],
  ["duplicate_descriptions_2", [getDuplicateSetsChart]],
  ["empty_pages", [getContentIssuesChart]],
  ["thin_pages", [getContentIssuesChart]],
  ["max_content_size", [getContentIssuesChart]],
  ["duplicate_content_2", [getDuplicateSetsChart]],
  ["duplicate_body_content", [getContentDuplicationChart]],
  ["missing_h1_tags", [getPageIssuesChart]],
  ["multiple_h1_tag_pages", [getPageIssuesChart]],
  ["noarchive_pages", [getPageIssuesChart]],
  ["javascript_files", [getContentTypeBreakdownChart]],
  ["css_files", [getContentTypeBreakdownChart]],
  ["pdf_files", []],
  ["broken_js_css", [getResourceIssuesChart]],
  ["disallowed_js_css", [getResourceIssuesChart]],
  ["disallowed_js_css_uncrawled", [getResourceIssuesChart]],
  ["all_images_2", [getContentTypeBreakdownChart]],
  ["broken_images", [getExperienceBrokenChart]],
  ["unique_redirecting_images", [getResourceIssuesChart]],
  ["linked_non_html_files", [getContentTypeBreakdownChart]],
  ["pages_without_social_markup", [getSocialTagsBreakdownChart]],
  ["pages_with_social_markup", [getSocialTagsBreakdownChart]],
  // CONFIG
  [
    "pages_without_canonical_tag",
    [getCanonicalIssuesChart, getCanonicalBreakdownChart],
  ],
  ["all_rel_links", [getLinksBreakdownChart]],
  [
    "unlinked_canonical_pages",
    [getCanonicalIssuesChart, getOrphanedPagesReportChart],
  ],
  ["canonical_pages", []],
  ["high_canonicals_in", []],
  ["conflicting_canonical_tags", [getCanonicalIssuesChart]],
  ["canonical_to_non_200", [getBrokenLinksChart, getCanonicalIssuesChart]],
  ["non_indexable_canonical_links_in", []],
  ["redirect_chains", [getRedirectBreakdownChart]],
  ["all_redirects", [getRedirectBreakdownChart]],
  ["excessive_redirects_in", []],
  ["all_broken_redirects", [getRedirectBreakdownChart]],
  ["internal_redirects_found_in_web", []],
  ["max_redirections", [getRedirectBreakdownChart]],
  ["redirection_loop", [getRedirectBreakdownChart]],
  ["meta_refresh_redirect", [getRedirectBreakdownChart]],
  ["javascript_redirects", []],
  ["http_pages", [getHttpAndHttpsChart]],
  ["https_pages", [getHttpAndHttpsChart]],
  [
    "http_scripts_on_https_pages",
    [getHttpsIssuesChart, getResourceIssuesChart],
  ],
  ["non_secure_form_fields", [getHttpsIssuesChart]],
  ["pages_with_hsts", []],
  ["links_between_protocols", [getHttpsIssuesChart]],
  ["http_pages_with_hsts", [getCanonicalBreakdownChart]],
  ["pages_without_hreflang_tags", [getHreflangIssuesChart]],
  ["pages_with_hreflang_tags", [getHreflangIssuesChart]],
  ["not_supported_hreflang_links", []],
  ["all_hreflang_links", [getHreflangBreakdownChart, getLinksBreakdownChart]],
  ["unsupported_hreflang_links", [getHreflangBreakdownChart]],
  ["broken_hreflang_links", [getBrokenLinksChart]],
  ["all_hreflang_combinations", [getHreflangBreakdownChart]],
  ["hreflang_link_non_200", [getHreflangBreakdownChart]],
  ["non_indexable_hreflang_links_in", []],
  ["first_pages", [getPaginationChart]],
  ["unlinked_paginated_pages", [getOrphanedPagesReportChart]],
  ["double_slash_urls", []],
  ["max_url_length", [getPageIssuesChart]],
  ["broken_head_warning", [getRenderingIssuesChart]],
  ["canonical_link_mismatch", [getRenderingIssuesChart]],
  ["rendered_link_count_mismatch", [getRenderingIssuesChart]],
  ["rendered_word_count_mismatch", [getRenderingIssuesChart]],
  // LINKS
  ["unique_internal_links", [getLinksBreakdownChart]],
  ["all_broken_links", [getBrokenLinksChart]],
  ["unique_broken_links", [getBrokenLinksChart]],
  ["missing_image_link_alt_tags", [getResourceIssuesChart]],
  ["max_links", [getPageIssuesChart]],
  ["meta_nofollow_pages", [getPageIssuesChart]],
  ["nofollowed_pages", [getNonIndexablePagesChartWithFixedReportOrder]],
  ["max_external_links", [getExternalLinkBreakdownChart]],
  ["linked_domains", [getExternalLinkBreakdownChart]],
  ["unique_external_links", [getExternalLinkBreakdownChart]],
  ["broken_links_external", []],
  ["external_redirects", [getExternalLinkBreakdownChart]],
  ["unique_external_redirects", []],
  ["all_sitemaps_links", [getLinksBreakdownChart]],
  ["xml_sitemaps", [getSitemapsChart]],
  ["empty_sitemaps", [getSitemapsChart]],
  ["not_in_sitemaps_primary_indexable", []],
  ["broken_sitemap_links", [getBrokenLinksChart, getSitemapLinksIssuesChart]],
  [
    "sitemaps_non_indexable_links",
    [getNonIndexablePagesBySourceChart, getSitemapLinksIssuesChart],
  ],
  ["mobile_alternates_in_sitemaps", [getSitemapLinksIssuesChart]],
  ["sitemaps_disallowed_malformed_links", [getSitemapLinksIssuesChart]],
  ["sitemaps_too_many_urls", [getSitemapsBreakdownChart]],
  ["sitemaps_broken_disallowed", [getSitemapsBreakdownChart]],
  ["backlink_pages_redirects", [getBacklinkIssuesChart]],
  ["backlink_pages_with_errors", [getBrokenLinksChart, getBacklinkIssuesChart]],
  ["backlink_pages_disallowed", [getBacklinkIssuesChart]],
  ["backlink_pages_disallowed_uncrawled", [getBacklinkIssuesChart]],
  ["backlink_pages_with_nofollow", [getBacklinkIssuesChart]],
  ["backlink_pages_indexable", []],
  ["backlink_pages_no_links_out", [getBacklinkIssuesChart]],
  [
    "backlink_pages_non_indexable",
    [getNonIndexablePagesBySourceChart, getBacklinkIssuesChart],
  ],
  ["mobile_alternates_with_backlinks", []],
  // STRUCTURED DATA
  ["pages_with_breadcrumb_schema", [getStructuredDataTypesChart]],
  ["pages_with_event_schema", [getStructuredDataTypesChart]],
  ["pages_with_faqpage_schema", [getStructuredDataTypesChart]],
  ["pages_with_howto_schema", [getStructuredDataTypesChart]],
  ["pages_with_news_article_schema", [getStructuredDataTypesChart]],
  ["pages_with_product_schema", [getStructuredDataTypesChart]],
  ["pages_with_qapage_schema", [getStructuredDataTypesChart]],
  ["pages_with_recipe_schema", [getStructuredDataTypesChart]],
  ["pages_with_review_schema", [getStructuredDataTypesChart]],
  ["pages_with_videoobject_schema", [getStructuredDataTypesChart]],
  [
    "pages_with_invalid_news_article_schema",
    [getNewsArticleStructuredDataValidationBreakdownChart],
  ],
  [
    "pages_with_invalid_product_schema",
    [getProductStructuredDataValidationBreakdownChart],
  ],
  [
    "pages_with_valid_news_article_schema",
    [getNewsArticleStructuredDataValidationBreakdownChart],
  ],
  [
    "pages_with_valid_product_schema",
    [getProductStructuredDataValidationBreakdownChart],
  ],
  ["pages_with_structured_data", [getPagesWithSchemaMarkupChart]],
  ["pages_without_structured_data", [getPagesWithSchemaMarkupChart]],
  // TRAFFIC
  [
    "indexable_pages_in_search",
    [getSearchImpressionDistributionChart, getIndexablePagesInSearchChart],
  ],
  ["non_indexable_pages_in_search", [getSearchImpressionDistributionChart]],
  ["mobile_amphtml_pages_in_search", [getSearchImpressionDistributionChart]],
  ["primary_pages_with_serp_clicks", [getGscTrafficFunnel]],
  ["primary_pages_in_serp_without_clicks", [getGscTrafficFunnel]],
  [
    "primary_pages_not_in_serp",
    [getPrimaryPagesNotInSerps, getGscTrafficFunnel],
  ],
  [
    "primary_pages_not_in_serp_not_in_sitemaps",
    [getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_without_followed_links",
    [getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_with_low_deeprank",
    [getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_with_no_backlinks",
    [getPagesinSitemapsBreakdownChart],
  ],
  ["duplicate_pages_in_serp", [getGscTrafficFunnel]],
  ["non_indexable_pages_in_serp", [getGscTrafficFunnel]],
  ["redirecting_pages_in_serp", [getGscTrafficFunnel]],
  ["error_pages_in_serp", [getGscTrafficFunnel]],
  ["primary_pages_with_1_to_10_clicks", [getGscTrafficLevel]],
  ["primary_pages_with_10_to_100_clicks", [getGscTrafficLevel]],
  ["primary_pages_with_100_to_1000_clicks", [getGscTrafficLevel]],
  ["primary_pages_with_1000_to_10000_clicks", [getGscTrafficLevel]],
  ["primary_pages_with_10000_plus_clicks", [getGscTrafficLevel]],
  ["primary_pages_with_visits", [getAnalyticsTrafficFunnel]],
  ["primary_pages_with_no_visits", [getAnalyticsTrafficFunnel]],
  ["duplicate_pages_with_visits", [getAnalyticsTrafficFunnel]],
  ["non_indexable_pages_with_visits", [getAnalyticsTrafficFunnel]],
  ["redirecting_pages_with_visits", [getAnalyticsTrafficFunnel]],
  ["error_pages_with_visits", [getAnalyticsTrafficFunnel]],

  // HS EXPERIENCE
  ["good_engagement", [getEngagementBreakdownChart]],
  ["moderate_engagement", [getEngagementBreakdownChart]],
  ["poor_engagement", [getEngagementBreakdownChart]],
  ["bad_engagement", [getEngagementBreakdownChart]],

  ["low_pageviews_per_session", [getPageViewsPerSessionChart]],
  ["moderate_pageviews_per_session", [getPageViewsPerSessionChart]],
  ["high_pageviews_per_session", [getPageViewsPerSessionChart]],

  ["high_bounce_rate", [getBounceRateBreakdownChart]],
  ["moderate_bounce_rate", [getBounceRateBreakdownChart]],
  ["low_bounce_rate", [getBounceRateBreakdownChart]],

  ["low_avg_time_on_page", [getAvgTimeOnPageChart]],
  ["moderate_avg_time_on_page", [getAvgTimeOnPageChart]],
  ["high_avg_time_on_page", [getAvgTimeOnPageChart]],

  ["broken_image_urls", [getResourceIssuesChart]],
  ["redirecting_image_urls", [getResourceIssuesChart]],
  ["broken_js", [getResourceIssuesChart]],
  ["broken_css", [getResourceIssuesChart]],
  ["http_scripts_on_https_pages", [getResourceIssuesChart]],
  ["disallowed_js", [getResourceIssuesChart]],
  ["disallowed_css", [getResourceIssuesChart]],
  ["disallowed_js_uncrawled", [getResourceIssuesChart]],
  ["disallowed_css_uncrawled", [getResourceIssuesChart]],

  // MOBILE
  ["discouraged_viewport_types", []],
  ["non_200_separate_mobile_amp", [getBrokenLinksChart, getMobileIssuesChart]],
  ["non_reciprocal_mobile_amp_desktop", [getMobileIssuesChart]],
  ["mobile_alternates_noindex", [getMobileIssuesChart]],
  ["separate_mobile_content_mismatch", [getMobileIssuesChart]],
  ["separate_mobile_wordcount_mismatch", [getMobileIssuesChart]],
  ["separate_mobile_links_in_mismatch", [getMobileIssuesChart]],
  ["separate_mobile_links_out_mismatch", [getMobileIssuesChart]],
  ["amphtml_non_rel_alted", [getMobileIssuesChart]],
  ["mobile_desktop_responsive", [getMobileConfigurationChart]],
  ["mobile_desktop_dynamic", [getMobileConfigurationChart]],
  ["mobile_desktop_separate_desktop", [getMobileConfigurationChart]],
  ["mobile_desktop_separate_mobile", [getMobileConfigurationChart]],
  ["mobile_desktop_amphtml", [getMobileConfigurationChart]],
  ["mobile_app_links", []],
  ["no_mobile_configuration", [getMobileConfigurationChart]],
  ["valid_amp_pages", [getAmpValidationChart]],
  ["invalid_amp_pages", [getAmpValidationChart]],
  // LOG FILES
  ["pages_with_no_bot_hits", [getBotHitsBreakdownChart]],
  ["pages_with_low_bot_hits", [getBotHitsBreakdownChart]],
  ["pages_with_medium_bot_hits", [getBotHitsBreakdownChart]],
  ["pages_with_high_bot_hits", [getBotHitsBreakdownChart]],
  ["pages_with_desktop_bot_hits", [getBotHitsByDeviceChart]],
  ["pages_with_mobile_bot_hits", [getBotHitsByDeviceChart]],
  ["desktop_pages_with_low_desktop_bot_hits", [getLogSummaryIssuesChart]],
  ["mobile_alternates_with_low_mobile_bot_hits", [getLogSummaryIssuesChart]],
  ["disallowed_pages_with_bot_hits", [getLogSummaryIssuesChart]],
  ["disallowed_pages_with_bot_hits_uncrawled", [getLogSummaryIssuesChart]],
  ["pages_in_sitemap_with_no_bot_hits", [getLogSummaryIssuesChart]],
  ["indexable_pages_with_no_bot_hits", [getLogSummaryIssuesChart]],
  ["primary_pages_with_bot_hits", [getBotHitsCrawlBudget]],
  ["primary_pages_with_no_bot_hits", [getBotHitsCrawlBudget]],
  ["duplicate_pages_with_bot_hits", [getBotHitsCrawlBudget]],
  [
    "non_indexable_pages_with_bot_hits",
    [getBotHitsCrawlBudget, getLogSummaryIssuesChart],
  ],
  ["redirects_with_bot_hits", [getBotHitsCrawlBudget]],
  [
    "error_pages_with_bot_hits",
    [getBotHitsCrawlBudget, getLogSummaryIssuesChart],
  ],

  // PERFORMANCE
  ["fast_load_time", [getUrlFetchTimeChart]],
  ["medium_load_time", [getUrlFetchTimeChart]],
  ["max_load_time", [getUrlFetchTimeChart, getPerformanceBreakdownChart]],
  ["fast_server_response", [getServerResponseTimeChart]],
  ["medium_server_response", [getServerResponseTimeChart]],
  [
    "slow_server_response",
    [getServerResponseTimeChart, getServerResponseTimeTrend],
  ],
  ["fast_fcp", [getFirstContentfulPaintChart]],
  ["average_fcp", [getFirstContentfulPaintChart]],
  ["slow_fcp", [getFirstContentfulPaintChart]],
  ["fast_lcp", [getLargestContentfulPaintChart]],
  ["average_lcp", [getLargestContentfulPaintChart]],
  ["slow_lcp", [getLargestContentfulPaintChart]],
  ["good_cls", [getCumulativeLayoutShiftChart]],
  ["average_cls", [getCumulativeLayoutShiftChart]],
  ["poor_cls", [getCumulativeLayoutShiftChart]],
  ["fast_tti", [getTimeToInteractiveChart]],
  ["average_tti", [getTimeToInteractiveChart]],
  ["slow_tti", [getTimeToInteractiveChart]],
  ["fast_dcl", [getDomContentLoadChart]],
  ["average_dcl", [getDomContentLoadChart]],
  ["slow_dcl", [getDomContentLoadChart]],
  ["max_html_size", [getPerformanceBreakdownChart]],
  // SOURCE GAP
  [
    "pages_not_in_web_crawl",
    [getWebCrawlBreakdownChart, getCrawlSourceOverlapChart],
  ],
  [
    "all_web_crawl_urls",
    [getWebCrawlBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["pages_not_in_list", [getListBreakdownChart, getCrawlSourceOverlapChart]],
  [
    "all_crawled_urls_in_list",
    [getListBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["orphaned_list_pages", [getOrphanedPagesReportChart]],
  ["all_sitemaps_links", [getLinksBreakdownChart]],
  ["xml_sitemaps", [getSitemapsChart]],
  [
    "all_crawled_urls_in_xml_sitemaps",
    [getSitemapsBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["not_in_sitemaps_primary_indexable", []],
  ["broken_sitemap_links", [getSitemapLinksIssuesChart]],
  [
    "sitemaps_non_indexable_links",
    [getNonIndexablePagesBySourceChart, getSitemapLinksIssuesChart],
  ],
  ["mobile_alternates_in_sitemaps", [getSitemapLinksIssuesChart]],
  ["sitemaps_disallowed_malformed_links", [getSitemapLinksIssuesChart]],
  ["sitemaps_too_many_urls", [getSitemapsChart]],
  ["sitemaps_broken_disallowed", [getSitemapsChart]],
  ["orphaned_sitemaps_pages", [getOrphanedPagesReportChart]],
  [
    "missing_in_sitemaps",
    [getSitemapsBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["backlink_pages_redirects", [getBacklinkIssuesChart]],
  ["backlink_pages_with_errors", [getBrokenLinksChart, getBacklinkIssuesChart]],
  ["backlink_pages_disallowed", [getBacklinkIssuesChart]],
  ["backlink_pages_disallowed_uncrawled", [getBacklinkIssuesChart]],
  ["backlink_pages_with_nofollow", [getBacklinkIssuesChart]],
  ["backlink_pages_indexable", []],
  ["backlink_pages_no_links_out", [getBacklinkIssuesChart]],
  [
    "backlink_pages_non_indexable",
    [getNonIndexablePagesBySourceChart, getBacklinkIssuesChart],
  ],
  ["mobile_alternates_with_backlinks", []],
  [
    "all_cralwed_urls_with_backlinks",
    [getBacklinksBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["orphaned_pages_with_backlinks", [getOrphanedPagesReportChart]],
  [
    "missing_in_backlinks_crawl",
    [getBacklinksBreakdownChart, getCrawlSourceOverlapChart],
  ],
  [
    "all_crawled_organic_landing_pages",
    [getAnalyticsBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["orphaned_organic_landing_pages", [getOrphanedPagesReportChart]],
  [
    "missing_in_organic_landing_pages",
    [getAnalyticsBreakdownChart, getCrawlSourceOverlapChart],
  ],
  [
    "pages_in_google_search_console",
    [getGoogleSearchConsoleBreakdownChart, getCrawlSourceOverlapChart],
  ],
  [
    "pages_not_in_google_search_console",
    [getGoogleSearchConsoleBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["orphaned_google_search_console_pages", [getOrphanedPagesReportChart]],
  [
    "pages_in_log_summary",
    [getLogSummaryBreakdownChart, getCrawlSourceOverlapChart],
  ],
  [
    "pages_not_in_log_summary",
    [getLogSummaryBreakdownChart, getCrawlSourceOverlapChart],
  ],
  ["orphaned_log_summary_pages", [getOrphanedPagesReportChart]],
  // EXTRACTION
  ["extract_report", [getCustomExtractionChart, getExtractReportsChart]],
  [
    "extract_complement_report",
    [getCustomExtractionChart, getExtractReportsChart],
  ],
  ...CUSTOM_EXTRACTION_REPORT_MAPPINGS,
  // NO CATEGORY
  ["duplicate_body_content_excl_duplicate_pages", []],
  ["pages_with_duplicate_descriptions_excl_duplicate_pages", []],
  ["pages_with_duplicate_titles_excl_duplicate_pages", []],

  // Accessibility Issue Reports
  ["accessibility_issue_instances", [getAccessibilityIssuesAndInstancesTrend]],
  ["accessibility_issues", [getAccessibilityIssuesAndInstancesTrend]],
  ["autocomplete_valid_issues", [getAccessibilityFormsChart]],
  ["form_field_multiple_labels_issues", [getAccessibilityFormsChart]],
  ["select_name_issues", [getAccessibilityFormsChart]],
  ["label_issues", [getAccessibilityFormsChart]],
  ["valid_lang_issues", [getAccessibilityLanguageChart]],
  ["html_xml_lang_mismatch_issues", [getAccessibilityLanguageChart]],
  ["html_lang_valid_issues", [getAccessibilityLanguageChart]],
  ["html_has_lang_issues", [getAccessibilityLanguageChart]],
  ["wcag_a_issues", [getAccessibilityLevelAChart]],
  ["wcag_aa_issues", [getAccessibilityLevelAAChart]],
  ["wcag_aaa_issues", [getAccessibilityLevelAAAChart]],
  ["best_practices_issues", [getAccessibilityLevelsChart]],
  ["wcag_a_issue_instances", [getAccessibilityIssuesandInstancesATrend]],
  ["wcag_aa_issue_instances", [getAccessibilityIssuesandInstancesAATrend]],
  ["wcag_aaa_issue_instances", [getAccessibilityIssuesandInstancesAAATrend]],
  [
    "best_practices_issue_instances",
    [getAccessibilityIssuesandInstancesBPTrend],
  ],
  ["critical_issues", [getAccessibilitySeverityChart]],
  ["serious_issues", [getAccessibilitySeverityChart]],
  ["moderate_issues", [getAccessibilitySeverityChart]],
  ["minor_issues", [getAccessibilitySeverityChart]],
  ["area_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["aria_allowed_attr_issues", [getAccessibilityAriaAChart]],
  ["aria_command_name_issues", [getAccessibilityAriaAChart]],
  ["aria_hidden_body_issues", [getAccessibilityAriaAChart]],
  ["aria_hidden_focus_issues", [getAccessibilityNameRoleValueAChart]],
  ["aria_input_field_name_issues", [getAccessibilityAriaAChart]],
  ["aria_meter_name_issues", [getAccessibilityAriaAChart]],
  ["aria_progressbar_name_issues", [getAccessibilityAriaAChart]],
  ["aria_required_attr_issues", [getAccessibilityAriaAChart]],
  ["aria_required_children_issues", [getAccessibilityAriaAChart]],
  ["aria_required_parent_issues", [getAccessibilityAriaAChart]],
  ["aria_roledescription_issues", [getAccessibilityAriaAChart]],
  ["aria_roles_issues", [getAccessibilityAriaAChart]],
  ["aria_toggle_field_name_issues", [getAccessibilityAriaAChart]],
  ["aria_tooltip_name_issues", [getAccessibilityAriaAChart]],
  ["aria_valid_attr_value_issues", [getAccessibilityAriaAChart]],
  ["aria_valid_attr_issues", [getAccessibilityAriaAChart]],
  ["audio_caption_issues", [getAccessibilityTimeAndMediaAChart]],
  ["blink_issues", [getAccessibilityTimeAndMediaAChart]],
  ["button_name_issues", [getAccessibilityNameRoleValueAChart]],
  ["bypass_issues", [getAccessibilityKeyboardAChart]],
  ["color_contrast_issues", [getAccessibilityColorAAChart]],
  [
    "definition_list_issues",
    [getAccessibilityStructureChart, getAccessibilityStructureAChart],
  ],
  [
    "dlitem_issues",
    [getAccessibilityStructureChart, getAccessibilityStructureAChart],
  ],
  ["document_title_issues", [getAccessibilityTextAlternativesAChart]],
  ["duplicate_id_active_issues", [getAccessibilityParsingAChart]],
  ["duplicate_id_aria_issues", [getAccessibilityParsingAChart]],
  ["duplicate_id_issues", [getAccessibilityParsingAChart]],
  ["form_field_multiple_labels_issues", [getAccessibilityFormsAChart]],
  ["frame_focusable_content_issues", [getAccessibilityKeyboardAChart]],
  ["frame_title_issues", [getAccessibilityTextAlternativesAChart]],
  ["html_has_lang_issues", [getAccessibilityLanguageAChart]],
  ["html_lang_valid_issues", [getAccessibilityLanguageAChart]],
  ["html_xml_lang_mismatch_issues", [getAccessibilityLanguageAChart]],
  ["image_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["input_button_name_issues", [getAccessibilityNameRoleValueAChart]],
  ["input_image_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["label_issues", [getAccessibilityFormsAChart]],
  ["link_name_issues", [getAccessibilityNameRoleValueAChart]],
  [
    "list_issues",
    [getAccessibilityStructureChart, getAccessibilityStructureAChart],
  ],
  [
    "listitem_issues",
    [getAccessibilityStructureChart, getAccessibilityStructureAChart],
  ],
  ["marquee_issues", [getAccessibilityParsingAChart]],
  ["meta_refresh_issues", [getAccessibilityTimeAndMediaAChart]],
  ["nested_interactive_issues", [getAccessibilityKeyboardAChart]],
  ["object_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["role_img_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["scrollable_region_focusable_issues", [getAccessibilityKeyboardAChart]],
  ["select_name_issues", [getAccessibilityFormsAChart]],
  ["server_side_image_map_issues", [getAccessibilityTextAlternativesAChart]],
  ["svg_img_alt_issues", [getAccessibilityTextAlternativesAChart]],
  ["td_headers_attr_issues", [getAccessibilityTablesAChart]],
  ["th_has_data_cells_issues", [getAccessibilityTablesAChart]],
  ["valid_lang_issues", [getAccessibilityLanguageAAChart]],
  ["video_caption_issues", [getAccessibilityTextAlternativesAChart]],
  ["empty_table_header_issues", [getAccessibilityAriaBPChart]],
  ["accesskeys_issues", [getAccessibilityKeyboardBPChart]],
  ["aria_allowed_role_issues", [getAccessibilityAriaBPChart]],
  ["aria_dialog_name_issues", [getAccessibilityAriaBPChart]],
  ["aria_text_issues", [getAccessibilityAriaBPChart]],
  ["aria_treeitem_name_issues", [getAccessibilityAriaBPChart]],
  ["empty_heading_issues", [getAccessibilityNameRoleValueBPChart]],
  ["frame_tested_issues", [getAccessibilityStructureBPChart]],
  ["frame_title_unique_issues", [getAccessibilityTextAlternativesBPChart]],
  ["heading_order_issues", [getAccessibilitySemanticsBPChart]],
  ["image_redundant_alt_issues", [getAccessibilityTextAlternativesBPChart]],
  ["label_title_only_issues", [getAccessibilityFormsBPChart]],
  ["landmark_banner_is_top_level_issues", [getAccessibilitySemanticsBPChart]],
  [
    "landmark_complementary_is_top_level_issues",
    [getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_contentinfo_is_top_level_issues",
    [getAccessibilitySemanticsBPChart],
  ],
  ["landmark_main_is_top_level_issues", [getAccessibilitySemanticsBPChart]],
  ["landmark_no_duplicate_banner_issues", [getAccessibilitySemanticsBPChart]],
  [
    "landmark_no_duplicate_contentinfo_issues",
    [getAccessibilitySemanticsBPChart],
  ],
  ["landmark_no_duplicate_main_issues", [getAccessibilitySemanticsBPChart]],
  ["landmark_one_main_issues", [getAccessibilitySemanticsBPChart]],
  ["landmark_unique_issues", [getAccessibilitySemanticsBPChart]],
  ["meta_viewport_large_issues", [getAccessibilitySensoryAndVisualBPChart]],
  ["meta_viewport_issues", [getAccessibilitySensoryAndVisualAAChart]],
  ["target_size_issues", [getAccessibilitySensoryAndVisualAAChart]],
  ["page_has_heading_one_issues", [getAccessibilitySemanticsBPChart]],
  ["presentation_role_conflict_issues", [getAccessibilityAriaBPChart]],
  ["region_issues", [getAccessibilityKeyboardBPChart]],
  ["scope_attr_valid_issues", [getAccessibilityTablesBPChart]],
  ["skip_link_issues", [getAccessibilityKeyboardBPChart]],
  ["tabindex_issues", [getAccessibilityKeyboardBPChart]],
  ["table_duplicate_name_issues", [getAccessibilityTablesBPChart]],
  ["css_orientation_lock_issues", [getAccessibilityStructureBPChart]],
  ["focus_order_semantics_issues", [getAccessibilityKeyboardBPChart]],
  ["hidden_content_issues", [getAccessibilityStructureBPChart]],
  ["link_in_text_block_issues", [getAccessibilityColorAChart]],
  ["no_autoplay_audio_issues", [getAccessibilityTimeAndMediaAChart]],
  ["p_as_heading_issues", [getAccessibilitySemanticsAChart]],
  ["table_fake_caption_issues", [getAccessibilityTablesAChart]],
  ["td_has_header_issues", [getAccessibilityTablesAChart]],
  ["color_contrast_issues", [getAccessibilityLevelAAChart]],
  ["valid_lang_issues", [getAccessibilityLevelAAChart]],
  ["autocomplete_valid_issues", [getAccessibilityLevelAAChart]],
  ["avoid_inline_spacing_issues", [getAccessibilityLevelAAChart]],
  ["color_contrast_enhanced_issues", [getAccessibilityLevelAAAChart]],
  [
    "identical_links_same_purpose_issues",
    [getAccessibilityLevelAAAChart, getAccessibilitySemanticsChart],
  ],
  [
    "color_contrast_issues",
    [getAccessibilityColorChart, getAccessibilityLevelAAChart],
  ],
  [
    "color_contrast_enhanced_issues",
    [getAccessibilityColorChart, getAccessibilityLevelAAAChart],
  ],
  ["link_in_text_block_issues", [getAccessibilityColorChart]],
  [
    "identical_links_same_purpose_issues",
    [getAccessibilityLevelAAAChart, getAccessibilitySemanticsChart],
  ],
  ["label_content_name_mismatch_issues", [getAccessibilitySemanticsChart]],
  ["p_as_heading_issues", [getAccessibilitySemanticsChart]],

  // <category)
  ["aria_a", [getAccessibilityAriaAChart]],
  ["forms_a", [getAccessibilityFormsAChart]],
  ["keyboard_a", [getAccessibilityKeyboardAChart]],
  ["language_a", [getAccessibilityLanguageAChart]],
  ["name_role_value_a", [getAccessibilityNameRoleValueAChart]],
  ["parsing_a", [getAccessibilityParsingAChart]],
  ["structure_a", [getAccessibilityStructureAChart]],
  ["tables_a", [getAccessibilityTablesAChart]],
  ["text_alternatives_a", [getAccessibilityTextAlternativesAChart]],
  ["time_and_media_a", [getAccessibilityTimeAndMediaAChart]],
  ["semantics_a", [getAccessibilitySemanticsAChart]],
  ["color", [getAccessibilityColorChart]],
  ["forms", [getAccessibilityFormsChart]],
  ["language", [getAccessibilityLanguageChart]],
  ["structure", [getAccessibilityStructureChart]],
  ["semantics", [getAccessibilitySemanticsChart]],

  // SITE SPEED
  ["cumulative_layout_shift_slow", [getSiteSpeedIssuesCLS]],
  ["cumulative_layout_shift_moderate", [getSiteSpeedIssuesCLS]],
  ["total_blocking_time_slow", [getSiteSpeedIssuesTBT]],
  ["total_blocking_time_moderate", [getSiteSpeedIssuesTBT]],
  ["first_contentful_paint_slow", [getSiteSpeedIssuesFCP]],
  ["first_contentful_paint_moderate", [getSiteSpeedIssuesFCP]],
  ["largest_contentful_paint_slow", [getSiteSpeedIssuesLCP]],
  ["largest_contentful_paint_moderate", [getSiteSpeedIssuesLCP]],
  ["layout_shift_elements_failed_audits", [getSiteSpeedCLSLayoutShiftElements]],
  ["layout_shift_elements_failed_items", [getSiteSpeedCLSLayoutShiftElements]],
  [
    "mainthread_script_evaluation_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_other_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_style_layout_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_script_parsing_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_parse_html_css_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_rendering_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_garbage_collection_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "crux_domain_summary",
    [
      getSiteSpeedAvgCruxFCPChart,
      getSiteSpeedAvgCruxLCPChart,
      getSiteSpeedAvgCruxITNPChart,
      getSiteSpeedAvgCruxCLSChart,
    ],
  ],
  // GA Conversions
  ["primary_pages_with_visits_and_conversions", [getConversionPagesChart]],
  ["primary_pages_with_visits_without_conversions", [getConversionPagesChart]],
]);
