import { Grid, makeStyles } from "@material-ui/core";
import { ChartPanel } from "../../_common/charts/components/chart-components/ChartPanel";
import { useReportCategoryChartConfig } from "./config/useReportCategoryChartConfig";
import { useSession } from "@lumar/shared";
import { ChartConfigItem } from "../../_common/charts/types/ChartConfig";
import { CurrentCategoryHealthScoreSection } from "./data-visualization/charts/health-score/CurrentCategoryHealthScoreSection";
import { TopChangesChart } from "./data-visualization/charts/TopChangesChart";
import { URLFunnel } from "./data-visualization/charts/url-funnel/URLFunnel";
import { TopErrorsChartPanel } from "./data-visualization/charts/TopErrorsChart";
import { useCrawlOverviewContext } from "../CrawlOverviewContext";
import { ChartData } from "../../_common/charts/components/chart-components/ChartData";
import { useChartDataContext } from "../../_common/charts/components/chart-components/ChartDataContext";
import {
  ChartComponent,
  ChartComponentProps,
} from "../../_common/charts/components/chart-components/ChartComponent";
import { ChartSkeleton } from "../../_common/charts/components/chart-components/ChartSkeleton";
import { ModuleCode } from "../../graphql";
import { SiteSpeedSmallCharts } from "./data-visualization/charts/site-speed/SiteSpeedSmallCharts";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "7px",
  },
  tileContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    marginTop: "16px",
    width: "100%",
    justifyContent: "center",
  },
  tile: {
    display: "block",
    background: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    flexGrow: 1,
    flexBasis: 0,
    color: "inherit",
    padding: 8,
    height: 101,
    minWidth: 210,
    [theme.breakpoints.down("md")]: {
      minWidth: 292,
      maxWidth: "100%",
    },
  },
}));

export function Dashboard(): JSX.Element | null {
  const { data } = useCrawlOverviewContext();
  const classes = useStyles();
  const session = useSession();
  const { mainCharts, includeTopChanges } = useReportCategoryChartConfig();

  const isSiteSpeedOverview =
    data?.moduleCode === ModuleCode.SiteSpeed &&
    data?.selectedCategory?.code === "site_speed";

  const isSeoOverview =
    data?.moduleCode === ModuleCode.Seo &&
    data?.selectedCategory?.code === "top";

  const isHealthScoreCategory =
    session.hasAddon("health-scores") && data?.selectedCategory?.hasHealthScore;

  return (
    <div style={{ marginTop: 14 }}>
      {isHealthScoreCategory ? <CurrentCategoryHealthScoreSection /> : null}
      {isSiteSpeedOverview ? <SiteSpeedSmallCharts /> : null}
      {isSeoOverview ? <URLFunnel /> : null}
      <Grid container spacing={2} className={classes.root}>
        {includeTopChanges ? (
          <Grid key="top-changes" item xs={12} lg={6}>
            <TopChangesChart />
          </Grid>
        ) : null}
        {!isHealthScoreCategory ? (
          <Grid item xs={12} lg={6}>
            <TopErrorsChartPanel />
          </Grid>
        ) : null}
        {mainCharts.map((chartConfigItem) => {
          const key = getKey(chartConfigItem);
          return (
            <ChartData key={key} {...chartConfigItem}>
              <ChartRender {...chartConfigItem} />
            </ChartData>
          );
        })}
      </Grid>
    </div>
  );
}

function ChartRender(props: ChartComponentProps): JSX.Element {
  const { reportStats, loading } = useChartDataContext();

  if (
    (props.hideChartWithoutData && !reportStats.length) ||
    (props.hideChartWithoutData && loading)
  ) {
    return <></>;
  }

  return (
    <Grid item xs={12} lg={6}>
      <ChartPanel>
        {loading ? <ChartSkeleton /> : <ChartComponent {...props} />}
      </ChartPanel>
    </Grid>
  );
}

function getKey(config: ChartConfigItem): string {
  return config.title([]) + config.visualisationType + (config.icon ?? "");
}
