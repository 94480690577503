import {
  ChipColor,
  ArrowNarrowDownSolid,
  ArrowNarrowUpSolid,
  Chip,
  Trans,
  useNumberFormatter,
  useTranslation,
  SparklineTableChart,
  SparklineTableChartDatum,
  ChartContainer,
  SparklineTableChartProps,
} from "@lumar/shared";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography, useTheme } from "@material-ui/core";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Routes } from "../../../../_common/routing/routes";
import {
  CrawlContextValue,
  useCrawlContextHelpers,
} from "../../../CrawlContext";
import { ChartPanelSuccessMessage } from "../../../../_common/charts/components/chart-messages/ChartPanelSuccessMessage";
import { mapCrawlReportTrendToSparklineChartSeries } from "./helpers";
import { useCrawlOverviewContextData } from "../../../CrawlOverviewContext";
import { ChartPanelButton } from "../../../../_common/charts/components/chart-components/ChartPanelButton";
import { useSearchParam } from "../../../../_common/routing/useSearchParam";
import { useSelectedSegment } from "../../../../segment-selector/useSelectedSegment";

export function TopChangesChart(): JSX.Element {
  const { t } = useTranslation("charts");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const [segment] = useSelectedSegment();

  return (
    <ChartContainer
      title={t("changes.title")}
      description={() => (
        <Trans ns="charts" i18nKey="changes.description">
          <Typography paragraph variant="inherit">
            This list shows the greatest changes in reports compared to the
            previous crawl, and should be used to identify potential problems or
            quantify planned changes to a website. The order is a suggestion
            based on the number of items in the report and weight of the report
          </Typography>
          <Typography variant="inherit">
            Please ensure that in{" "}
            <Link
              color="secondary"
              component={RouterLink}
              to={Routes.Crawls.getUrl({
                accountId,
                projectId,
                tab: "edit",
                step: 4,
              })}
            >
              Step 4 of project settings
            </Link>{" "}
            you have selected a crawl to compare changes against.
          </Typography>
        </Trans>
      )}
      header={<TopChangesButton />}
      subtitle={segment?.name}
      data-testid="top-changes-chart"
    >
      <TopChangesChartInner />
    </ChartContainer>
  );
}

function TopChangesChartInner(): JSX.Element {
  const { t } = useTranslation("charts");
  const helpers = useCrawlContextHelpers();
  const { selectedCategory } = useCrawlOverviewContextData();
  const changedReports = helpers.getCrawlReportCategoryChangedReportsList(
    selectedCategory.code,
  );
  const sparklineTableChartData = useMapTopChangesToSparklineTableChartData(
    helpers.getCrawlReportCategoryChangedReportsList(selectedCategory.code),
  );

  if (changedReports.length === 0) {
    return (
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <ChartPanelSuccessMessage>
          {t("changes.noChangesFound")}
        </ChartPanelSuccessMessage>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <SparklineTableChart
        {...sparklineTableChartData}
        getMinText={(min) => t("min", { value: min })}
        getMaxText={(max) => t("max", { value: max })}
        linkProps={(datum, index) =>
          index === 0 ? { "data-pendo": "top-health-score-change" } : {}
        }
      />
    </div>
  );
}

function TopChangesButton(): JSX.Element | null {
  const { t } = useTranslation("charts");
  const helpers = useCrawlContextHelpers();
  const segmentId = useSearchParam("segmentId");
  const { crawlId, projectId, accountId } = useParams<{
    crawlId: string;
    accountId: string;
    projectId: string;
  }>();
  const { selectedCategory } = useCrawlOverviewContextData();
  const changedReports = helpers.getCrawlReportCategoryChangedReportsList(
    selectedCategory.code,
  );
  if (changedReports.length === 0) {
    return null;
  }

  return (
    <ChartPanelButton
      link={Routes.CrawlOverview.getUrl({
        accountId,
        crawlId,
        projectId,
        category: selectedCategory.code,
        segmentId,
        type: "all",
        sorting: [{ field: "weightedChange", sort: "desc" }],
      })}
      label={t("changes.viewAll", { count: changedReports.length })}
    />
  );
}

function useMapTopChangesToSparklineTableChartData(
  reports: Required<CrawlContextValue>["data"]["crawlReports"],
): {
  data: SparklineTableChartDatum[];
  columns: SparklineTableChartProps["columns"];
} {
  const segmentId = useSearchParam("segmentId");
  const { crawlId, projectId, accountId } = useParams<{
    crawlId: string;
    accountId: string;
    projectId: string;
  }>();
  return {
    data: reports.slice(0, 6).map((report) => {
      return {
        id: report.reportTemplateCode,
        href: Routes.Report.getUrl({
          accountId,
          crawlId,
          projectId,
          reportTemplateCode: report.reportTemplateCode,
          reportTypeCode: "basic",
          segmentId,
        }),
        label: report.reportTemplateName,
        values: {
          rowsChange: (
            <ChangedReportRowsChange
              key="rows-change"
              change={report.change || 0}
              totalSign={report.reportTemplateTotalSign || 0}
            />
          ),
          percentageChange: (
            <ChangedReportPercentageChange
              key="percentage-change"
              totalRows={report.basic || 0}
              change={report.change || 0}
              totalSign={report.reportTemplateTotalSign || 0}
            />
          ),
        },
        series: mapCrawlReportTrendToSparklineChartSeries(report.trend),
      };
    }),
    columns: [
      "name",
      { field: "rowsChange", columnWidth: "minmax(min-content, 1fr)" },
      { field: "percentageChange", columnWidth: "minmax(min-content, 1fr)" },
      "trend",
    ],
  };
}

function ChangedReportRowsChange(props: {
  change: number;
  totalSign: number;
}): JSX.Element {
  const theme = useTheme();
  const formatNumber = useNumberFormatter();

  function getChangeColor(): string {
    const changeSign = props.totalSign * props.change;

    if (changeSign > 0) {
      return theme.palette.green[600];
    }

    if (changeSign < 0) {
      return theme.palette.red[600];
    }

    return theme.palette.grey[600];
  }

  return (
    <span
      style={{
        color: getChangeColor(),
        width: "34px",
        textAlign: "right",
        display: "inline-block",
        fontWeight: 600,
      }}
    >
      {formatNumber(props.change, {
        notation: "compact",
        signDisplay: "always",
      })}
    </span>
  );
}

function ChangedReportPercentageChange(props: {
  totalRows: number;
  change: number;
  totalSign: number;
}): JSX.Element {
  const formatNumber = useNumberFormatter();
  const percentageChange = Math.abs(
    props.totalRows / (props.totalRows - props.change) - 1,
  );

  return (
    <Chip
      {...useChangeReportChipProps(props.change, props.totalSign)}
      style={{ minWidth: "86px", alignSelf: "start" }}
      label={formatNumber(percentageChange, {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    ></Chip>
  );
}

function useChangeReportChipProps(
  change: number,
  totalSign: number,
): { icon?: ReactElement; color: ChipColor } {
  const theme = useTheme();
  const changeSign = totalSign * change;

  if (changeSign > 0) {
    return {
      color: "green",
      icon:
        change < 0 ? (
          <ArrowNarrowDownSolid
            fontSize="inherit"
            style={{ color: theme.palette.green[800] }}
          />
        ) : change > 0 ? (
          <ArrowNarrowUpSolid
            fontSize="inherit"
            style={{ color: theme.palette.green[800] }}
          />
        ) : undefined,
    };
  }

  if (changeSign < 0) {
    return {
      color: "red",
      icon:
        change < 0 ? (
          <ArrowNarrowDownSolid
            fontSize="inherit"
            style={{ color: theme.palette.red[800] }}
          />
        ) : change > 0 ? (
          <ArrowNarrowUpSolid
            fontSize="inherit"
            style={{ color: theme.palette.red[800] }}
          />
        ) : undefined,
    };
  }

  return {
    color: "lightgrey",
    icon:
      change < 0 ? (
        <ArrowNarrowDownSolid fontSize="inherit" />
      ) : change > 0 ? (
        <ArrowNarrowUpSolid fontSize="inherit" />
      ) : undefined,
  };
}
