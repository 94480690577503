/* eslint-disable fp/no-this */
import React from "react";
import { Typography, useDateFormatter, useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { useTheme, alpha, Theme } from "@material-ui/core";
import { Point } from "highcharts";
import { sortBy } from "lodash";
import { renderToString } from "react-dom/server";
import { useParams, useHistory } from "react-router";
import { ChartDataMetric } from "../../../../../_common/charts/components/chart-components/ChartDataContext";
import {
  getAggregatedMetric,
  getReportUnit,
} from "../../../../../_common/charts/components/chart-components/chartDataHelpers";
import { HighchartsChart } from "../../../../../_common/charts/components/HighchartsChart";
import { isPointWithUrl } from "../../../../../_common/charts/multi-dimensional-series-chart/utils";
import { useTooltipStyles } from "../../../../../_common/charts/utils/useTooltipStyles";
import { useReportTemplateUnitFormatter } from "../../../../../_common/locale/format-api-enum/useReportTemplateUnitFormatter";
import { Routes } from "../../../../../_common/routing/routes";
import {
  CrawlContextProjectModule,
  useCrawlContextData,
  CrawlContextCrawlSetting,
  CrawlContextCrawlReportStat,
} from "../../../../CrawlContext";

interface Props {
  noDataMessage?: string;
  title: string;
  module?: CrawlContextProjectModule;
  report?: {
    reportTemplate: { code: string };
    trend: {
      crawlId: number;
      basic?: number | null;
      createdAt?: string | null;
    }[];
    metadata?: {
      unit?: string | null;
    };
  };
}

export function SiteSpeedSmallColumnChart(props: Props): JSX.Element {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const { crawlSetting } = useCrawlContextData();
  const tooltipClasses = useTooltipStyles();
  const theme = useTheme();
  const formatUnit = useReportTemplateUnitFormatter();
  const { t } = useTranslation("charts");
  const history = useHistory();

  const formatDate = useDateFormatter();

  const series = sortBy(props.report?.trend ?? [], (x) =>
    x.createdAt ? new Date(x.createdAt).getTime() : 0,
  );

  const metric = getAggregatedMetric(
    props.report as CrawlContextCrawlReportStat | undefined,
    props.module,
  );
  const metricName = getMetricName(t, metric);
  const thresholds = getThresholds({ metric, crawlSetting, theme });
  const values = series.map((x) => x.basic);
  const max = getMaxValue(values, thresholds);

  const missingDataPattern = {
    color: theme.palette.grey[600],
    path: { d: "M 10 0 L 0 10 M 1 -1 L -1 1 M 11 9 L 9 11", strokeWidth: 0.6 },
    width: 10,
    height: 10,
  };

  const backgroundsRef = React.useRef<Highcharts.SVGElement[]>([]);

  const options: Highcharts.Options = {
    chart: {
      spacing: [5, 0, 8, 0],
      events: {
        render: function (): void {
          drawBackground(this, backgroundsRef);
        },
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      max,
      endOnTick: false,
    },
    series: [
      {
        cursor: "pointer",
        point: {
          events: {
            click: function (event) {
              const point = (event.point ?? event.target) as Highcharts.Point;
              if (isPointWithUrl(point) && point.url) {
                const { url } = point;
                if (event.ctrlKey || event.metaKey) {
                  window.open(url, "_blank", "noreferrer");
                } else {
                  history.push(url);
                }
              }
            },
          },
        },
        type: "column",
        data: series.map((value, idx) => ({
          x: idx,
          y: value.basic ?? max,
          createdAt: value.createdAt,
          url: Routes.Report.getUrl({
            accountId,
            projectId,
            crawlId: String(value.crawlId),
            reportTemplateCode: props.report?.reportTemplate.code ?? "",
            reportTypeCode: "basic",
          }),
          isDataMissing: value.basic === null || value.basic === undefined,
          ...(value.basic === null || value.basic === undefined
            ? {
                color: {
                  pattern: {
                    backgroundColor: theme.palette.grey[300],
                    ...missingDataPattern,
                  },
                },
                states: {
                  hover: {
                    color: {
                      pattern: {
                        backgroundColor: alpha(theme.palette.primary.main, 0.4),
                        ...missingDataPattern,
                      },
                    },
                  },
                },
              }
            : { color: getThresholdColor(theme, value.basic, thresholds) }),
        })),
        accessibility: {
          point: {
            descriptionFormatter: (point) => {
              if (getIsDataMissing(point)) {
                return t("smallColumnChartPointAnnouncement", {
                  date: formatDate(new Date(getCreatedAt(point) || ""), {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                  value: t("noCrawlData"),
                  metric: "",
                });
              }

              return t("smallColumnChartPointAnnouncement", {
                date: formatDate(new Date(getCreatedAt(point) || ""), {
                  dateStyle: "medium",
                  timeStyle: "short",
                }),
                value: formatUnit(
                  point.y ?? 0,
                  getReportUnit(
                    props.report as CrawlContextCrawlReportStat | undefined,
                    props.module,
                  ),
                ),
                metric: metricName,
              });
            },
          },
        },
      },
    ],
    title: {
      text: props.title,
      floating: true,
      style: {
        opacity: 0,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointPadding: 0.05,
        borderWidth: 0,
        borderRadius: 0,
      },
    },
    tooltip: {
      useHTML: true,
      outside: true,
      shape: "rect",
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      shared: true,
      hideDelay: 10,
      style: {
        fontSize: "1em",
        lineHeight: "1.5em",
        color: theme.palette.text.primary,
        // Making sure that sidebar next to the chart
        // doesn't cut the tooltip off.
        zIndex: theme.zIndex.drawer + 1,
      },
      formatter: function () {
        const value = this.y ?? 0;
        const createdAt = getCreatedAt(this);
        const isDataMissing = getIsDataMissing(this);

        if (isDataMissing) {
          return renderToString(
            <div className={tooltipClasses.container}>
              <div
                className={tooltipClasses.textContainer}
                style={{
                  width: 200,
                  whiteSpace: "pre-line",
                  textAlign: "center",
                }}
              >
                {t("noCrawlData")}
              </div>
            </div>,
          );
        }

        return renderToString(
          <div className={tooltipClasses.container}>
            <div className={tooltipClasses.textContainer}>
              <div
                className={tooltipClasses.urlCountText}
                style={{ color: getThresholdColor(theme, value, thresholds) }}
              >
                {formatUnit(
                  value,
                  getReportUnit(
                    props.report as CrawlContextCrawlReportStat | undefined,
                    props.module,
                  ),
                )}
              </div>
              {createdAt && (
                <div>
                  {formatDate(createdAt, {
                    dateStyle: "long",
                    timeStyle: "short",
                  })}
                </div>
              )}
              <div>{metricName}</div>
            </div>
          </div>,
        );
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };

  return (
    <div style={{ height: 55 }}>
      {!series.length && props.noDataMessage ? (
        <div style={{ height: "100%", padding: theme.spacing(0.625, 0) }}>
          <Typography
            variant="subtitle4"
            style={{
              background: theme.palette.yellow[50],
              padding: theme.spacing(0.5, 1),
              borderRadius: theme.spacing(1),
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              maxHeight: "100%",
            }}
          >
            {props.noDataMessage}
          </Typography>
        </div>
      ) : (
        <HighchartsChart options={options} />
      )}
    </div>
  );
}

export function getThresholds({
  metric,
  crawlSetting,
  theme,
}: {
  metric: ChartDataMetric | undefined;
  crawlSetting: CrawlContextCrawlSetting | undefined;
  theme: Theme;
}): { value: number; color: string }[] | undefined {
  const siteSpeedScoring = metric?.metadata?.siteSpeedScoring;
  if (!siteSpeedScoring) return;

  const isMobile = Boolean(
    crawlSetting?.userAgentString
      ? crawlSetting?.userAgentIsMobile
      : crawlSetting?.userAgent?.isMobile,
  );

  return metric?.metadata?.siteSpeedScoring
    ? [
        { value: 0, color: theme.palette.green[500] },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.p10
            : siteSpeedScoring.desktop.p10,
          color: theme.palette.yellow[300],
        },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.median
            : siteSpeedScoring.desktop.median,
          color: theme.palette.red[500],
        },
      ]
    : undefined;
}

function getCreatedAt(point: Point): Date | undefined {
  const createdAt = (point as unknown as Record<string, unknown>)?.createdAt;
  if (typeof createdAt !== "string") return;

  const result = new Date(createdAt);
  return isNaN(result.getTime()) ? undefined : result;
}

function getIsDataMissing(point: Point): boolean {
  const isDataMissing = (point as unknown as Record<string, unknown>)
    ?.isDataMissing;
  return isDataMissing === true;
}

function drawBackground(
  chart: Highcharts.Chart,
  backgroundsRef: React.MutableRefObject<Highcharts.SVGElement[]>,
): void {
  backgroundsRef.current.forEach((x) => x.destroy());

  backgroundsRef.current = chart.series.flatMap((series) =>
    series.points.map((point) =>
      chart.renderer
        .rect(
          point.shapeArgs?.x,
          chart.plotTop,
          point.shapeArgs?.width,
          point.shapeArgs?.y,
        )
        .attr({ fill: "#EBEFF3" })
        .add(),
    ),
  );
}

function getMaxValue(
  values: (number | null | undefined)[],
  thresholds: ReturnType<typeof getThresholds>,
): number {
  const maxValue = Math.max(...values.map((x) => x ?? 0));
  const max = thresholds?.find((x) => x.value > maxValue)?.value ?? maxValue;

  return max === 0 ? 1 : max;
}

function getMetricName(
  t: TFunction<"charts">,
  metric?: { aggregate: string; name: string },
): string | undefined {
  switch (metric?.aggregate) {
    case "$avg":
      return t("aggregates.avg", { metricName: metric.name });
    case "$min":
      return t("aggregates.min", { metricName: metric.name });
    case "$max":
      return t("aggregates.max", { metricName: metric.name });
    case "$sum":
      return t("aggregates.sum", { metricName: metric.name });
  }
  return metric?.name;
}

function getThresholdColor(
  theme: Theme,
  value: number | null | undefined,
  thresholds?: { value: number; color: string }[],
): string | undefined {
  if (!thresholds?.length) return;

  if (value === null || value === undefined) {
    return theme.palette.grey[400];
  }

  const idx = thresholds.reduce(
    (result, treshold, idx) => (value > treshold.value ? idx : result),
    0,
  );
  return thresholds[idx].color;
}
