import { useParams } from "react-router-dom";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ResourceDetailData } from "../../../data/types";
import { ReportEntity } from "../../../../report/Report.types";

export interface SiteSpeedAuditsProps {
  data: ResourceDetailData;
  auditCodes?: string[];
}

export function SiteSpeedAudits({
  data,
  auditCodes,
}: SiteSpeedAuditsProps): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();
  const urlDigest = data.metrics["urlDigest"].value;

  return (
    <ReportGrid
      reportInput={{
        crawlId,
        reportEntity: ReportEntity.ReportStat,
        reportTemplateCode: "crawl_site_speed_audits",
        reportTypeCode: ReportTypeCode.Basic,
      }}
      baseFilter={{
        urlDigest: { eq: urlDigest },
        ...(auditCodes ? { auditId: { in: auditCodes } } : {}),
      }}
      tabsMode="onlyTotalRowsVisible"
      hideTaskMenu
      hideCreateCustomReport
    />
  );
}
