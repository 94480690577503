export function getReportsWithAlignedTrends<
  TrendItemType extends { basic?: number | null },
  ReportType extends { trend: TrendItemType[]; reportTemplateCode: string },
>(reports: ReportType[]): ReportType[] {
  const referenceLongestTrend =
    reports
      ?.toSorted((a, b) => b.trend.length - a.trend.length)[0]
      ?.trend.map((item) => ({ ...item, basic: null })) ?? [];

  return reports.map((report) => {
    const isTrendAligned = report.trend.length === referenceLongestTrend.length;

    if (isTrendAligned) {
      return report;
    }

    const alignedTrend = referenceLongestTrend.map((item, index) => {
      if (report.trend[index]) {
        return report.trend[index];
      }
      return item;
    });

    return {
      ...report,
      trend: alignedTrend,
    };
  });
}
