import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { validate as validateRegex } from "@deepcrawl/lucene-regex-validator";
import { uniqBy } from "lodash";
import { TFunction } from "i18next";

import { REGEX_PATTERNS } from "../../../../_common/constants/regex-patterns.constant";
import { ContextValues, CookieSettings } from "./types";

export const CUSTOM_REJECTION_MAX = 100;
export const SCRIPT_RESOURCE_MAX = 10;
export const CUSTOM_REQUEST_HEADER_MAX = 30;
export const CUSTOM_EXTRACTION_MAX = 30;
const DUPLICATE_PRECISION_MAX = 10;
const REDIRECTIONS_MAX = 10;

export function useSettingsValidationSchema(
  contextValues: ContextValues,
): unknown {
  const { t } = useTranslation("crawlSettings");

  const required = t("settings.validationError.required");
  const url = t("settings.validationError.url");
  const urlParameters = t("settings.validationError.urlParameter");
  const emails = t("settings.validationError.email");
  const regex = t("settings.validationError.regex");
  const ip = t("settings.validationError.ip");
  const number = t("settings.validationError.number");
  const nonNegative = t("settings.validationError.minNumberError", { min: 0 });
  const positiveNumber = t("settings.validationError.positiveNumber");
  const maxDuplicatePrecisionsError = t(
    "settings.validationError.maxNumberError",
    {
      max: DUPLICATE_PRECISION_MAX,
    },
  );
  const maxRedirectionsError = t("settings.validationError.maxNumberError", {
    max: REDIRECTIONS_MAX,
  });
  const maxRequestHeader = t("settings.validationError.uniqueKey", {
    max: CUSTOM_REQUEST_HEADER_MAX,
  });

  const renderTimeoutError = t(
    "settings.validationError.maximumRenderTimeout",
    { max: contextValues.maximumRenderTimeout },
  );

  const maxSafeguardThresholdError = t(
    "settings.validationError.maxNumberError",
    {
      max: 100,
    },
  );
  const minSafeguardThresholdError = t(
    "settings.validationError.minNumberError",
    {
      min: 0,
    },
  );

  return Yup.object().shape({
    scope: Yup.object().shape({
      urlScope: Yup.object().shape({
        groups: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(required),
            urlMatch: Yup.string().required(required),
          }),
        ),
      }),
    }),
    spider: Yup.object().shape({
      crawlSafeguard: Yup.object().shape({
        enabled: Yup.boolean().required(required),
        threshold: Yup.number()
          .min(0, minSafeguardThresholdError)
          .max(100, maxSafeguardThresholdError)
          .required(required),
      }),
      scriptRendering: Yup.object().shape({
        renderTimeout: Yup.number()
          .min(1, renderTimeoutError)
          .max(contextValues.maximumRenderTimeout, renderTimeoutError)
          .required(required),
        externalResources: Yup.array().of(Yup.string().required(required)),
      }),
      mobileSite: Yup.object().shape({
        homePage: Yup.string().matches(REGEX_PATTERNS.url, url),
        pattern: Yup.string().test(
          "Regex test",
          regex,
          (value) => !value || validateRegex(value).valid,
        ),
        customFull: Yup.string().when("agent", {
          is: (agent: string) => agent === "Custom",
          then: (schema) => schema.required(required),
        }),
        customShort: Yup.string().when("agent", {
          is: (agent: string) => agent === "Custom",
          then: (schema) => schema.required(required),
        }),
        userAgentIsMobile: Yup.bool().when("agent", {
          is: (agent: string) => agent === "Custom",
          then: (schema) => schema.required(required),
        }),
        viewportWidth: Yup.number()
          .typeError(t("settings.validationError.number"))
          .min(1, t("validationViewportSize", { min: 1, max: 5000 }))
          .max(5000, t("validationViewportSize", { min: 1, max: 5000 })),
        viewportHeight: Yup.number()
          .typeError(t("settings.validationError.number"))
          .min(1, t("validationViewportSize", { min: 1, max: 20000 }))
          .max(20000, t("validationViewportSize", { min: 1, max: 20000 })),
      }),
      customRequestHeaders: Yup.object().shape({
        headers: Yup.array()
          .of(
            Yup.object().shape({
              key: Yup.string()
                .required(required)
                .matches(
                  /^[a-zA-Z0-9-]*$/,
                  t("settings.validationError.invalidName"),
                )
                .test(
                  "userAgent",
                  t("settings.validationError.notAllowed"),
                  (value) => !value || value.toLowerCase() !== "user-agent",
                ),
              value: Yup.string().required(required),
            }),
          )
          .test(
            "maxRequestHeader",
            maxRequestHeader,
            (values) =>
              !values ||
              uniqBy(values, (x) => x.key).length <= CUSTOM_REQUEST_HEADER_MAX,
          ),
      }),
    }),
    userAgent: getUserAgentSchema(t),
    cookies: getCookiesSchema(t),
    extraction: Yup.object().shape({
      customExtraction: Yup.object().shape({
        rules: Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required(required),
            pattern: Yup.string()
              .required(required)
              .test("Regex test", regex, function validate(value): boolean {
                if (!value) return true;
                try {
                  const regExp = value.startsWith("(?i)")
                    ? value.substring(4)
                    : value;
                  new RegExp(regExp);
                  return true;
                } catch {
                  return false;
                }
              }),
          }),
        ),
      }),
    }),
    test: Yup.object().shape({
      testSiteDomain: Yup.object().shape({
        domain: Yup.string().matches(REGEX_PATTERNS.url, url),
      }),
      customDNS: Yup.object().shape({
        customDns: Yup.array().of(
          Yup.object().shape({
            hostName: Yup.string()
              .required(required)
              .matches(new RegExp(REGEX_PATTERNS.baseDomainURL), url),
            ipAddress: Yup.string()
              .matches(REGEX_PATTERNS.ip, ip)
              .required(required),
          }),
        ),
      }),
      removeUrlParams: Yup.object().shape({
        params: Yup.array().of(Yup.string().matches(/^[^=]+$/, urlParameters)),
      }),
      urlRewriting: Yup.object().shape({
        rules: Yup.array().of(
          Yup.object().shape({
            matchFrom: Yup.string().required(required),
            matchTo: Yup.string().required(required),
          }),
        ),
      }),
    }),
    report: Yup.object().shape({
      apiCallback: Yup.object().shape({
        url: Yup.string().matches(REGEX_PATTERNS.url, url),
        headers: Yup.array().of(
          Yup.object().shape({
            key: Yup.string().required(required),
            value: Yup.string().required(required),
          }),
        ),
      }),
      emailAlerts: Yup.object().shape({
        emails: Yup.array().of(
          Yup.string().matches(REGEX_PATTERNS.email, emails),
        ),
      }),
      report: Yup.object().shape({
        maxTitleWidth: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .required(required),
        minTitleWidth: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .required(required),
        minDescriptionLength: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .required(required),
        maxDescriptionLength: Yup.number()
          .typeError(number)
          .min(0, nonNegative),
        maxHtmlSize: Yup.number().typeError(number).min(0, nonNegative),
        maxLinks: Yup.number().typeError(number).min(0, nonNegative),
        thinPageThreshold: Yup.number().typeError(number).min(0, nonNegative),
        emptyPageThreshold: Yup.number().typeError(number).min(0, nonNegative),
        maxExternalLinks: Yup.number().typeError(number).min(0, nonNegative),
        maxContentSize: Yup.number().typeError(number).min(0, nonNegative),
        maxUrlLength: Yup.number().typeError(number).min(0, nonNegative),
        maxFetchTime: Yup.number().typeError(number).min(0, nonNegative),
        duplicatePrecision: Yup.number()
          .typeError(number)
          .test(
            "positive",
            positiveNumber,
            (value) => value === undefined || value > 0,
          )
          .max(DUPLICATE_PRECISION_MAX, maxDuplicatePrecisionsError)
          .required(required),
        maxRedirections: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .max(REDIRECTIONS_MAX, maxRedirectionsError)
          .required(required),
        lowLogSummaryRequests: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .required(required),
        highLogSummaryRequests: Yup.number()
          .typeError(number)
          .min(0, nonNegative)
          .required(required),
      }),
    }),
  });
}

export function getUserAgentSchema(
  t: TFunction<"crawlSettings">,
  skipViewport?: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Yup.AnySchema<any, any, any> {
  return Yup.object().shape({
    string: Yup.string().when("code", {
      is: (code: string) => code === "Custom",
      then: (schema) => schema.required(t("settings.validationError.required")),
    }),
    token: Yup.string().when("code", {
      is: (code: string) => code === "Custom",
      then: (schema) => schema.required(t("settings.validationError.required")),
    }),
    ...(!skipViewport
      ? {
          viewportWidth: Yup.number()
            .typeError(t("settings.validationError.number"))
            .min(1, t("validationViewportSize", { min: 1, max: 5000 }))
            .max(5000, t("validationViewportSize", { min: 1, max: 5000 })),
          viewportHeight: Yup.number()
            .typeError(t("settings.validationError.number"))
            .min(1, t("validationViewportSize", { min: 1, max: 20000 }))
            .max(20000, t("validationViewportSize", { min: 1, max: 20000 })),
        }
      : {}),
  });
}

export const API_COOKIE_KEY_REGEXP = /^[a-zA-Z0-9!#$%&'*+\-.^_`|~]+$/;
export const API_COOKIE_VALUE_REGEXP =
  /^[a-zA-Z0-9!#$%&'()*+\-./:<=>?@[\]^_`{|}~]+$/;

export function getCookiesSchema(
  t: TFunction<"crawlSettings">,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Yup.AnySchema<any, any, any> {
  return Yup.object().shape({
    cookies: Yup.array(
      Yup.object()
        .shape({
          key: Yup.string().matches(
            API_COOKIE_KEY_REGEXP,
            t("validationCookieKey"),
          ),
          value: Yup.string().matches(
            API_COOKIE_VALUE_REGEXP,
            t("validationCookieValue"),
          ),
        })
        .test("unique", t("validationUniqueCookieKey"), (value, context) => {
          if (!value?.key) return true;

          const isUnique =
            context.parent.filter(
              (item: CookieSettings) => item.key === value.key,
            ).length === 1;

          if (!isUnique)
            throw context.createError({
              path: `${context.path}.key`,
              message: t("validationUniqueCookieKey"),
            });

          return true;
        }),
    ),
  });
}
