import { Alert, useTranslation } from "@lumar/shared";
import {
  makeStyles,
  List,
  ListItem,
  Grid,
  ListItemText,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useCrawlContext } from "../../crawl-overview/CrawlContext";
import { SearchSideBarItem } from "./search-side-bar-item/SearchSideBarItem";
import { Routes } from "../../_common/routing/routes";
import { useSearchParam } from "../../_common/routing/useSearchParam";

const useStyles = makeStyles(() => ({
  resultsList: { marginTop: 0 },
  categories: {
    color: "#4B5563",
    textTransform: "uppercase",
    borderTop: "solid 1px #D1D5DB",
    width: "100%",
  },
  categoryText: {
    backgroundColor: "#EBEFF3",
    position: "absolute",
    top: -1,
    paddingRight: 7,
  },
  listItems: { padding: "4px 3px 4px 6px" },
}));

export function SearchSideBar(props: { searchTerm: string }): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const { loading, errors, helpers } = useCrawlContext();
  const segmentId = useSearchParam("segmentId");
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (loading) {
    return (
      <CircularProgress
        size="2rem"
        style={{ display: "block", margin: "auto", marginTop: "64px" }}
      />
    );
  }

  if (errors) {
    return <Alert severity="error">{t("genericError")}</Alert>;
  }

  const matchingCrawlReportCategories = (
    helpers?.getCrawlReportCategoriesListWithReportsMatchingSearchTerm(
      props.searchTerm,
    ) ?? []
  ).filter((crawlReportCategory) => crawlReportCategory.reports.length > 0);

  return (
    <List className={classes.resultsList}>
      {matchingCrawlReportCategories.map((crawlReportCategory) => (
        <ListItem key={crawlReportCategory.code} className={classes.listItems}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <ListItemText className={classes.categories}>
                <Typography className={classes.categoryText} variant="caption">
                  {crawlReportCategory.name}
                </Typography>
              </ListItemText>
            </Grid>
            <Grid item>
              <List>
                {crawlReportCategory.reports.map((report) => (
                  <SearchSideBarItem
                    key={report.id}
                    totalRows={report.basic ?? 0}
                    name={report.reportTemplate.name}
                    href={Routes.Report.getUrl({
                      accountId,
                      projectId,
                      crawlId,
                      segmentId,
                      reportTemplateCode: report.reportTemplate.code,
                      reportTypeCode: "basic",
                    })}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}
