/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { Options, SeriesAreaOptions, SeriesLineOptions } from "highcharts";
import { CrawlProgressFragment } from "../../../../graphql";
import { baseChartOptions } from "../_common/charts/base-chart-options";
import { baseTooltipOptions } from "../_common/charts/tooltip-config";
import { insertIf } from "../../../../_common/insertIf";

function failureRateChartOptions(theme: Theme): Options {
  return {
    ...baseChartOptions,
    accessibility: {
      series: {
        pointDescriptionEnabledThreshold: false,
      },
    },
    chart: {
      height: 172,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: { second: "%H:%M:%S", millisecond: "%H:%M:%S" },
    },
    yAxis: {
      type: "linear",
      title: { style: { display: "none" } },
      min: 0,
      max: 100,
      labels: {
        format: "{value}%", // Add "%" suffix to y-axis labels
      },
    },
    legend: { enabled: false },
    tooltip: {
      ...baseTooltipOptions,
      formatter: function format() {
        return `${this.y || 0} %`;
      },
    },
    series: [
      {
        marker: { enabled: false },
        color: theme.palette.red[500],
        type: "area",
        fillColor: theme.palette.red[500],
        fillOpacity: 0.5,
        lineColor: theme.palette.red[500],
        lineWidth: 2,
      },
      {
        marker: { enabled: false },
        lineWidth: 2,
        color: theme.palette.text.primary,
        dashStyle: "ShortDash",
        type: "line",
      },
    ],
  };
}

export function getFailureRateChartOptions(args: {
  theme: Theme;
  offset: number;
  crawl?: CrawlProgressFragment;
}): Options {
  const { theme, crawl, offset } = args;
  const options = failureRateChartOptions(theme);
  const currentAreaSeries = options.series?.[0] as SeriesAreaOptions;
  const currentLineSeries = options.series?.[1] as SeriesLineOptions;

  const failureRateEnabled = crawl?.crawlSetting?.failureRateLimitEnabled;
  const failureRateThreshold = crawl?.crawlSetting?.failureRateThreshold ?? 50;

  const failureRateData = (crawl?.internalCrawlRates ?? []).map((rate) => [
    new Date(rate.crawlingAt).getTime() + offset,
    rate.failureRate ?? 0,
  ]);

  const failureRateThresholdData = (crawl?.internalCrawlRates ?? []).map(
    (rate) => [
      new Date(rate.crawlingAt).getTime() + offset,
      failureRateThreshold,
    ],
  );

  return {
    ...options,
    series: [
      {
        ...currentAreaSeries,
        data: failureRateData,
      },
      ...insertIf(failureRateEnabled, {
        ...currentLineSeries,
        data: failureRateThresholdData,
      }),
    ],
  };
}
