import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { useTranslation } from "@lumar/shared";

export function UrlsCrawledChart(props: {
  levelsChartOptions: Options;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  return (
    <ProgressDashboardContainer
      headerTitle={t("progressDashboard.urlsCrawledChartTitle")}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={props.levelsChartOptions}
        containerProps={{ "data-testid": "urls-crawled-chart" }}
      />
    </ProgressDashboardContainer>
  );
}
